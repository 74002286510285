import React, { useRef, useContext } from 'react';
import { gsap } from 'gsap';
import { web3 } from 'web3';
import './style.scss';
import { DataContext } from "../../contexts/DataContext";

const Navbar = ({ bgColor }) => {

    const { changeMenuState } = useContext(DataContext);
    const { menuIsOpen, totalPriceInEth } = useContext(DataContext);

    const { changeRoadmapState } = useContext(DataContext);
    const { roadmapIsOpen } = useContext(DataContext);

    const { changeMintState } = useContext(DataContext);
    const { mintIsOpen } = useContext(DataContext);

    var targetH1 = document.querySelectorAll("h1")[0];
    var targetH2 = document.querySelectorAll("h2")[0];


    //open close MENU
    const handleClickMenu = () => {
        changeMenuState()
        if (!menuIsOpen) {
            gsap.to(".nb-mint", {
                duration: 1,
                y: '-=50vh',
                zIndex: 3,
                ease: "power3.out",
            });
            gsap.to('.btn-roadmap', {
                duration: 1,
                y: '-=50vh',
                ease: "power3.out",
            });
            gsap.to('.btn-mint', {
                duration: 1,
                x: '+=50vw',
                ease: "power3.out",
            });

            gsap.to(targetH1.children, {
                duration: 1,
                rotation: -360,
                ease: "power3.out",
                x: "-=200vw",
                stagger: 0.1,

            });
            gsap.to(targetH2.children, {
                duration: .4,
                rotation: 360,
                ease: "bounce.in",
                x: "+=100vw",
                stagger: 0.01,
            });
            gsap.to("#character", {
                duration: 1,
                scale: 0,
                ease: "power3.out",
                delay: .2
            });
            gsap.to(".rs", {
                duration: 1,
                rotation: 180,
                scale: 0,
                ease: "power3.out",
                delay: .2
            });

        } else {
            // document.body.style.overflow = 'visible';

            gsap.to(".nb-mint", {
                duration: 1,
                y: 0,
                zIndex: 3,
                ease: "power3.out",
            });
            gsap.to("#character", {
                duration: 1,
                delay: .2,
                scale: 1,
                ease: "power3.out",
            });
            gsap.to(".panel-left, .-right", {
                duration: 1,
                rotation: 0,
                scale: 1,
                ease: "power3.out",
                delay: .2
            });
            gsap.to(targetH1.children, {
                duration: 1,
                x: "+=200vw",
                rotation: 360,
                ease: "power3.out",
                stagger: 0.1,
            });
            gsap.to(targetH2.children, {
                duration: .4,
                x: "0",
                rotation: 360,
                ease: "bounce.in",
                stagger: 0.01,
            });
            gsap.to("header, footer", {
                duration: 1,
                y: 0,
                ease: "power3.out",
            });
            gsap.to('.btn-roadmap', {
                duration: 1,
                y: 0,
                ease: "power3.out",
            });
            gsap.to('.btn-mint', {
                duration: 1,
                x: 0,
                ease: "power3.out",
            });
            gsap.to(".rs", {
                duration: 1,
                rotation: 720,
                scale: 1,
                ease: "power3.out",

            });
        }

    };

    //open close ROADMAP
    const handleClickRoadmap = () => {
        changeRoadmapState()
        if (!roadmapIsOpen) {
            // document.body.style.overflow = 'hidden';

            gsap.to("#character", {
                duration: 1,
                rotation: 1080,
                scale: 0,
                ease: "power3.out",
                delay: .1
            });

            gsap.to(".nb-mint", {
                duration: 1,
                y: '-=50vh',
                zIndex: 3,
                ease: "power3.out",
            });
            gsap.to('.btn-menu', {
                duration: 1,
                x: '-=50vw',
                ease: "power3.out",
            });
            gsap.to('.btn-mint', {
                duration: 1,
                x: '+=50vw',
                ease: "power3.out",
            });

            gsap.to(targetH1.children, {
                duration: 1,
                rotation: -360,
                ease: "power3.out",
                x: "-=200vw",
                stagger: 0.1,

            });
            gsap.to(targetH2.children, {
                duration: .4,
                rotation: 360,
                ease: "bounce.out",
                x: "+=100vw",
                stagger: 0.01,
            });

            gsap.to(".rs", {
                duration: 1,
                rotation: 180,
                scale: 0,
                ease: "power3.out",
                delay: .2
            });


        } else {
            // document.body.style.overflow = 'visible';

            gsap.to(".nb-mint", {
                duration: 2,
                y: 0,
                zIndex: 3,
                ease: "power3.out",
            });

            gsap.to(".rs", {
                duration: 1,
                rotation: 0,
                scale: 1,
                ease: "power3.out",
                delay: .2
            });
            gsap.to(targetH1.children, {
                duration: 1,
                x: "+=200vw",
                rotation: 360,
                ease: "power3.out",
                stagger: 0.1,
            });
            gsap.to(targetH2.children, {
                duration: .4,
                x: "0",
                rotation: 360,
                ease: "bounce.out",
                stagger: 0.01,
            });

            // gsap.to("header, footer", {
            //     duration: 1,
            //     y: 0,
            //     ease: "power3.out",
            // });
            gsap.to('.btn-menu', {
                duration: 1,
                x: 0,
                ease: "power3.out",
            });
            gsap.to('.btn-mint', {
                duration: 1,
                x: 0,
                ease: "power3.out",
            });
            gsap.to("#character", {
                duration: 1,
                delay: .5,
                rotation: 0,
                scale: 1,
                ease: "power3.out",
            });
        }

    };

    //open close MINT
    const handleClickMint = () => {
        changeMintState()
        if (!mintIsOpen) {
            gsap.to('.content', {
                opacity: 0,
            })
        } else {
            gsap.to('.content', {
                opacity: 1,
            })
        }
    };

    // function toHtml(text) {
    //     return { __html: text };
    // }

    return (
        <nav className='row text-center'>
            <div className='col-4 btn-menu p-0'>
                <button

                    id="btn-menu" onClick={handleClickMenu}>
                    {menuIsOpen ? 'CLOSE' : 'HISTORY'}
                </button>
            </div>
            <div className='col-4 btn-roadmap p-0'>
                <button

                    id="btn-roadmap" style={{ backgroundColor: bgColor }} onClick={handleClickRoadmap}>
                    {roadmapIsOpen ? 'CLOSE' : 'ROADMAP'}
                </button>
            </div>
            <div className='col-4 btn-mint p-0'>
                {/* <button id="btn-mint" onClick={handleClickMint} dangerouslySetInnerHTML={toHtml(mintIsOpen ? '<i class="fa-solid fa-house"></i>' : 'MINT')} /> */}
                {/* <button id="btn-mint" onClick={handleClickMint} data-name={totalPriceInEth + ' ETH'} >&nbsp;&nbsp;MINT&nbsp;&nbsp;</button> */}
                <button id="btn-mint" onClick={handleClickMint} data-name={'SOON'} >&nbsp;&nbsp;MINT&nbsp;&nbsp;</button>

            </div>
        </nav>
    );
};

export default Navbar;

