import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './style.scss';

const Menu = ({ isOpen, bgColor, textColor }) => {
    const menuRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            document.getElementById("menu").style.backgroundColor = bgColor;
            document.getElementById("menu").style.color = textColor;
            gsap.fromTo(menuRef.current, { zIndex: 1, top: "-100vh" }, {
                opacity: 1,
                zIndex: 1,
                delay: .5,
                duration: 1,
                top: "0",
                ease: 'bounce.out',
            });

            gsap.to("header", {
                duration: 1,
                opacity: 0,
                ease: "power3.out",
            });
            gsap.to("footer", {
                duration: 1,
                opacity: 0,
                ease: "power3.out",
            });
            gsap.to("body, #content", {
                duration: 1,
                overflowX: 'hidden',
                overflowY: 'hidden',
                ease: "power3.out",
            });
        } else {

            gsap.fromTo(menuRef.current, { top: "0vh" }, {
                opacity: 0,
                duration: 1,
                top: "-100vh",
                ease: 'power3.out',
            });

            gsap.to("header", {
                duration: 1,
                opacity: 1,
                ease: "power3.out",
            });
            gsap.to("footer", {
                duration: 1,
                opacity: 1,
                ease: "power3.out",
            });
            gsap.to("body, #content", {
                duration: 1,
                overflowX: 'hidden',
                overflowY: 'visible',
                ease: "power3.out",
            });

        }
    }, [isOpen]);


    return (

        <div>
            <div id="menu" ref={menuRef} className="d-flex align-items-center justify-content-center menu-open" style={{ top: '-100%' }}>
                <div className='bg-overlay' />
                <div className='container row h-100 d-flex align-items-end justify-content-end'>
                    <div className='col-12 menu-text menu-text-first overflow-scroll' id='menu-text'>
                        <p>
                            Peopixs are unique and fully customizable NFTs stored on the Ethereum blockchain. They are created by selecting desired options to create a unique Peopix character, and the data is stored on an IPFS server. Only one Peopix can be minted at a time due to the fact that they are not randomly generated like most NFTs. However, up to 10 Peopixs can be acquired.
                        </p>

                        <h4>The principle of mint is as follows</h4>
                        <p>
                            Create the Peopix in your image by selecting the desired options,
                            Peopix will be created on the blockchain and the data will be stored on an IPFS server.
                        </p>

                        <h4>Wich price and why ?</h4>
                        <p>
                            Peopixs are priced at an affordable 0.005 ETH, making them accessible to a wide range of users. Additionally, players have the option to purchase up to three accessories and a costume for their Peopix, allowing for even more customization and personalization. These paid options provide an opportunity for players to further personalize their Peopix character and make it truly unique.
                        </p>
                        <h4>Why can I mint only one Peopix at a time ?</h4>
                        <p>
                            The fact that you can choose the parameters of your NFT unlike most NFT generated randomly, the multi mint is not possible.
                            It is however possible to acquire up to 10 Peopixs.
                        </p>

                        <h4>what can I do with my Peopix ?</h4>
                        <p>
                            Owning a Peopix means being a full member of the Peopix universe and having access to a variety of exclusive advantages in the game.
                        </p>

                        <h4>The game ? what game ?</h4>
                        <p>
                            In Peopixs World, players will be able to explore a map using their Peopix as the main character. The map will be filled with activities such as fishing, gardening, battles and social interactions with other players. Players will be able to chat with each other in real time and explore the world together, creating a collaborative gaming experience.                        </p>
                        <p>click on the roadmap button to learn more about the game</p>

                        <h4>Who are you? How can we find you ?</h4>
                        <p>
                            We are two Web developers working on the content of the project in order to continuously evolve the features, the UI/UX design, the code of Peopixs, paying special attention to the creation of NFT's and the interactive map.
                            We use Twitter as a platform for information and contact for our community, as we want to use our precious time to focus on the roadmap without being distracted by other communication channels. As a result, we do not plan to use Discord for the time being. Don't worry we are right here 👀
                        </p>

                        <h4>And then what ?</h4>
                        <p>
                            We are constantly striving to improve and add value to our gaming environment. In addition to providing an immersive and engaging experience with our Peopixs, we are excited to announce that we are exploring the implementation of tokenomics in the future.

                            By incorporating tokenomics, we hope to add value to the Peopixs environment and create a game economy that benefits our players. We welcome ideas from our community to improve the game experience and bring even more value to your Peopixs.

                            We are listening to the comments and opinions of our community to constantly improve our project. We believe that by working together, we can create an even more exciting and engaging gaming experience that is truly one of a kind.                        </p>
                        <p>
                            Feel free to follow  and contact us via twitter.
                        </p>
                    </div>
                    <div className='col-12 menu-text text-center' id='menu-rs'>
                        {/* <a href="#" className='m-2'>
                            <img src="/assets/img/opensea.png" alt="opensea" />
                        </a>
                        <a href="#" className='m-2'>
                            <img src="/assets/img/twitter.png" alt="twitter" />
                        </a> */}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Menu;