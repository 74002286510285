import React, { Component, useState, useEffect, useContext } from "react";
import Person from '../GameObject/Person'
import utils from '../../utils/utils'
import OverworldEvent from "./OverworldEvent";
import willMibBehaviorLoop from "../../Scenes/LabRoom/willMib/behaviorLoop.json"
import npcbBehaviorLoop from "../../Scenes/LabRoom/npcb/behaviorLoop.json"
import npcaBehaviorLoop from "../../Scenes/Toilet/npca/behaviorLoop.json"
import npcaTalking from "../../Scenes/Toilet/npca/talking.json"
import npcbTalking from "../../Scenes/LabRoom/npcb/talking.json"
import tommyMibBehaviorLoop from "../../Scenes/LabRoom/tommyMib/behaviorLoop.json"

class OverworldMap extends Component {
    constructor(config) {
        super(config);
        this.overworld = null;
        this.gameObjects = {}; // Live objects are in here
        this.configObjects = config.configObjects; // Configuration content

        this.cutsceneSpaces = config.cutsceneSpaces || {};
        this.walls = config.walls || {}
        this.lowerImage = new Image();
        this.lowerImage.src = config.lowerSrc;
        this.upperImage = new Image();
        this.upperImage.src = config.upperSrc;
        this.isCutscenePlaying = false;
    }


    drawLowerImage(ctx, cameraPerson) {
        ctx.drawImage(
            this.lowerImage,
            utils.withGrid(10.5) - cameraPerson.x,
            utils.withGrid(6) - cameraPerson.y,
        )
    }

    drawUpperImage(ctx, cameraPerson) {
        ctx.drawImage(
            this.upperImage,
            utils.withGrid(10.5) - cameraPerson.x,
            utils.withGrid(6) - cameraPerson.y,
        )
    }

    isSpaceTaken(currentX, currentY, direction) {
        const { x, y } = utils.nextPosition(currentX, currentY, direction);
        if (this.walls[`${x},${y}`]) {
            return true;
        }
        //Check for game objects at this position
        return Object.values(this.gameObjects).find(obj => {
            if (obj.x === x && obj.y === y) { return true; }
            if (obj.intentPosition && obj.intentPosition[0] === x && obj.intentPosition[1] === y) {
                return true;
            }
            return false;
        })

    }
    // isSpaceTaken(currentX, currentY, direction) {
    //     const { x, y } = utils.nextPosition(currentX, currentY, direction);
    //     return this.walls[`${x},${y}`] || false;
    // }
    mountObjects() {

        Object.keys(this.configObjects).forEach(key => {
            let object = this.configObjects[key];
            object.id = key;
            let instance;


            if (object.type === "Person") {
                if (object.isPlayerControlled) {
                    const principalHero = "./assets/images/characters/people/peopix-1.png"
                    object.src = principalHero; // Set the image source for the hero object
                }
                instance = new Person(object);
            }


            if (object.type === "PizzaStone") {
                // instance = new PizzaStone(object);
            }
            this.gameObjects[key] = instance;
            this.gameObjects[key].id = key;
            instance.mount(this);
        })
    }

    // async startCutscene(events) {
    //     this.isCutscenePlaying = true;

    //     for (let i = 0; i < events.length; i++) {
    //         const eventHandler = new OverworldEvent({
    //             event: events[i],
    //             map: this,
    //         })
    //         await eventHandler.init();
    //     }

    //     this.isCutscenePlaying = false;
    //     //Reset NPCs to do their idle behavior
    //     // Object.values(this.gameObjects).forEach(object => object.doBehaviorEvent(this))

    // }

    async startCutscene(events, avatar, actor) {
        this.isCutscenePlaying = true;

        for (let i = 0; i < events.length; i++) {

            const eventHandler = new OverworldEvent({
                event: events[i],
                map: this,
                avatar: avatar,
                actor: actor,
            })


            const result = await eventHandler.init();
            if (result === "LOST_BATTLE") {
                break;
            }
        }
        this.isCutscenePlaying = false;

    }




    checkForActionCutscene() {
        const hero = this.gameObjects["hero"];
        const nextCoords = utils.nextPosition(hero.x, hero.y, hero.direction);
        const match = Object.values(this.gameObjects).find(object => {
            return `${object.x},${object.y}` === `${nextCoords.x},${nextCoords.y}`
        });

        if (!this.isCutscenePlaying && match && match.talking.length) {
            this.startCutscene(match.talking[0].events, match.talking[0].avatar, match.talking[0].actor)
        }
    }

    checkForFootstepCutscene() {
        const hero = this.gameObjects["hero"];
        const match = this.cutsceneSpaces[`${hero.x},${hero.y}`];
        if (!this.isCutscenePlaying && match) {
            this.startCutscene(match[0].events, match[0].avatar, match[0].actor)
        }
    }

}
window.OverworldMaps = {

    LabRoom: {
        lowerSrc: "./assets/images/maps/LabLower.png",
        upperSrc: "./assets/images/maps/LabUpper.png",
        configObjects: {
            hero: {
                type: "Person",
                isPlayerControlled: true,
                x: utils.withGrid(24),
                y: utils.withGrid(11),
            },
            npcB: {
                type: "Person",
                x: utils.withGrid(33),
                y: utils.withGrid(20),
                src: "/assets/images/characters/people/npc1.png",
                behaviorLoop: utils.SceneLoopJson(npcbBehaviorLoop.behaviorLoop),
                // talking: utils.SceneLoopJson(npcbTalking.talking),
                talking: [
                    {
                        "avatar": "/assets/images/characters/people/npc1.png",
                        "actor": "John",
                        "events": [
                            {
                                type: "textMessage",
                                text: "It seems that someone has stolen the holy white paper.",
                                faceHero: "npcB",
                            },
                            {
                                "type": "textMessage",
                                "text": "Have you seen the signs ?"
                            }
                        ]
                    }
                ]

            },
            willMib: {
                type: "Person",
                x: utils.withGrid(40),
                y: utils.withGrid(15),
                src: "./assets/images/characters/people/will-mib.png",
                behaviorLoop: utils.SceneLoopJson(willMibBehaviorLoop.behaviorLoop),
            },
            tommyMib: {
                type: "Person",
                x: utils.withGrid(4),
                y: utils.withGrid(20),
                src: "./assets/images/characters/people/tommy-mib.png",
                behaviorLoop: utils.SceneLoopJson(tommyMibBehaviorLoop.behaviorLoop),
            },
        },
        walls: {
            [utils.asGridCoord(20, 8)]: true,
            [utils.asGridCoord(21, 8)]: true,
            [utils.asGridCoord(22, 8)]: true,
            [utils.asGridCoord(23, 8)]: true,
            [utils.asGridCoord(24, 8)]: true,
            [utils.asGridCoord(25, 8)]: true,
            [utils.asGridCoord(26, 8)]: true,
            [utils.asGridCoord(27, 8)]: true,
            [utils.asGridCoord(28, 8)]: true,

            [utils.asGridCoord(17, 9)]: true,
            [utils.asGridCoord(18, 9)]: true,
            [utils.asGridCoord(19, 9)]: true,
            [utils.asGridCoord(20, 9)]: true,
            [utils.asGridCoord(28, 9)]: true,
            [utils.asGridCoord(29, 9)]: true,
            [utils.asGridCoord(30, 9)]: true,
            [utils.asGridCoord(31, 9)]: true,

            [utils.asGridCoord(17, 10)]: true,
            [utils.asGridCoord(29, 10)]: true,
            [utils.asGridCoord(30, 10)]: true,
            [utils.asGridCoord(31, 10)]: true,

            [utils.asGridCoord(17, 11)]: true,
            [utils.asGridCoord(29, 11)]: true,
            [utils.asGridCoord(30, 11)]: true,
            [utils.asGridCoord(31, 11)]: true,

            [utils.asGridCoord(17, 12)]: true,
            [utils.asGridCoord(31, 12)]: true,

            [utils.asGridCoord(17, 13)]: true,
            [utils.asGridCoord(31, 13)]: true,

            [utils.asGridCoord(17, 14)]: true,
            [utils.asGridCoord(31, 14)]: true,

            [utils.asGridCoord(17, 15)]: true,
            [utils.asGridCoord(31, 15)]: true,

            [utils.asGridCoord(17, 16)]: true,
            [utils.asGridCoord(31, 16)]: true,

            [utils.asGridCoord(17, 17)]: true,
            [utils.asGridCoord(31, 17)]: true,

            [utils.asGridCoord(10, 18)]: true,
            [utils.asGridCoord(11, 18)]: true,
            [utils.asGridCoord(12, 18)]: true,
            [utils.asGridCoord(13, 18)]: true,
            [utils.asGridCoord(14, 18)]: true,
            [utils.asGridCoord(15, 18)]: true,
            [utils.asGridCoord(16, 18)]: true,
            [utils.asGridCoord(17, 18)]: true,
            [utils.asGridCoord(31, 18)]: true,
            [utils.asGridCoord(32, 18)]: true,
            [utils.asGridCoord(33, 18)]: true,
            [utils.asGridCoord(34, 18)]: true,


            [utils.asGridCoord(10, 19)]: true,
            [utils.asGridCoord(11, 19)]: true,
            [utils.asGridCoord(12, 19)]: true,
            [utils.asGridCoord(13, 19)]: true,
            [utils.asGridCoord(14, 19)]: true,
            [utils.asGridCoord(15, 19)]: true,
            [utils.asGridCoord(16, 19)]: true,
            [utils.asGridCoord(17, 19)]: true,
            [utils.asGridCoord(31, 19)]: true,
            [utils.asGridCoord(32, 19)]: true,
            [utils.asGridCoord(33, 19)]: true,
            [utils.asGridCoord(34, 19)]: true,

            [utils.asGridCoord(10, 20)]: true,
            [utils.asGridCoord(11, 20)]: true,
            [utils.asGridCoord(34, 20)]: true,

            [utils.asGridCoord(34, 21)]: true,

            [utils.asGridCoord(10, 22)]: true,
            [utils.asGridCoord(11, 22)]: true,
            [utils.asGridCoord(12, 22)]: true,
            [utils.asGridCoord(13, 22)]: true,
            [utils.asGridCoord(14, 22)]: true,
            [utils.asGridCoord(15, 22)]: true,
            [utils.asGridCoord(16, 22)]: true,
            [utils.asGridCoord(17, 22)]: true,
            [utils.asGridCoord(18, 22)]: true,
            [utils.asGridCoord(19, 22)]: true,
            [utils.asGridCoord(20, 22)]: true,
            [utils.asGridCoord(28, 22)]: true,
            [utils.asGridCoord(29, 22)]: true,
            [utils.asGridCoord(30, 22)]: true,
            [utils.asGridCoord(31, 22)]: true,
            [utils.asGridCoord(32, 22)]: true,
            [utils.asGridCoord(33, 22)]: true,
            [utils.asGridCoord(34, 22)]: true,

            [utils.asGridCoord(20, 23)]: true,
            [utils.asGridCoord(28, 23)]: true,

            [utils.asGridCoord(20, 24)]: true,
            [utils.asGridCoord(28, 24)]: true,

            [utils.asGridCoord(20, 25)]: true,
            [utils.asGridCoord(28, 25)]: true,
        },
        cutsceneSpaces: {
            [utils.asGridCoord(19, 10)]: [{
                events: [
                    { who: "hero", type: "stand", direction: "up" },
                    { type: "textMessage", text: "Phase 1: Launch and initial development Q1 2023", },
                    { type: "textMessage", text: "In this phase, we will work on launching our platform and game.", },
                    { type: "textMessage", text: "We will also work on basic improvements, such as optimizing our server and improving the user interface for a smoother gameplay experience.", },
                    { type: "textMessage", text: "Phase 2: Adding features Q2 2023", },
                    { type: "textMessage", text: "In this phase, we will add new features to our platform and game, including the introduction of the role-playing element as well as player interaction.", },
                ]
            }],
            [utils.asGridCoord(33, 20)]: [{
                events: [
                    { who: "hero", type: "stand", direction: "up" },
                    { type: "textMessage", text: "Phase 3: Implementation of tokenomics and game economy Q3 2023", },
                    { type: "textMessage", text: "In this phase, we will add a game economy to our platform, which means that players will be able to earn rewards and value for their Peopix by actively participating in the game.", },
                    { type: "textMessage", text: "We will also work on tokenomics to add value to the environment and provide a more rewarding gaming experience for our players.", },
                ]
            }],
            [utils.asGridCoord(13, 20)]: [{
                events: [
                    { who: "hero", type: "stand", direction: "up" },
                    { type: "textMessage", text: "Phase 4: Expansion and continuous improvement Q4 2023", },
                    { type: "textMessage", text: "While we won't wait until Q4 2023 to share with you, in this phase we plan to expand our user base and continue to improve our platform and game based on feedback from our player community.", },
                    { type: "textMessage", text: "We welcome all ideas and suggestions to improve our platform and provide an even better gaming experience.", },
                ]
            }],

            [utils.asGridCoord(26, 9)]: [{
                events: [
                    { who: "hero", type: "stand", direction: "up" },
                    { type: "textMessage", text: "Bande d'enfoirée de Laurent et Quentin c'est peopix et pas PedoPixs", },
                ]
            }],
            [utils.asGridCoord(27, 9)]: [{
                events: [
                    { who: "hero", type: "stand", direction: "up" },
                    { type: "textMessage", text: "Bande d'enfoirée de Laurent et Quentin c'est peopix et pas PedoPixs", },
                ]
            }],
            [utils.asGridCoord(11, 21)]: [
                {
                    "avatar": "/assets/images/characters/people/tommy-mib.png",
                    "actor": "Tommy",
                    events: [
                        { who: "tommyMib", type: "walk", direction: "down" },
                        { who: "tommyMib", type: "walk", direction: "right" },
                        { who: "tommyMib", type: "walk", direction: "right" },
                        { who: "tommyMib", type: "walk", direction: "right" },
                        { who: "tommyMib", type: "walk", direction: "right" },
                        { who: "tommyMib", type: "walk", direction: "right" },
                        { who: "tommyMib", type: "walk", direction: "right" },
                        { type: "textMessage", text: "You can't go that way, Go back !", actor: "Tommy" },
                        { who: "hero", type: "walk", direction: "right" },
                        { who: "hero", type: "walk", direction: "right" },
                        { who: "tommyMib", type: "walk", direction: "left" },
                        { who: "tommyMib", type: "walk", direction: "left" },
                        { who: "tommyMib", type: "walk", direction: "left" },
                        { who: "tommyMib", type: "walk", direction: "left" },
                        { who: "tommyMib", type: "walk", direction: "left" },
                        { who: "tommyMib", type: "walk", direction: "left" },
                        { who: "tommyMib", type: "walk", direction: "up" },
                        { who: "tommyMib", type: "stand", direction: "right", time: 800 },



                    ]
                }
            ],


            [utils.asGridCoord(21, 25)]: [{
                events: [{ type: "textMessage", text: "We pride ourselves on offering an immersive and engaging gaming experience through our Peopix." },
                { type: "textMessage", text: "We are working tirelessly to improve our platform and add new and exciting features for our players." },
                { type: "textMessage", text: "Please note that this roadmap is not exhaustive and is subject to change." },
                { type: "textMessage", text: "We understand that the timeline for achieving these goals may vary based on a variety of factors, and we are committed to remaining flexible and making adjustments as necessary." },
                { type: "textMessage", text: "We are always open to feedback and suggestions from our community, and we look forward to continuing to build and improve the Peopixs project together." },
                { type: "changeMap", map: "SubLabRoom" }]
            }],
            [utils.asGridCoord(22, 25)]: [{
                events: [{ type: "textMessage", text: "We pride ourselves on offering an immersive and engaging gaming experience through our Peopix." },
                { type: "textMessage", text: "We are working tirelessly to improve our platform and add new and exciting features for our players." },
                { type: "textMessage", text: "Please note that this roadmap is not exhaustive and is subject to change." },
                { type: "textMessage", text: "We understand that the timeline for achieving these goals may vary based on a variety of factors, and we are committed to remaining flexible and making adjustments as necessary." },
                { type: "textMessage", text: "We are always open to feedback and suggestions from our community, and we look forward to continuing to build and improve the Peopixs project together." },
                { type: "changeMap", map: "SubLabRoom" }]
            }],
            [utils.asGridCoord(23, 25)]: [{
                events: [{ type: "textMessage", text: "We pride ourselves on offering an immersive and engaging gaming experience through our Peopix." },
                { type: "textMessage", text: "We are working tirelessly to improve our platform and add new and exciting features for our players." },
                { type: "textMessage", text: "Please note that this roadmap is not exhaustive and is subject to change." },
                { type: "textMessage", text: "We understand that the timeline for achieving these goals may vary based on a variety of factors, and we are committed to remaining flexible and making adjustments as necessary." },
                { type: "textMessage", text: "We are always open to feedback and suggestions from our community, and we look forward to continuing to build and improve the Peopixs project together." },
                { type: "changeMap", map: "SubLabRoom" }]
            }],
            [utils.asGridCoord(24, 25)]: [{
                events: [{ type: "textMessage", text: "We pride ourselves on offering an immersive and engaging gaming experience through our Peopix." },
                { type: "textMessage", text: "We are working tirelessly to improve our platform and add new and exciting features for our players." },
                { type: "textMessage", text: "Please note that this roadmap is not exhaustive and is subject to change." },
                { type: "textMessage", text: "We understand that the timeline for achieving these goals may vary based on a variety of factors, and we are committed to remaining flexible and making adjustments as necessary." },
                { type: "textMessage", text: "We are always open to feedback and suggestions from our community, and we look forward to continuing to build and improve the Peopixs project together." },
                { type: "changeMap", map: "SubLabRoom" }]
            }],
            [utils.asGridCoord(25, 25)]: [{
                events: [{ type: "textMessage", text: "We pride ourselves on offering an immersive and engaging gaming experience through our Peopix." },
                { type: "textMessage", text: "We are working tirelessly to improve our platform and add new and exciting features for our players." },
                { type: "textMessage", text: "Please note that this roadmap is not exhaustive and is subject to change." },
                { type: "textMessage", text: "We understand that the timeline for achieving these goals may vary based on a variety of factors, and we are committed to remaining flexible and making adjustments as necessary." },
                { type: "textMessage", text: "We are always open to feedback and suggestions from our community, and we look forward to continuing to build and improve the Peopixs project together." },
                { type: "changeMap", map: "SubLabRoom" }]
            }],
            [utils.asGridCoord(26, 25)]: [{
                events: [{ type: "textMessage", text: "We pride ourselves on offering an immersive and engaging gaming experience through our Peopix." },
                { type: "textMessage", text: "We are working tirelessly to improve our platform and add new and exciting features for our players." },
                { type: "textMessage", text: "Please note that this roadmap is not exhaustive and is subject to change." },
                { type: "textMessage", text: "We understand that the timeline for achieving these goals may vary based on a variety of factors, and we are committed to remaining flexible and making adjustments as necessary." },
                { type: "textMessage", text: "We are always open to feedback and suggestions from our community, and we look forward to continuing to build and improve the Peopixs project together." },
                { type: "changeMap", map: "SubLabRoom" }]
            }],
            [utils.asGridCoord(27, 25)]: [{
                events: [{ type: "textMessage", text: "We pride ourselves on offering an immersive and engaging gaming experience through our Peopix." },
                { type: "textMessage", text: "We are working tirelessly to improve our platform and add new and exciting features for our players." },
                { type: "textMessage", text: "Please note that this roadmap is not exhaustive and is subject to change." },
                { type: "textMessage", text: "We understand that the timeline for achieving these goals may vary based on a variety of factors, and we are committed to remaining flexible and making adjustments as necessary." },
                { type: "textMessage", text: "We are always open to feedback and suggestions from our community, and we look forward to continuing to build and improve the Peopixs project together." },
                { type: "changeMap", map: "SubLabRoom" }]
            }],
        }
    },

    SubLabRoom: {
        lowerSrc: "./assets/images/maps/SubLabLower.png",
        upperSrc: "./assets/images/maps/SubLabUpper.png",
        configObjects: {
            hero: {
                type: "Person",
                isPlayerControlled: true,
                x: utils.withGrid(11),
                y: utils.withGrid(13),
                // src: this.charImg,

            },
            clownyRed: {
                type: "Person",
                x: utils.withGrid(10),
                y: utils.withGrid(20),
                src: "./assets/images/characters/people/clowny-red.png",
                behaviorLoop: [
                    { type: "stand", direction: "up", time: 2000 },
                    { type: "walk", direction: "right" },
                    { type: "stand", direction: "up", time: 2000 },
                    { type: "walk", direction: "left" },
                ],
                talking: [
                    {
                        "avatar": "/assets/images/characters/people/clowny-red.png",
                        "actor": "Clowny Red",
                        events: [
                            { type: "battle", text: "Come back later !", faceHero: "clownyRed", actor: "Clowny Red" },
                            { who: "hero", type: "walk", direction: "up" },
                        ]
                    }
                ]
            },
            clownyBlue: {
                type: "Person",
                x: utils.withGrid(19),
                y: utils.withGrid(20),
                src: "./assets/images/characters/people/clowny-blue.png",
                behaviorLoop: [
                    { type: "stand", direction: "up", time: 1000 },
                    { type: "walk", direction: "left" },
                    { type: "stand", direction: "up", time: 4000 },
                    { type: "walk", direction: "right" },
                ],
                talking: [
                    {
                        "avatar": "/assets/images/characters/people/clowny-blue.png",
                        "actor": "Clowny Blue",
                        events: [
                            { type: "textMessage", text: "Come back later !", faceHero: "clownyBlue", actor: "Clowny Blue" },
                            { who: "hero", type: "walk", direction: "up" },
                        ]
                    }
                ]
            },
            leprechaun: {
                type: "Person",
                x: utils.withGrid(18),
                y: utils.withGrid(14),
                src: "./assets/images/characters/people/leprechaun.png",
                behaviorLoop: [
                    { type: "stand", direction: "down", time: 2000 },
                    { type: "walk", direction: "right" },
                    { type: "stand", direction: "down", time: 3000 },
                    { type: "walk", direction: "left" },
                ],
                talking: [
                    {
                        "avatar": "/assets/images/characters/people/leprechaun.png",
                        "actor": "Leprechaun",
                        events: [
                            { type: "textMessage", text: "Come back later !", faceHero: "leprechaun", actor: "Leprechaun" },
                            { who: "hero", type: "walk", direction: "down" },
                        ]
                    }
                ]
            },
            skully: {
                type: "Person",
                x: utils.withGrid(14),
                y: utils.withGrid(17),
                src: "./assets/images/characters/people/skully.png",
                behaviorLoop: [
                    { type: "stand", direction: "up", time: 5000 },
                    { type: "walk", direction: "right" },
                    { type: "stand", direction: "up", time: 1000 },
                    { type: "walk", direction: "left" },
                ],
                talking: [
                    {
                        events: [
                            {
                                type: "textMessage",
                                text: "Come back later !",
                                faceHero: "skully",
                                actor: "Skully",
                            },
                            { who: "hero", type: "walk", direction: "up" },
                        ]
                    }
                ]
            },
        },
        walls: {
            [utils.asGridCoord(9, 10)]: true,
            [utils.asGridCoord(12, 10)]: true,
            [utils.asGridCoord(17, 10)]: true,
            [utils.asGridCoord(20, 10)]: true,

            [utils.asGridCoord(9, 11)]: true,
            [utils.asGridCoord(12, 11)]: true,
            [utils.asGridCoord(17, 11)]: true,
            [utils.asGridCoord(20, 11)]: true,

            [utils.asGridCoord(9, 12)]: true,
            [utils.asGridCoord(12, 12)]: true,
            [utils.asGridCoord(17, 12)]: true,
            [utils.asGridCoord(20, 12)]: true,

            [utils.asGridCoord(9, 13)]: true,
            [utils.asGridCoord(12, 13)]: true,
            [utils.asGridCoord(17, 13)]: true,
            [utils.asGridCoord(18, 13)]: true,
            [utils.asGridCoord(19, 13)]: true,
            [utils.asGridCoord(20, 13)]: true,

            [utils.asGridCoord(9, 14)]: true,
            [utils.asGridCoord(12, 14)]: true,
            [utils.asGridCoord(13, 14)]: true,
            [utils.asGridCoord(14, 14)]: true,
            [utils.asGridCoord(15, 14)]: true,
            [utils.asGridCoord(16, 14)]: true,
            [utils.asGridCoord(17, 14)]: true,
            [utils.asGridCoord(20, 14)]: true,

            [utils.asGridCoord(8, 15)]: true,
            [utils.asGridCoord(21, 15)]: true,

            [utils.asGridCoord(8, 16)]: true,
            [utils.asGridCoord(21, 16)]: true,

            [utils.asGridCoord(8, 17)]: true,
            [utils.asGridCoord(13, 17)]: true,
            [utils.asGridCoord(16, 17)]: true,
            [utils.asGridCoord(6, 17)]: true,
            [utils.asGridCoord(21, 17)]: true,

            [utils.asGridCoord(8, 18)]: true,
            [utils.asGridCoord(13, 18)]: true,
            [utils.asGridCoord(14, 18)]: true,
            [utils.asGridCoord(15, 18)]: true,
            [utils.asGridCoord(16, 18)]: true,
            [utils.asGridCoord(6, 18)]: true,
            [utils.asGridCoord(21, 18)]: true,

            [utils.asGridCoord(8, 19)]: true,
            [utils.asGridCoord(13, 19)]: true,
            [utils.asGridCoord(16, 19)]: true,
            [utils.asGridCoord(6, 19)]: true,
            [utils.asGridCoord(21, 19)]: true,

            [utils.asGridCoord(8, 20)]: true,
            [utils.asGridCoord(9, 20)]: true,
            [utils.asGridCoord(12, 20)]: true,
            [utils.asGridCoord(13, 20)]: true,
            [utils.asGridCoord(14, 20)]: true,
            [utils.asGridCoord(15, 20)]: true,
            [utils.asGridCoord(16, 20)]: true,
            [utils.asGridCoord(17, 20)]: true,
            [utils.asGridCoord(20, 20)]: true,
            [utils.asGridCoord(21, 20)]: true,

            [utils.asGridCoord(10, 21)]: true,
            [utils.asGridCoord(11, 21)]: true,
            [utils.asGridCoord(18, 21)]: true,
            [utils.asGridCoord(19, 21)]: true,
        },
        cutsceneSpaces: {
            [utils.asGridCoord(10, 11)]: [{
                events: [{ type: "changeMap", map: "LabRoom" }]
            }],
            [utils.asGridCoord(11, 11)]: [{
                events: [{ type: "changeMap", map: "LabRoom" }]
            }],
            [utils.asGridCoord(14, 17)]: [{
                events: [{ type: "textMessage", text: "No trespassing !" },
                { who: "hero", type: "walk", direction: "up" },
                { who: "hero", type: "walk", direction: "up" },

                ]
            }],

            [utils.asGridCoord(15, 17)]: [{
                events: [{ type: "textMessage", text: "No trespassing !" },
                { who: "hero", type: "walk", direction: "up" },
                { who: "hero", type: "walk", direction: "up" },
                ]
            }],
            [utils.asGridCoord(10, 20)]: [{
                // events: [{ type: "changeMap", map: "ToiletRoom" }],
                events: [{ type: "changeMap", map: "cutNature" }],
                // events: [{ type: "changeMap", map: "cutFish" }],
                // events: [{ type: "textMessage", text: "No trespassing !" },
                // { who: "hero", type: "walk", direction: "up" },
                // { who: "hero", type: "walk", direction: "up" },
                // ]
            }],
            [utils.asGridCoord(11, 20)]: [{
                events: [{ type: "textMessage", text: "No trespassing !" },
                { who: "hero", type: "walk", direction: "up" },
                { who: "hero", type: "walk", direction: "up" },
                ]
            }],
            [utils.asGridCoord(18, 20)]: [{
                events: [{ type: "textMessage", text: "No trespassing !" },
                { who: "hero", type: "walk", direction: "up" },
                { who: "hero", type: "walk", direction: "up" },
                ]
            }],
            [utils.asGridCoord(19, 20)]: [{
                events: [{ type: "textMessage", text: "No trespassing !" },
                { who: "hero", type: "walk", direction: "up" },
                { who: "hero", type: "walk", direction: "up" },
                ]
            }],
            [utils.asGridCoord(18, 14)]: [{
                events: [{ type: "textMessage", text: "No trespassing !" },
                { who: "hero", type: "walk", direction: "down" },
                { who: "hero", type: "walk", direction: "down" },
                ]
            }],
            [utils.asGridCoord(19, 14)]: [{
                events: [{ type: "textMessage", text: "No trespassing !" },
                { who: "hero", type: "walk", direction: "down" },
                { who: "hero", type: "walk", direction: "down" },
                ]
            }],
        },
    },

    ToiletRoom: {
        lowerSrc: "./assets/images/maps/ToiletLower.png",
        upperSrc: "./assets/images/maps/ToiletUpper.png",
        configObjects: {
            hero: {
                type: "Person",
                isPlayerControlled: true,
                x: utils.withGrid(14),
                y: utils.withGrid(9),
            },
            npcA: {
                type: "Person",
                x: utils.withGrid(15),
                y: utils.withGrid(13),
                src: "./assets/images/characters/people/npc2.png",
                behaviorLoop: utils.SceneLoopJson(npcaBehaviorLoop.behaviorLoop),
                talking: utils.SceneLoopJson(npcaTalking.talking),
            },

        },
        walls: {
            [utils.asGridCoord(10, 4)]: true,
            [utils.asGridCoord(11, 4)]: true,
            [utils.asGridCoord(12, 4)]: true,
            [utils.asGridCoord(13, 4)]: true,
            [utils.asGridCoord(15, 4)]: true,
            [utils.asGridCoord(16, 4)]: true,
            [utils.asGridCoord(17, 4)]: true,
            [utils.asGridCoord(18, 4)]: true,
            [utils.asGridCoord(19, 4)]: true,

        },
        cutsceneSpaces: {},
    },

    cutNature: {
        lowerSrc: "./assets/images/maps/aaa-nature.png",
        upperSrc: "./assets/images/maps/ToiletUpper.png",
        configObjects: {
            hero: {
                type: "Person",
                isPlayerControlled: true,
                x: utils.withGrid(14),
                y: utils.withGrid(9),
            },
            npcA: {
                type: "Person",
                x: utils.withGrid(15),
                y: utils.withGrid(13),
                src: "./assets/images/characters/people/npc2.png",
                behaviorLoop: utils.SceneLoopJson(npcaBehaviorLoop.behaviorLoop),
                talking: utils.SceneLoopJson(npcaTalking.talking),
            },

        },
        walls: {

        },
        cutsceneSpaces: {},
    },

    cutFish: {
        lowerSrc: "./assets/images/maps/aaa-camping.png",
        upperSrc: "./assets/images/maps/ToiletUpper.png",
        configObjects: {
            hero: {
                type: "Person",
                isPlayerControlled: true,
                x: utils.withGrid(13),
                y: utils.withGrid(15),
            },
            // npcA: {
            //     type: "Person",
            //     x: utils.withGrid(22),
            //     y: utils.withGrid(19),
            //     src: "./assets/images/characters/people/npc6.png",
            //     behaviorLoop: [
            //         { type: "stand", direction: "right", time: 2000 },
            //         { type: "walk", direction: "left" },
            //         { type: "stand", direction: "left", time: 2000 },
            //         { type: "walk", direction: "right" },
            //     ],
            //     talking: utils.SceneLoopJson(npcaTalking.talking),
            // },
            npcB: {
                type: "Person",
                x: utils.withGrid(18),
                y: utils.withGrid(19),
                src: "./assets/images/characters/people/npc1.png",
                behaviorLoop: [
                    { type: "stand", direction: "left" }
                ],
                talking: utils.SceneLoopJson(npcaTalking.talking),
            },
            // npcC: {
            //     type: "Person",
            //     x: utils.withGrid(9),
            //     y: utils.withGrid(18),
            //     src: "./assets/images/characters/people/clowny-red.png",
            //     behaviorLoop: [
            //         { type: "stand", direction: "down" }
            //     ],
            //     talking: utils.SceneLoopJson(npcaTalking.talking),
            // },
        },
        walls: {

        },
        cutsceneSpaces: {

        },
    }
}



export default OverworldMap