import React, { createContext, useContext, useEffect, useState } from "react";
import web3 from "web3";
export const DataContext = createContext();

const DataContextProvider = (props) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [roadmapIsOpen, setRoadmapIsOpen] = useState(false);
    const [mintIsOpen, setMintIsOpen] = useState(false);
    const [mintCost, setMintCost] = useState(0);
    const [totalPriceInEth, setTotalPriceInEth] = useState(0)
    const [demoChar, setDemoChar] = useState(null)
    const [sessionDemoChar, setSessionDemoChar] = useState(null)


    const changeMenuState = () => {
        setMenuIsOpen(!menuIsOpen);
    };
    const changeRoadmapState = () => {
        setRoadmapIsOpen(!roadmapIsOpen);
    };
    const changeMintState = () => {
        setMintIsOpen(!mintIsOpen);
    };
    const changeMintCost = (ammount) => {
        setMintCost(ammount);

        async function convertWEItoETH(amountInWEI) {
            if (amountInWEI) {
                const amountInETH = parseFloat(web3.utils.fromWei(amountInWEI.toString(), 'ether'));
                return amountInETH;
            }
            return 0;
        }


        async function updateConfig() {
            const total = await convertWEItoETH(ammount);
            setTotalPriceInEth(total)
        }

        updateConfig();

    };

    const changeDemoChar = (data) => {
        // Storing the base64 encoded string in session storage
        if (data) {
            setDemoChar(data);
        }
    };


    useEffect(() => {
        if (demoChar) {
            if (sessionDemoChar !== demoChar) {
                setSessionDemoChar(demoChar)
            }
        }
    }, [demoChar]);

    return (
        <DataContext.Provider value={{ menuIsOpen, changeMenuState, roadmapIsOpen, changeRoadmapState, mintIsOpen, changeMintState, mintCost, totalPriceInEth, changeMintCost, demoChar, changeDemoChar, sessionDemoChar }}>
            {props.children}
        </DataContext.Provider>
    );
};

export default DataContextProvider;