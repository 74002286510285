import utils from "../../utils/utils";

const BattleAnimations = {
  pickAxe: async (event, onComplete) => {
    const element = event.caster.pizzaElement;
    const animationClassName = event.caster.team === "player" ? "battle-pickAxe-right" : "battle-pickAxe-left";
    element.classList.add(animationClassName);

    document.querySelector(".sprite_player").classList.remove("animation-idle-player")
    document.querySelector(".sprite_enemy").classList.remove("animation-idle-enemy")

    if (animationClassName === 'battle-pickAxe-right') {
      document.querySelector(".sprite_player").classList.remove("animation-def-player");
      document.querySelector(".sprite_enemy").classList.add("animation-def-enemy");
      document.querySelector(".sprite_player").classList.add("animation-atk-pickAxe-player")
      document.querySelector(".sprite_enemy").classList.remove("animation-atk-pickAxe-enemy")
      document.querySelector(".Battle_hero").classList.add(animationClassName);
    }
    if (animationClassName === 'battle-pickAxe-left') {
      document.querySelector(".sprite_player").classList.add("animation-def-player")
      document.querySelector(".sprite_enemy").classList.remove("animation-def-enemy")
      document.querySelector(".sprite_player").classList.remove("animation-atk-pickAxe-player");
      document.querySelector(".sprite_enemy").classList.add("animation-atk-pickAxe-enemy")
      document.querySelector(".Battle_enemy").classList.add(animationClassName);
    }

    // Remove class when animation is fully complete
    element.addEventListener("animationend", () => {
      element.classList.remove(animationClassName);
      document.querySelector(".Battle_hero").classList.remove(animationClassName);
      document.querySelector(".Battle_enemy").classList.remove(animationClassName);
      document.querySelector(".sprite_player").classList.remove("animation-atk-pickAxe-player");
      document.querySelector(".sprite_player").classList.remove("animation-def-player");
      document.querySelector(".sprite_enemy").classList.remove("animation-atk-pickAxe-enemy");
      document.querySelector(".sprite_enemy").classList.remove("animation-def-enemy");
      document.querySelector(".sprite_player").classList.add("animation-idle-player")
      document.querySelector(".sprite_enemy").classList.add("animation-idle-enemy")
    }, { once: true });

    // Continue battle cycle right around when the pizzas collide
    await utils.wait(100);
    onComplete();
  },

  sleep: async (event, onComplete) => {
    const { caster } = event;
    let div = document.createElement("div");
    div.classList.add("sleep-orb");
    div.classList.add(caster.team === "player" ? "battle-sleep-right" : "battle-sleep-left");
    const animationClassName = event.caster.team === "player" ? "battle-sleep-right" : "battle-sleep-left";

    document.querySelector(".sprite_player").classList.remove("animation-idle-player")
    document.querySelector(".sprite_enemy").classList.remove("animation-idle-enemy")

    //player atk with sleep
    if (animationClassName === 'battle-sleep-right') {
      document.querySelector(".sprite_player").classList.remove("animation-def-player");
      document.querySelector(".sprite_player").classList.add("animation-atk-magic-player")
      document.querySelector(".sprite_enemy").classList.add("animation-hurt-enemy");
      document.querySelector(".sprite_enemy").classList.remove("animation-atk-pickAxe-enemy")
      div.innerHTML = (`
      <img src="/assets/images/Skills/sleep-1.gif" width="32px" height="32px" alt="atk"/>
      `);
    }

    //enemy atk with sleep
    if (animationClassName === 'battle-sleep-left') {
      document.querySelector(".sprite_enemy").classList.remove("animation-def-enemy");
      document.querySelector(".sprite_enemy").classList.add("animation-atk-magic-enemy");
      document.querySelector(".sprite_player").classList.add("animation-hurt-player");
      document.querySelector(".sprite_player").classList.remove("animation-atk-pickAxe-player");
      div.innerHTML = (`
      <img class="flipped" src="/assets/images/Skills/sleep-1.gif" width="32px" height="32px" alt="atk"/>
      `);
    }

    // div.innerHTML = (`
    // <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/95231b76-5fad-429a-a6d5-99f5f5e23f3b/dchk9qd-bcdc7346-d100-4adb-9a0b-5cda5703ec9d.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzk1MjMxYjc2LTVmYWQtNDI5YS1hNmQ1LTk5ZjVmNWUyM2YzYlwvZGNoazlxZC1iY2RjNzM0Ni1kMTAwLTRhZGItOWEwYi01Y2RhNTcwM2VjOWQuZ2lmIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.aR043nhYBHsfbXAncd2-PmSVtmEXVfzX_L036zhBuPY" width="32px" height="32px" alt="atk"/>
    // `);


    // div.innerHTML = (`
    //   <svg viewBox="0 0 32 32" width="32" height="32">
    //     <circle cx="16" cy="16" r="16" fill="${event.color}" />
    //   </svg>
    // `);

    //Remove class when animation is fully complete
    div.addEventListener("animationend", (event) => {
      //player atak with sleep
      if (event.animationName === 'battle-sleep-right') {
        document.querySelector(".sprite_player").classList.remove("animation-atk-magic-player");
        document.querySelector(".sprite_player").classList.add("animation-idle-player");
        document.querySelector(".sprite_enemy").classList.remove("animation-hurt-enemy");
      }
      //enemy atak with sleep
      if (event.animationName === 'battle-sleep-left') {
        document.querySelector(".sprite_enemy").classList.remove("animation-atk-magic-enemy");
        document.querySelector(".sprite_enemy").classList.add("animation-idle-enemy");
        document.querySelector(".sprite_player").classList.remove("animation-hurt-player");
      }
      div.remove();
    });

    //Add to scene
    document.querySelector(".Battle").appendChild(div);

    await utils.wait(820);
    onComplete();
  }
};

export default BattleAnimations;
