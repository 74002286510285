import React, { useState, useEffect, useRef } from 'react';
import animationData from '../Character/animations.json';

import { useParams } from 'react-router-dom';

import axios from 'axios';
const Peopixs = ({ url }) => {
    const [data, setData] = useState(null);
    const [animationNb, setAnimationNb] = useState(0); // Initial frame

    const [side, setSide] = useState(0);     // 0 FRONT - 1 BACK - 2 RIGHT - 3 LEFT
    const [frame, setFrame] = useState(0); // Initial frame
    const [frameVertical, setFrameVertical] = useState(0); // Initial frame
    const [animation, setAnimation] = useState(animationData.animations[animationNb]);
    const [frameRate, setFrameRate] = useState(100);
    const [bg, setBg] = useState('');

    const sizeFrame = 32;
    const animationType = animation.start * sizeFrame
    const zoom = 20;
    const frameCount = animation.frameRates; // Total number of frames in the animation


    const canvasRef = useRef(null); // Ref to the canvas element
    const { id } = useParams();
    let idd = id;


    useEffect(() => {
        if (isNaN(id)) {
            idd = 0
        }
        axios.get(`${url}/leaflet/${idd}`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.log(error);


            });
    }, [id]);

    useEffect(() => {
        //when push different keys, do action
        window.addEventListener('keydown', handleKeyPress);
        function handleKeyPress(event) {
            if (event.code === 'ArrowRight') {
                setSide(2);
            }
            if (event.code === 'ArrowLeft') {
                setSide(3);

            }
            if (event.code === 'ArrowUp') {
                setSide(1);

            }
            if (event.code === 'ArrowDown') {
                setSide(0);

            }
        }
    }, []);

    useEffect(() => {
        let animationTurn1 = 0;
        let animationTurn2 = 0;
        if (animationData.animations[animationNb].vertical) {
            animationTurn1 = animationType;
            animationTurn2 = frameVertical * sizeFrame;

        } else {
            animationTurn1 = frame * sizeFrame;
            animationTurn2 = animationType;
        }
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();

        if (data) {
            setBg(data.bg)
            const dataLeaflet = data.data.image;
            img.src = dataLeaflet;
            img.onload = () => {
                // ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.imageSmoothingEnabled = false; // Enable image smoothing
                ctx.drawImage(
                    img,
                    animationTurn1, // Source x
                    side * sizeFrame, // Source y
                    sizeFrame, // Source width
                    sizeFrame, // Source height
                    0, // Destination x
                    0, // Destination y
                    sizeFrame * zoom, // Destination width
                    sizeFrame * zoom // Destination height
                );
            }
        }

    }, [data, frame]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFrame((frame + 1) % frameCount);
        }, frameRate); // Set the frame change interval
        return () => clearInterval(intervalId);
    }, [frame]);

    return (
        <div style={{ background: "#" + bg }} className="parent">
            <canvas ref={canvasRef} width={640} height={640} />
        </div>
    );
};

export default Peopixs;