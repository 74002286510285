
import React, { Component } from 'react';
import OverworldMap from './OverworldMap';
import DirectionInput from '../Keyboard/DirectionInput';
import KeyPressListener from '../Keyboard/KeyPressListener';
import MouseClickListener from '../Mouse/MouseClickListener';

class Overworld extends Component {
    constructor(config) {
        super(config)
        this.element = config.element;
        this.canvas = this.element.querySelector(".game-canvas");
        this.ctx = this.canvas.getContext("2d");
        this.map = null;
    }

    startGameLoop() {
        const step = () => {
            //Clear off the canvas
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            //Establish the camera person
            const cameraPerson = this.map.gameObjects.hero;

            //Update all objects
            Object.values(this.map.gameObjects).forEach(object => {
                object.update({
                    arrow: this.directionInput.direction,
                    map: this.map,
                })
            })

            //Draw Lower layer
            this.map.drawLowerImage(this.ctx, cameraPerson);

            //Draw Game Objects
            //reorganise les z-index des personnages selon leur position Y
            Object.values(this.map.gameObjects).sort((a, b) => {
                return a.y - b.y;
            }).forEach(object => {
                object.sprite.draw(this.ctx, cameraPerson);
            })

            //Draw Upper layer
            this.map.drawUpperImage(this.ctx, cameraPerson);

            requestAnimationFrame(() => {
                step();
            })
        }
        step();
    }

    bindActionInput() {
        new KeyPressListener("Enter", () => {
            //Is there a person here to talk to?
            this.map.checkForActionCutscene()
        })
    }

    //check hero position
    bindHeroPositionCheck() {
        document.addEventListener("PersonWalkingComplete", e => {
            if (e.detail.whoId === "hero") {
                //Hero's position has changed
                this.map.checkForFootstepCutscene()
            }
        })
    }

    startMap(mapConfig) {
        this.map = new OverworldMap(mapConfig);
        this.map.overworld = this;
        this.map.mountObjects();
    }

    init() {

        this.startMap(window.OverworldMaps.LabRoom);
        this.map.mountObjects();

        this.bindActionInput();
        this.bindHeroPositionCheck();

        this.directionInput = new DirectionInput();
        this.directionInput.init();

        this.startGameLoop();

        this.map.startCutscene([
            // { type: "battle" },

            // { type: "textMessage", text: "Welcome to the Peopixs.io" },
            // { type: "textMessage", text: "Use (W A S D) or arrows on the keyboard. for moving your character" },
            // { type: "textMessage", text: "Press ENTER key to interact with the others characters." },
            // { type: "textMessage", text: "Walk around to discover what the objects have to tell you ;)" },


            { type: "textMessage", text: "Welcome to the Peopixs roadmap v1.0 !" },
            { type: "textMessage", text: "This one is still in the demo phase but we are working hard on it to bring you the best content ever :)" },
            { type: "textMessage", text: "Use (W A S D) or arrows on the keyboard. for moving your character" },
            { type: "textMessage", text: "Press ENTER key to interact with the others characters." },
            { type: "textMessage", text: "Walk around to discover what the objects have to tell you ;)" },

            //     { who: "hero", type: "walk", direction: "down" },
            //     { who: "hero", type: "walk", direction: "down" },
            //     { who: "npcA", type: "walk", direction: "right" },
            //     { who: "npcA", type: "walk", direction: "right" },
            //     { who: "npcA", type: "walk", direction: "right" },
            //     { who: "npcA", type: "walk", direction: "right" },
            //     { who: "npcA", type: "stand", direction: "up" },
            //     { who: "npcB", type: "stand", direction: "up", time: 300 },
            //     { type: "textMessage", text: "WHY HELLO THERE!" },
        ])

    }
}

export default Overworld;

