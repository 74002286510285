import Combatant from '../Battle/Combatant';
import SubmissionMenu from '../Battle/SubmissionMenu';
import Team from '../Battle/Team';
import BattleEvent from './BattleEvent';
import TurnCycle from './TurnCycle';

import '../Battle/Combatant.scss'
import '../Battle/SubmissionMenu.scss'
import '../Battle/Team.scss'

class Battle {
  constructor(config) {
    // super(config)
    this.char = config.char
    this.combatants = {
      "player1": new Combatant({
        ...window.Pizzas.s001,
        team: "player",
        hp: 30,
        maxHp: 50,
        xp: 75,
        maxXp: 100,
        level: 1,
        status: null,
        // status: { type: "heal" },

        isPlayerControlled: true,
      }, this),
      "enemy1": new Combatant({
        ...window.Pizzas.v001,
        team: "enemy",
        hp: 20,
        maxHp: 50,
        xp: 20,
        maxXp: 100,
        // status: { type: "heal" },
        status: null,
        level: 1,
      }, this),
      "enemy2": new Combatant({
        ...window.Pizzas.f001,
        team: "enemy",
        hp: 25,
        maxHp: 50,
        xp: 30,
        maxXp: 100,
        level: 1,
      }, this)
    }
    this.activeCombatants = {
      player: "player1",
      enemy: "enemy1",
    }
    this.items = [
      { actionId: "item_recoverStatus", instanceId: "p1", team: "player" },
      { actionId: "item_recoverStatus", instanceId: "p2", team: "player" },
      { actionId: "item_recoverStatus", instanceId: "p3", team: "enemy" },
      { actionId: "item_recoverHp", instanceId: "p4", team: "player" },
    ]
  }


  createElement() {
    this.element = document.createElement("div");
    this.element.classList.add("Battle");
    this.element.innerHTML = (`
        <div class="Battle_hero">
        <div class="sprite_player">
          <img src=${this.char} alt="Hero" />
        </div>
        </div>
        <div class="Battle_enemy">
          <div class="sprite_enemy">
            <img src=${'./assets/images/characters/people/npc3.png'} alt="Enemy" />
          </div>
        </div>
        `)
  }

  init(container) {
    this.createElement();
    container.appendChild(this.element);

    Object.keys(this.combatants).forEach(key => {
      let combatant = this.combatants[key];
      combatant.id = key;
      combatant.init(this.element)
    })

    this.turnCycle = new TurnCycle({
      battle: this,
      onNewEvent: event => {
        return new Promise(resolve => {
          const battleEvent = new BattleEvent(event, this)
          battleEvent.init(resolve);
        })
      }
    })
    this.turnCycle.init();


  }
}

export default Battle