import React, { useEffect, useState } from 'react';
import Overworld from "./class/Overworld/Overworld";
import './style.scss'

const Game = ({ demoCharImg }) => {
    // const [data, setData] = useState()
    // useEffect(() => {
    //     setData([...demoCharImg, demoCharImg])
    // }, [demoCharImg]);

    // useEffect(() => {
    // if (data) {
    //     if (data.length === 5) {
    window.onload = function () {

        const gameContainer = document.createElement('div');
        gameContainer.classList.add('game-container');
        const currentContainer = document.querySelector('#bg-overlay-roadmap').appendChild(gameContainer);

        const canvas = document.createElement('canvas');
        canvas.classList.add('game-canvas');
        canvas.id = 'game-canvas';

        canvas.width = 352;
        canvas.height = 198;
        gameContainer.appendChild(canvas);

        const overworld = new Overworld({
            element: currentContainer,
            // demoChar: demoCharImg
        });
        overworld.init();

        // }
    }

    // }

    // }, [data]);

    return <></>;
};

export default Game;
