import KeyboardMenu from '../Keyboard/KeyboardMenu'
class SubmissionMenu {
    constructor({ caster, enemy, onComplete, items }) {
        this.caster = caster;
        this.enemy = enemy;
        this.onComplete = onComplete;
        let quantityMap = {};
        items.forEach(item => {
            if (item.team === caster.team) {
                let existing = quantityMap[item.actionId];
                if (existing) {
                    existing.quantity += 1;
                } else {
                    quantityMap[item.actionId] = {
                        actionId: item.actionId,
                        quantity: 1,
                        instanceId: item.instanceId,
                    }
                }
            }
        })
        this.items = Object.values(quantityMap);
    }

    getPages() {

        const backOption = {
            label: "<i class='fa-solid fa-arrow-left'></i>",
            description: "Return to previous page",
            handler: () => {
                this.keyboardMenu.setOptions(this.getPages().root)
            }
        };

        return {
            root: [
                {
                    label: "Attack",
                    description: "Choose an attack",

                    handler: () => {
                        //Do something when chosen...
                        this.keyboardMenu.setOptions(this.getPages().attacks)
                    }
                },
                {
                    label: "Items",
                    description: "Choose an item",
                    handler: () => {
                        //Go to items page...
                        this.keyboardMenu.setOptions(this.getPages().items)
                    }
                },
                {
                    label: "Swap",
                    description: "Change to another pizza",
                    handler: () => {
                        //See pizza options
                    }
                },
            ],
            attacks: [

                ...this.caster.actions.map(key => {
                    const action = window.Actions[key];
                    return {
                        label: action.name,
                        description: action.description,
                        handler: () => {
                            this.menuSubmit(action)
                        }
                    }
                }),
                backOption
            ],
            items: [
                ...this.items.map(item => {
                    const action = window.Actions[item.actionId];
                    return {
                        label: action.name,
                        description: action.description,
                        right: () => {
                            return "x" + item.quantity;
                        },
                        handler: () => {
                            this.menuSubmit(action, item.instanceId)
                        }
                    }
                }),
                backOption
            ]
        }
    }

    menuSubmit(action, instanceId = null) {

        this.keyboardMenu?.end();

        this.onComplete({
            action,
            target: action.targetType === "friendly" ? this.caster : this.enemy,
            instanceId
        })
    }

    decide() {
        // Generate a random index within the bounds of the actions array
        const randomIndex = Math.floor(Math.random() * this.caster.actions.length);
        // Use the random index to select an action from the actions array
        this.menuSubmit(window.Actions[this.caster.actions[randomIndex]]);
    }

    showMenu(container) {
        this.keyboardMenu = new KeyboardMenu();
        this.keyboardMenu.init(container);
        this.keyboardMenu.setOptions(this.getPages().root)
    }

    init(container) {

        if (this.caster.isPlayerControlled) {
            //si player controlé mais que sous status
            // de controle, sleep, ... 
            //alors on atk aleatoire comme non compté
            if (this.caster.status) {
                if (this.caster.status.type === 'sleep') {
                    this.decide()
                } else {
                    //Show some UI
                    this.showMenu(container)
                }
            } else {
                //Show some UI
                this.showMenu(container)
            }

        } else {
            this.decide()
        }
    }
}

export default SubmissionMenu;