import React from "react";
import GameObject from "./GameObject";
import utils from "../../utils/utils";


class Person extends GameObject {
    constructor(config) {
        super(config);

        this.movingProgressRemaining = 0;
        this.isStanding = false;
        this.intentPosition = null; // [x,y]

        this.isPlayerControlled = config.isPlayerControlled || false
        this.directionUpdate = {
            "up": ["y", -1],
            "down": ["y", 1],
            "left": ["x", -1],
            "right": ["x", 1],
        }
    }


    update(state) {
        if (this.movingProgressRemaining > 0) {
            this.updatePosition();
        } else {

            //More cases for starting to walk will come here
            //
            //

            //Case: We're keyboard ready and have an arrow pressed
            if (!state.map.isCutscenePlaying && this.isPlayerControlled && state.arrow) {

                this.startBehavior(state, {
                    type: "walk",
                    direction: state.arrow
                })
            }
            this.updateSprite(state);
        }
    }

    startBehavior(state, behavior) {
        if (!this.isMountedC) {
            return;
        }
        //Set character direction to whatever behavior has
        this.direction = behavior.direction;

        if (behavior.type === "walk") {
            //Stop here if space is not free
            if (state.map.isSpaceTaken(this.x, this.y, this.direction)) {
                behavior.retry && setTimeout(() => {
                    this.startBehavior(state, behavior)
                }, 10);
                return;
            }

            //Ready to walk!
            this.movingProgressRemaining = 16;

            //Add next position intent
            //TODO
            //TODO UNCOMMUNT LINE IF YOU IA PASS A TRAVERS HERO
            // const intentPosition = utils.nextPosition(this.x, this.y, this.direction)
            // this.intentPosition = [
            //     intentPosition.x,
            //     intentPosition.y,
            // ]

            this.updateSprite(state);
        }

        // if (behavior.type === "stand") {
        //     this.isStanding = true;

        //     setTimeout(() => {
        //         utils.emitEvent("PersonStandComplete", {
        //             whoId: this.id
        //         })
        //         this.isStanding = false;

        //     }, behavior.time)
        // }

        if (behavior.type === "stand") {
            this.isStanding = true;

            if (this.standBehaviorTimeout) {
                clearTimeout(this.standBehaviorTimeout);
                // console.log("xlear")
            }
            this.standBehaviorTimeout = setTimeout(() => {
                utils.emitEvent("PersonStandComplete", {
                    whoId: this.id
                })
                this.isStanding = false;
            }, behavior.time)
        }

    }

    updatePosition() {
        const [property, change] = this.directionUpdate[this.direction];
        this[property] += change;
        this.movingProgressRemaining -= 1;
        if (this.movingProgressRemaining === 0) {
            //we finished the walk;
            utils.emitEvent("PersonWalkingComplete", {
                whoId: this.id
            })
        }
    }

    updateSprite() {
        if (this.movingProgressRemaining > 0) {
            this.sprite.setAnimation("walk-" + this.direction)
            return;
        }
        this.sprite.setAnimation("idle-" + this.direction)
    }
}

export default Person;





