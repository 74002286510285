import React, { useState, useEffect, useRef, useContext } from 'react';
import { gsap } from 'gsap';
import { Container, Row, Col, Button } from 'react-bootstrap';
import backgroundColor from '../Character/backgroundColor.json';

import characterProperties from '../Character/properties.json';
import characterFirstname from '../GenerateName/firstname.json';
import characterLastname from '../GenerateName/lastname.json';

import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import { DataContext } from "../../contexts/DataContext";
import Web3 from 'web3';
import './style.scss';
const web3 = new Web3(window.ethereum);


const Mint = ({ closePanel, totalPriceElements, isOpen, bgColor, textColor, onStateChangeFooter, onStateChangeBtnPress, animatedChar, animationData, char, base64Img, url, urlExternal, characterContent }) => {

    const mintRef = useRef(null);
    const { changeMintState, mintIsOpen, mintCost, changeMintCost } = useContext(DataContext);


    let [finalChar, setFinalChar] = useState({})
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(``);
    const [mintAmount, setMintAmount] = useState(1);
    const [totalMint, setTotalMint] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });

    const [totalPrice, setTotalPrice] = useState();
    const [totalPriceInEth, setTotalPriceInEth] = useState();

    useEffect(() => {
        setTotalPrice(CONFIG.WEI_COST)

    }, [CONFIG.WEI_COST]);


    const [charFirstName, setCharFirstName] = useState(null);
    const [charLastName, setCharLastName] = useState(null);


    const claimNFTs = () => {
        let cost = mintCost;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);

        if (finalChar) {
            blockchain.smartContract.methods
                .mint(mintAmount)
                .send({
                    gasLimit: String(totalGasLimit),
                    to: CONFIG.CONTRACT_ADDRESS,
                    from: blockchain.account,
                    value: totalCostWei,
                })
                .once("error", (err) => {
                    console.log(err);
                    setFeedback("Sorry, something went wrong please try again later.");
                    setClaimingNft(false);
                })
                .then(async (receipt) => {
                    async function getTotalMinted() {
                        // Call the total minted function
                        return await blockchain.smartContract.methods.totalSupply().call();
                    }

                    const totalMinted = await getTotalMinted();
                    function generateStatValue(percentageRanges, valueRanges) {
                        let rand = Math.random();
                        let rangeIndex = 0;
                        for (let i = 0; i < percentageRanges.length; i++) {
                            if (rand < percentageRanges[i]) {
                                rangeIndex = i;
                                break;
                            }
                        }
                        let minValue = valueRanges[rangeIndex][0];
                        let maxValue = valueRanges[rangeIndex][1];
                        return Math.floor(rand * (maxValue - minValue + 1)) + minValue;
                    }

                    const percentageRanges = [
                        //50% de chance d'avoir les stats entre 20 et 40
                        0.5,
                        //35% de chance d'avoir les stats entre 41 et 50
                        0.85,
                        //13% (0.98 - 0.85) de chance d'avoir les stats entre 51 et 90
                        0.98,
                        //2%  de chance d'avoir les stats entre 91 et 100
                        1
                    ];
                    const valueRanges = [[20, 40], [41, 50], [51, 90], [91, 100]];


                    finalChar.attributes.push(
                        {
                            "trait_type": "Level",
                            "value": 0
                        },
                        {
                            "trait_type": "Attack",
                            "value": generateStatValue(percentageRanges, valueRanges)
                        },
                        {
                            "trait_type": "Defense",
                            "value": generateStatValue(percentageRanges, valueRanges)
                        },
                        {
                            "trait_type": "Critical Hit",
                            "value": generateStatValue(percentageRanges, valueRanges)
                        },
                        {
                            "trait_type": "Speed",
                            "value": generateStatValue(percentageRanges, valueRanges)
                        }
                    );

                    const randomBetweenOneAndTen = () => {
                        return Math.floor(Math.random() * 10) + 1;
                    }

                    let stats = [];

                    const boostTypes = [
                        { display_type: 'boost_number', trait_type: 'Attack' },
                        { display_type: 'boost_number', trait_type: 'Defense' },
                        { display_type: 'boost_number', trait_type: 'Critical Hit' },
                        { display_type: 'boost_number', trait_type: 'Speed' },
                        { display_type: 'boost_percentage', trait_type: 'Attack' },
                        { display_type: 'boost_percentage', trait_type: 'Defense' },
                        { display_type: 'boost_percentage', trait_type: 'Critical Hit' },
                        { display_type: 'boost_percentage', trait_type: 'Speed' }
                    ];

                    if (finalChar.attributes) {
                        const accessoryOne = finalChar.attributes.find(attr => attr.trait_type === 'accessory one');
                        const accessoryTwo = finalChar.attributes.find(attr => attr.trait_type === 'accessory two');
                        const accessoryThree = finalChar.attributes.find(attr => attr.trait_type === 'accessory three');
                        const disguise = finalChar.attributes.find(attr => attr.trait_type === 'disguise');

                        if (accessoryOne && accessoryOne.value !== 'NONE') {
                            const randomStat = boostTypes[Math.floor(Math.random() * boostTypes.length)];
                            const newStat = {
                                display_type: randomStat.display_type,
                                trait_type: randomStat.trait_type,
                                value: randomBetweenOneAndTen(),
                            };
                            stats.push(newStat);
                        }

                        if (accessoryTwo && accessoryTwo.value !== 'NONE') {
                            const randomStat = boostTypes[Math.floor(Math.random() * boostTypes.length)];
                            const newStat = {
                                display_type: randomStat.display_type,
                                trait_type: randomStat.trait_type,
                                value: randomBetweenOneAndTen(),
                            };
                            stats.push(newStat);
                        }

                        if (accessoryThree && accessoryThree.value !== 'NONE') {
                            const randomStat = boostTypes[Math.floor(Math.random() * boostTypes.length)];
                            const newStat = {
                                display_type: randomStat.display_type,
                                trait_type: randomStat.trait_type,
                                value: randomBetweenOneAndTen(),
                            };
                            stats.push(newStat);
                        }


                        if (disguise && disguise.value !== 'NONE') {
                            const randomStat = boostTypes[Math.floor(Math.random() * boostTypes.length)];
                            const newStat = {
                                display_type: randomStat.display_type,
                                trait_type: randomStat.trait_type,
                                value: randomBetweenOneAndTen(),
                            };
                            stats.push(newStat);
                        }
                    }
                    finalChar.attributes.push(...stats)


                    // Update the state
                    const newFinalChar = {
                        ...finalChar,
                        "animation_url": urlExternal + "/peopixs/" + totalMinted,
                        "external_url": urlExternal + "/peopixs/" + totalMinted,
                    };

                    const data = {
                        finalChar: newFinalChar,
                        base64Img: { "image": base64Img },
                        totalMints: totalMinted,
                        account: blockchain.account,
                        price: totalPriceInEth
                    };

                    axios.post(urlJson + '/save-json', data)
                        .then(response => {
                            console.log('WONDERFULL !!! your NFT has been well minted ');
                        })
                        .catch(error => {
                            console.log('Error saving NFT', error);
                        });


                    setFeedback(
                        `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
                    );
                    setClaimingNft(false);
                    dispatch(fetchData(blockchain.account));
                });
        } else {
            console.log('Error saving NFT');
            setFeedback(
                `OUPS ! an error appeared, please refresh the page and mint again`
            );
        }
    };

    // const decrementMintAmount = () => {
    //     let newMintAmount = mintAmount - 1;
    //     if (newMintAmount < 1) {
    //         newMintAmount = 1;
    //     }
    //     setMintAmount(newMintAmount);
    // };

    // const incrementMintAmount = () => {
    //     let newMintAmount = mintAmount + 1;
    //     if (newMintAmount > 1) {
    //         newMintAmount = 1;
    //     }
    //     setMintAmount(newMintAmount);
    // };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);




    useEffect(() => {
        async function convertETHtoWEI(amountInETH) {
            const amountInWEI = parseInt(web3.utils.toWei(amountInETH.toString(), 'ether'), 10);
            return amountInWEI;
        }

        async function updateConfig() {
            const total = await convertETHtoWEI(totalPriceElements);
            setTotalPrice(CONFIG.WEI_COST + total)
        }

        updateConfig();
    }, [totalPriceElements]);

    useEffect(() => {

        changeMintCost(totalPrice)

    }, [totalPrice]);


    useEffect(() => {


        async function convertWEItoETH(amountInWEI) {
            if (amountInWEI) {
                const amountInETH = parseFloat(web3.utils.fromWei(amountInWEI.toString(), 'ether'));
                return amountInETH;
            }
            return 0;
        }


        async function updateConfig() {
            const total = await convertWEItoETH(mintCost);
            setTotalPriceInEth(total)
        }

        updateConfig();
    }, [mintCost]);





    function updateButtonMintPrice() {
        const btnMint = document.getElementById("btn-mint");
        btnMint.addEventListener("click", function () {
            const totalPriceInEth = parseFloat(getComputedStyle(btnMint).getPropertyValue("--total-price-in-eth"));
            btnMint.style.setProperty("--content", `${totalPriceInEth} ETH`);
        });
    }

    document.addEventListener("DOMContentLoaded", function () {
        updateButtonMintPrice();
    });










    const canvasRef = useRef(null);
    const [croppedBlob, setCroppedBlob] = useState(null);
    const [name, setName] = useState('');

    let urlJson = url;

    const {
        accessory_one,
        accessory_two,
        accessory_three,
        background,
        blush,
        disguise,
        eyes,
        hair,
        lipstick,
        pant,
        shirt,
        shoes,
        skin,
    } = char;

    const search = (name) => {
        // name.hair = 'WAV Black';
        const indexAccessoryOne = characterProperties.accessory_one.findIndex(item => item.name === name.accessory_one);
        const indexAccessoryTwo = characterProperties.accessory_two.findIndex(item => item.name === name.accessory_two);
        const indexAccessoryThree = characterProperties.accessory_three.findIndex(item => item.name === name.accessory_three);
        const indexBackground = backgroundColor.bg.findIndex(item => item.name === name.background);
        const indexBlush = characterProperties.blush.findIndex(item => item.color === name.blush);
        const indexDisguise = characterProperties.disguise.findIndex(item => item.name === name.disguise);
        const indexEyes = characterProperties.eyes.findIndex(item => item.color === name.eyes);

        const hairSplit = name.hair;
        const nameHair = hairSplit.substring(0, hairSplit.lastIndexOf(" "));
        const colorHair = hairSplit.split(" ").pop();

        const indexNameHair = characterProperties.hairs.findIndex(item => item.name === nameHair);
        const indexColorHair = characterProperties.hairsColor.findIndex(item => item.color === colorHair);
        const indexLipstick = characterProperties.lipstick.findIndex(item => item.color === name.lipstick);

        const shirtSplit = name.shirt;
        const nameShirt = shirtSplit.substring(0, shirtSplit.lastIndexOf(" "));
        const colorShirt = shirtSplit.split(" ").pop();

        const indexNameShirt = characterProperties.shirts.findIndex(item => item.name === nameShirt);
        const indexColorShirt = characterProperties.clothesColor.findIndex(item => item.color === colorShirt);

        const pantSplit = name.pant;
        const namePant = pantSplit.substring(0, pantSplit.lastIndexOf(" "));
        const colorPant = pantSplit.split(" ").pop();

        const indexNamePant = characterProperties.pants.findIndex(item => item.name === namePant);
        const indexColorPant = characterProperties.clothesColor.findIndex(item => item.color === colorPant);

        const indexShoes = characterProperties.clothesColor.findIndex(item => item.color === name.shoes);
        const indexSkin = characterProperties.char.findIndex(item => item.name === name.skin);


        return {
            indexAccessoryOne,
            indexAccessoryTwo,
            indexAccessoryThree,
            indexBackground,
            indexBlush,
            indexDisguise,
            indexEyes,
            indexNameHair,
            indexColorHair,
            indexLipstick,
            indexNameShirt,
            indexColorShirt,
            indexNamePant,
            indexColorPant,
            indexShoes,
            indexSkin
        };
    }

    const [states, setStates] = useState(0);
    const [statesFooter, setStatesFooter] = useState(0);
    const [statesBtnArrows, setStatesBtnArrows] = useState(0);
    const IsAllTrue = search(char);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();
        ctx.imageSmoothingEnabled = false; // Enable image smoothing
        if (base64Img) {
            img.src = base64Img;

        }
        img.onload = () => {
            ctx.drawImage(img, 0, 0, 32, 32, 0, 0, 640, 640);
            const base64 = canvas.toDataURL();
            setCroppedBlob(base64);
        }
        //if all char object params are in the json file
    }, [base64Img]);


    useEffect(() => {
        if (
            IsAllTrue.indexAccessoryOne > -1 &&
            IsAllTrue.indexAccessoryTwo > -1 &&
            IsAllTrue.indexAccessoryThree > -1 &&
            IsAllTrue.indexBackground > -1 &&
            IsAllTrue.indexBlush > -1 &&
            IsAllTrue.indexDisguise > -1 &&
            IsAllTrue.indexEyes > -1 &&
            IsAllTrue.indexNameHair > -1 &&
            IsAllTrue.indexColorHair > -1 &&
            IsAllTrue.indexLipstick > -1 &&
            IsAllTrue.indexNameShirt > -1 &&
            IsAllTrue.indexColorShirt > -1 &&
            IsAllTrue.indexColorPant > -1 &&
            IsAllTrue.indexColorPant > -1 &&
            IsAllTrue.indexShoes > -1 &&
            IsAllTrue.indexSkin > -1

        ) {
            //if shirt is a dress need to remove pant
            if (IsAllTrue.indexNameShirt === 10) {
                //char.pant = "NONE"
            }
            const indexBackgroundColor = backgroundColor.bg.findIndex(item => item.name === char.background);
            document.body.style.backgroundColor = backgroundColor.bg[indexBackgroundColor].color;

            const hexToRgba = (hex, alpha = 1) => {
                const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
                return `rgba(${r}, ${g}, ${b}, ${alpha})`;
            }

            const invertColor = (hex) => {
                if (hex.indexOf('#') === 0) {
                    hex = hex.slice(1);
                }
                // convert 3-digit hex to 6-digits.
                if (hex.length === 3) {
                    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                }
                if (hex.length !== 6) {
                    throw new Error('Invalid HEX color.');
                }
                let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16).padStart(2, '0');
                let g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16).padStart(2, '0');
                let b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16).padStart(2, '0');
                return '#' + r + g + b;
            }
            const randomIndex = Math.floor(Math.random() * characterFirstname.length);
            const randomName = characterFirstname[randomIndex];
            setCharFirstName(randomName)

            const randomIndexLastName = Math.floor(Math.random() * characterLastname.length);
            const randomLastName = characterLastname[randomIndexLastName];
            setCharLastName(randomLastName)


            setFinalChar(
                {
                    "name": charFirstName + ' ' + charLastName,
                    "description": charFirstName + ' ' + charLastName + ' is an animated Peopixs.',
                    "image": croppedBlob,
                    "timestamp": Date.now(),
                    "background": backgroundColor.bg[indexBackgroundColor].color.slice(1),
                    "attributes": [
                        // accessory_one,
                        // accessory_two,
                        // accessory_three,
                        // disguise,


                        {
                            "trait_type": "background",
                            "value": char.background,
                        },
                        {
                            "trait_type": "skin",
                            "value": char.skin,
                        },
                        {
                            "trait_type": "shirt",
                            "value": char.shirt,
                        }
                        ,
                        {
                            "trait_type": "pant",
                            "value": char.pant,
                        },
                        {
                            "trait_type": "hair",
                            "value": char.hair,
                        }
                        ,
                        {
                            "trait_type": "eyes",
                            "value": char.eyes,
                        }
                        ,
                        {
                            "trait_type": "blush",
                            "value": char.blush,
                        }
                        ,
                        {
                            "trait_type": "lipstick",
                            "value": char.lipstick,
                        }
                        ,
                        {
                            "trait_type": "shoes",
                            "value": char.shoes,
                        }
                        ,
                        {
                            "trait_type": "accessory one",
                            "value": char.accessory_one,
                        }
                        ,
                        {
                            "trait_type": "accessory two",
                            "value": char.accessory_two,
                        }
                        ,
                        {
                            "trait_type": "accessory three",
                            "value": char.accessory_three,
                        }
                        ,
                        {
                            "trait_type": "disguise",
                            "value": char.disguise,
                        }

                    ],
                    "compiler": "Peopixs"


                })


        }

    }, [char, croppedBlob]);


    useEffect(() => {
        if (mintIsOpen) {
            const timeline = gsap.timeline({
                paused: true,
                defaults: { ease: 'bounce.out' },
            });

            timeline

                .fromTo(mintRef.current, { zIndex: 1, opacity: 0, top: '-200vh' }, {
                    zIndex: 0,
                    opacity: 1,
                    duration: 1,
                    top: 0,
                })
            document.body.style.overflow = 'visible';
            timeline.play();
        } else {
            const timeline = gsap.timeline({
                paused: true,
                defaults: { ease: 'power3.out' },
            });

            timeline

                .fromTo(mintRef.current, { top: '0vh' }, {

                    top: '-500vh',
                })

                .to('.content', {
                    opacity: 1,
                })
            // document.body.style.overflow = 'hidden';
            timeline.play();
        }
    }, [mintIsOpen]);

    useEffect(() => {
        onStateChangeFooter(statesFooter);
    }, [statesFooter]);


    const handlechangePosition = (key, e) => {
        setStatesFooter(key)
    };

    useEffect(() => {
        onStateChangeBtnPress(statesBtnArrows);
    }, [statesBtnArrows]);

    const handleBtnPress = (key, e) => {
        setStatesBtnArrows(key)
    };

    const handleBtnPressCancelMint = (e) => {
        changeMintState()
    };


    return (

        <div>

            <canvas ref={canvasRef} width={640} height={640} hidden />

            <div id="mint" ref={mintRef} className=" d-flex align-items-center justify-content-center menu-open" style={{ top: '-100%' }}>
                <div className='d-lg-none' id='close-mint-btn'>
                    <button className=' btn btn-danger'
                        onClick={(e) => {
                            handleBtnPressCancelMint()
                        }}
                    >
                        X
                    </button>
                </div>

                <div className='game'>
                    <div className="div-char text-center" id="gameScreen">
                        <p>{data.totalSupply != 0 ? data.totalSupply : '???'} / {CONFIG.MAX_SUPPLY}</p>

                        <div className='game-canvas d-flex align-items-center justify-content-center'>
                            {animatedChar}
                        </div>
                    </div>
                    <div className="div2 panel">
                        <div className='row'>
                            <div className='col-12 mb-3 d-lg-none'><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></div>

                            {animationData.animations
                                // .filter((n, key) => key < 7)
                                .map((n, key) => (

                                    <div className='col-4' key={key}>
                                        <button
                                            className=" btn-animation mb-3 "
                                            style={{ backgroundColor: backgroundColor.bg[key].color }}
                                            onClick={(e) => handlechangePosition(key, e)}
                                        >
                                            {n.name}
                                        </button>
                                    </div>
                                ))}
                            <div className='col-4'>
                                <button
                                    style={{ backgroundColor: backgroundColor.bg[13].color }}

                                    onClick={(e) => handleBtnPress(1, e)}
                                    className=" btn-animation">
                                    <i className="fa-solid fa-caret-up"></i>
                                </button>
                            </div>

                            <div className='col-4'>
                                <button
                                    style={{ backgroundColor: backgroundColor.bg[24].color }}

                                    onClick={(e) => handleBtnPress(0, e)}
                                    className=" btn-animation  ">
                                    <i className="fa-solid fa-caret-down"></i>
                                </button>
                            </div>
                            <div className='col-4' >

                                <button
                                    onClick={(e) => handleBtnPress(3, e)}
                                    style={{ backgroundColor: backgroundColor.bg[1].color }}
                                    className=" btn-animation ">
                                    <i className="fa-solid fa-caret-left"></i>
                                </button>
                            </div>
                            <div className='col-4' >

                                <button
                                    onClick={(e) => handleBtnPress(2, e)}
                                    style={{ backgroundColor: backgroundColor.bg[3].color }}

                                    className=" btn-animation ">
                                    <i className="fa-solid fa-caret-right"></i>

                                </button>
                            </div>
                            <div className='col-12 p-2'>
                                each nft includes all animations
                            </div>
                        </div>
                    </div>
                    <div className="div3">
                        <div className="row g-0 p-1 h-100 btns-mint  d-flex align-items-center justify-content-center text-center ">

                            {characterContent}
                            {!closePanel ? (
                                <>
                                    <Col lg={4} sm={12} className="text-center">
                                        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                                            <>
                                                <div
                                                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                                                >
                                                    The sale has ended.
                                                </div>
                                                <p
                                                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                                                >
                                                    You can still find {CONFIG.NFT_NAME} on
                                                </p>
                                                <hr />
                                                <a target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                                                    {CONFIG.MARKETPLACE}
                                                </a>
                                            </>
                                        ) : (
                                            <>
                                                {/* a remettre */}
                                                <p className='m-0'>Cost : {totalPriceInEth} ETH</p>
                                                <p className='m-0 text-center'><small>{CONFIG.NETWORK.NAME} Network</small></p>
                                            </>
                                        )}
                                    </Col>
                                    <Col lg={2} sm={12} className="text-center">

                                        {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                                            <>
                                            </>
                                        ) : (
                                            <>

                                                {blockchain.account === "" ||
                                                    blockchain.smartContract === null ? (
                                                    <div>

                                                        <button className=' btn btn-success'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                dispatch(connect());
                                                                getData();
                                                            }}
                                                        >
                                                            MINT SOON
                                                        </button>

                                                        {blockchain.errorMsg !== "" ? (
                                                            <>
                                                                <p
                                                                    style={{
                                                                        textAlign: "center",
                                                                        lineHeight: 1,
                                                                        marginTop: '1rem',
                                                                        color: 'red',
                                                                    }}
                                                                >
                                                                    <small>{blockchain.errorMsg}</small>
                                                                </p>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                ) : (
                                                    <>
                                                        {/* a remettre */}
                                                        <p className='text-center'>
                                                            <small> {feedback}</small>
                                                        </p>

                                                        <button className=' w-100 btn btn-success'
                                                            disabled={claimingNft ? 1 : 0}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                claimNFTs();
                                                                getData();
                                                            }}
                                                        >
                                                            {/* a remettre */}
                                                            {claimingNft ? "BUSY" : "MINT " + CONFIG.SYMBOL + " for " + totalPriceInEth + " " + CONFIG.NETWORK.SYMBOL}
                                                            {/* {claimingNft ? "BUSY" : "MINT "} */}
                                                        </button>
                                                    </>
                                                )}
                                            </>
                                        )}


                                    </Col>
                                    <Col lg={2} sm={12} className="text-center">

                                        <button className=' btn btn-danger'
                                            onClick={(e) => {
                                                handleBtnPressCancelMint()
                                            }}
                                        >
                                            CANCEL
                                        </button>
                                    </Col>

                                    {/* <Col lg={4} sm={12} className="text-center">
                                        <p className='text-center'>{CONFIG.NETWORK.NAME} Network</p>

                                    </Col> */}
                                </>

                            ) : (
                                <>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mint;