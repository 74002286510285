import React, { Component } from "react";
import utils from "../../utils/utils";

class Sprite extends Component {
    constructor(config) {
        super(config);


        // Set up the image
        this.image = new Image();
        this.image.src = config.src;
        this.image.onload = () => {
            this.isLoaded = true;
        };

        // Shadow
        this.shadow = new Image();
        this.useShadow = true; // config.config.useShadow || false
        if (this.useShadow) {
            this.shadow.src = "./assets/images/characters/shadow.png";
        }
        this.shadow.onload = () => {
            this.isShadowLoaded = true;
        };

        if (config.gameObject.props.id === 'hero') {
            // Configure Animation & Initial State
            this.animations = config.animations || {
                "idle-down": [[0, 0]],
                "idle-up": [[0, 1]],
                "idle-right": [[0, 2]],
                "idle-left": [[0, 3]],

                //FISH
                // "idle-down": [[0, 45]],
                // "idle-up": [[0, 46]],
                // "idle-right": [[0, 47]],
                // "idle-left": [[0, 48]],
                // ATK
                // "idle-down": [[0, 16]],
                // "idle-up": [[0, 17]],
                // "idle-right": [[0, 18]],
                // "idle-left": [[0, 19]],

                "walk-down": [[1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [0, 0]],
                "walk-up": [[1, 1], [2, 1], [3, 1], [4, 1], [5, 1], [6, 1], [7, 1], [0, 1]],
                "walk-right": [[1, 2], [2, 2], [3, 2], [4, 2], [5, 2], [6, 2], [7, 2], [0, 2]],
                "walk-left": [[1, 3], [2, 3], [3, 3], [4, 3], [5, 3], [6, 3], [7, 3], [0, 3]],

                //MOVE ATK
                // "walk-down": [[0, 16], [1, 16], [2, 16], [3, 16]],
                // "walk-left": [[0, 19], [1, 19], [2, 19], [3, 19]],

            };
        } else {
            // Configure Animation & Initial State
            this.animations = config.animations || {
                "idle-down": [[0, 0]],
                "idle-up": [[0, 1]],
                "idle-right": [[0, 2]],
                "idle-left": [[0, 3]],

                //FISH
                // "idle-down": [[0, 45]],
                // "idle-up": [[0, 46]],
                // "idle-right": [[0, 47]],
                // "idle-left": [[0, 48]],
                // ATK
                // "idle-down": [[0, 16]],
                // "idle-up": [[0, 17]],
                // "idle-right": [[0, 18]],
                // "idle-left": [[0, 19]],

                "walk-down": [[1, 0], [2, 0], [3, 0], [4, 0], [5, 0], [6, 0], [7, 0], [0, 0]],
                "walk-up": [[1, 1], [2, 1], [3, 1], [4, 1], [5, 1], [6, 1], [7, 1], [0, 1]],
                "walk-right": [[1, 2], [2, 2], [3, 2], [4, 2], [5, 2], [6, 2], [7, 2], [0, 2]],
                "walk-left": [[1, 3], [2, 3], [3, 3], [4, 3], [5, 3], [6, 3], [7, 3], [0, 3]],

                //MOVE ATK
                // "walk-down": [[0, 16], [1, 16], [2, 16], [3, 16]],
                // "walk-left": [[0, 19], [1, 19], [2, 19], [3, 19]],

            };
        }



        // this.currentAnimation = config.currentAnimation || "idle-down";
        this.currentAnimation = "idle-left";
        this.currentAnimationFrame = 0;

        this.animationFrameLimit = config.animationFrameLimit || 4
        this.animationFrameProgress = this.animationFrameLimit
        // Reference the game object
        this.gameObject = config.gameObject;
    }

    get frame() {
        return this.animations[this.currentAnimation][this.currentAnimationFrame]
    }

    setAnimation(key) {
        if (this.currentAnimation !== key) {
            this.currentAnimation = key;
            this.currentAnimationFrame = 0;
            this.animationFrameProgress = this.animationFrameLimit;
        }
    }

    updateAnimationProgress() {
        //Downtick frame progress
        if (this.animationFrameProgress > 0) {
            this.animationFrameProgress -= 1;
            return;
        }

        //reset the counter
        this.animationFrameProgress = this.animationFrameLimit
        this.currentAnimationFrame += 1;

        if (this.frame === undefined) {
            this.currentAnimationFrame = 0
        }
    }

    draw(ctx, cameraPerson) {

        const x = this.gameObject.x - 8 + utils.withGrid(10.5) - cameraPerson.x;
        const y = this.gameObject.y - 20 + utils.withGrid(6) - cameraPerson.y;

        this.isShadowLoaded &&
            ctx.drawImage(this.shadow, x, y + 2);

        const [frameX, frameY] = this.frame

        this.isLoaded &&
            ctx.drawImage(
                this.image,
                frameX * 32,
                frameY * 32,
                32,
                32,
                x,
                y,
                32,
                32
            );

        this.updateAnimationProgress()
    }

    render() {
        return <div />;
    }
}

export default Sprite;
