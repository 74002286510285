import React, { useContext, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './style.scss';
import Game from "../Game/Game";

const Roadmap = ({ isOpen }) => {
    const menuRef = useRef(null);
    // Sélectionnez tous les boutons de la page
    const buttons = document.querySelectorAll("button");

    // Bouclez sur chaque bouton et ajoutez un écouteur d'événements
    buttons.forEach((button) => {
        button.addEventListener("keydown", (event) => {
            // Si la touche pressée est la touche espace
            if (event.key === ' ' || event.key === 'Enter') {
                // Empêchez la propagation de l'événement
                event.preventDefault();
            }
        });
    });

    useEffect(() => {
        if (isOpen) {

            gsap.fromTo(menuRef.current, { zIndex: 1, top: "-100vh", rotation: 0 }, {
                opacity: 1,
                rotation: 360,
                zIndex: 0,
                delay: .5,
                duration: 1,
                top: "0",
                ease: 'bounce.out',
            });

            gsap.to("header", {
                duration: 1,
                opacity: 0,
                ease: "power3.out",
            });
            gsap.to("footer", {
                duration: 1,
                opacity: 0,
                ease: "power3.out",
            });
            gsap.to("body, #content", {
                duration: 1,
                overflowX: 'hidden',
                overflowY: 'hidden',
                ease: "power3.out",
            });
        } else {

            gsap.fromTo(menuRef.current, { top: "0vh", rotation: -360 }, {
                opacity: 0,
                duration: 1,
                rotation: 0,
                top: "-100vh",
                ease: 'power3.out',
            });


            gsap.to("header", {
                duration: 1,
                opacity: 1,
                ease: "power3.out",
            });
            gsap.to("footer", {
                duration: 1,
                opacity: 1,
                ease: "power3.out",
            });
            gsap.to("body, #content", {
                duration: 1,
                overflowX: 'hidden',
                overflowY: 'visible',
                ease: "power3.out",
            });
        }
    }, [isOpen]);



    return (
        <div>
            <div id="roadmap" ref={menuRef} className="">
                <div id="bg-overlay-roadmap" className='bg-overlay-roadmap' />
                <Game />
                {/* {demoCharImg ? <Game demoCharImg={demoCharImg} /> : null} */}
                <div style={{ color: 'white', display: 'none' }} className="p-4"  >
                    <p>
                        We pride ourselves on offering an immersive and engaging gaming experience through our Peopix. We are working tirelessly to improve our platform and add new and exciting features for our players. Here is what you can expect from us in the coming months:
                    </p>
                    <h4>
                        Phase 1: Launch and initial development Q1 2023
                    </h4>
                    <p>
                        In this phase, we will work on launching our platform and game. We will also work on basic improvements, such as optimizing our server and improving the user interface for a smoother gameplay experience.
                    </p>
                    <h4>
                        Phase 2: Adding features Q2 2023
                    </h4>
                    <p>
                        In this phase, we will add new features to our platform and game, including the introduction of the role-playing element as well as player interaction.
                    </p>
                    <h4>
                        Phase 3: Implementation of tokenomics and game economy Q3 2023
                    </h4>

                    <p>
                        In this phase, we will add a game economy to our platform, which means that players will be able to earn rewards and value for their Peopix by actively participating in the game. We will also work on tokenomics to add value to the environment and provide a more rewarding gaming experience for our players.
                    </p>

                    <h4>
                        Phase 4: Expansion and continuous improvement Q4 2023
                    </h4>

                    <p>
                        While we won't wait until Q4 2023 to share with you, in this phase we plan to expand our user base and continue to improve our platform and game based on feedback from our player community. We welcome all ideas and suggestions to improve our platform and provide an even better gaming experience.
                    </p>
                    <p>
                        Please note that this roadmap is not exhaustive and is subject to change. We understand that the timeline for achieving these goals may vary based on a variety of factors, and we are committed to remaining flexible and making adjustments as necessary. We are always open to feedback and suggestions from our community, and we look forward to continuing to build and improve the Peopixs project together.
                    </p>

                </div>
                {/* <div className='container row h-100 d-flex align-items-center justify-content-center'>

                </div> */}
            </div>
        </div >
    );
};

export default Roadmap;