import React, { useState } from 'react';
import './style.scss';
import Marquee from "react-fast-marquee";

function Header() {
    const [message] = useState('Welcome Home to PEOPIXS')
    return (
        <header className='row g-0'>
            <Marquee speed={40} className="shadow-marquee d-flex align-items-center col-12">
                <span>{message}</span>
                <span>{message}</span>
                <span>{message}</span>
                <span>{message}</span>
                <span>{message}</span>
            </Marquee>
        </header>

    );
}

export default Header;


