import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Peopixs from './components/pages/Peopixs';
import Home from "./components/Home/Home";
import Game from "./components/Game/Game";

const App = () => {

  //http://localhost:3000
  //https://node.vedym.com
  let url = ['http://localhost:3000', 'https://node.peopixs.io']
  let urlExternal = 'https://peopixs.io/';
  let urlJson = url[0]
  return (


    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Character url={urlJson} urlExternal={urlExternal} />} />
          <Route path="*" element={<Character url={urlJson} urlExternal={urlExternal} />} /> */}
          <Route path="/" element={

            <Home url={urlJson} urlExternal={urlExternal} />
          } />
          <Route path="*" element={
            <Home url={urlJson} urlExternal={urlExternal} />
          } />
          <Route path="/game" element={
            <div id="bg-overlay-roadmap"> <Game /></div>
            // <Game />
          } />
          <Route path="/peopixs/:id" element={<Peopixs url={urlJson} />} />

        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;