import React, { useState, useEffect, useRef, useContext } from 'react';
// import { UserContext } from '../contexts/user.context';

import animationData from '../Character/animations.json';
import backgroundColor from '../Character/backgroundColor.json';
import characterProperties from '../Character/properties.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Main from '../Main/Main';
import './style.scss';
import { DataContext } from "../../contexts/DataContext"
import Menu from '../Menu/Menu';
import Roadmap from '../Roadmap/Roadmap';
import Mint from '../Mint/Mint';
import utils from '../Game/utils/utils';


const Home = ({ url, urlExternal }) => {


    const [headerStates, setHeaderStates] = useState({});
    const [footerStates, setFooterStates] = useState(0);
    const [nbAnimBtn, setNbAnimBtn] = useState(0);

    const [priceAccOne, setPriceAccOne] = useState(0);
    const [priceAccTwo, setPriceAccTwo] = useState(0);
    const [priceAccThree, setPriceAccThree] = useState(0);

    const [priceDisguise, setPriceDisguise] = useState(0);

    const [priceAccTotal, setPriceAccTotal] = useState(0);

    const { menuIsOpen, roadmapIsOpen, mintIsOpen, demoChar, changeDemoChar } = useContext(DataContext);

    const handleStateChange = (states) => {
        setHeaderStates(states);
    };

    useEffect(() => {
        setNbAnimBtn(footerStates)
    }, [footerStates]);


    //create character object
    const [character, setCharacter] = useState({
        background: '',
        skin: '',
        eyes: '',
        blush: '',
        lipstick: '',
        shirt: '',
        pant: '',
        shoes: '',
        hair: '',
        accessory_one: '',
        accessory_two: '',
        accessory_three: '',
        disguise: '',
    });

    const [animationNb, setAnimationNb] = useState(0); // Initial frame
    const [bgColor, setBgColor] = useState(backgroundColor.bg[0].color);
    const [char, setChar] = useState(0); //between 1 and 8
    const [eyes, setEyes] = useState(0); //between 1 and 14
    const [blush, setBlush] = useState(0); //between 1 and 5
    const [lipstick, setLipstick] = useState(0); //between 1 and 5
    const [shirt, setShirt] = useState(0);
    const [nameShirt, setNameShirt] = useState('');
    const [allShirts, setAllShirts] = useState([]);
    const [pant, setPant] = useState(0);
    const [oldPant, setOldPant] = useState(0);
    const [namePant, setNamePant] = useState('');
    const [allPants, setAllPants] = useState([]);
    const [shoes, setShoes] = useState(0); // between 1 and 10
    const [hair, setHair] = useState(0);
    const [nameHair, setNameHair] = useState('');
    const [allHairs, setAllHairs] = useState([]);
    const [accessoryOne, setAccessoryOne] = useState(0);
    const [accessoryTwo, setAccessoryTwo] = useState(0);
    const [accessoryThree, setAccessoryThree] = useState(0);
    const [disguise, setDisguise] = useState(0); //between 0 and 6

    const [selectedBg, setSelectedBg] = useState(0);
    const [side, setSide] = useState(0);     // 0 FRONT - 1 BACK - 2 RIGHT - 3 LEFT
    const [frame, setFrame] = useState(0); // Initial frame
    const [frameVertical, setFrameVertical] = useState(0); // Initial frame
    const [animation, setAnimation] = useState(animationData.animations[animationNb]);
    const [frameRate, setFrameRate] = useState(100);
    const [croppedImage, setCroppedImage] = useState(null);
    const frameCount = animation.frameRates; // Total number of frames in the animation
    const canvasRef = useRef(null); // Ref to the canvas element
    const canvasRefGenerateSprite = useRef(null); // Ref to the canvas element
    const canvasRefGenerateSpriteToMint = useRef(null); // Ref to the canvas element
    const canvasRefGenerateSpriteToDemo = useRef(null); // Ref to the canvas element
    const sizeFrame = 32;
    const animationType = animation.start * sizeFrame
    const zoom = 10;

    let eyesPath = "./assets/eyes/eyes" + eyes + ".png"
    let charPath = "./assets/characters/char" + char + ".png"
    let lipStickPath = "./assets/lipstick/lipstick" + lipstick + ".png"
    let blushPath = "./assets/blush/blush" + blush + ".png"
    let shirtPath = "./assets/shirt/shirt" + shirt + ".png"
    let pantPath = "./assets/pant/pant" + pant + ".png"
    let shoesPath = "./assets/shoes/shoes" + shoes + ".png"
    let hairPath = "./assets/hair/hair" + hair + ".png"
    let AccessoryOnePath = "./assets/accessories_one/" + accessoryOne + ".png"
    let AccessoryTwoPath = "./assets/accessories_two/" + accessoryTwo + ".png"
    let AccessoryThreePath = "./assets/accessories_three/" + accessoryThree + ".png"
    let disguisePath = "./assets/disguises/disguise" + disguise + ".png"
    let incrementShirt = 0;
    let incrementPant = 0;
    let incrementHair = 0;
    let incrementAccessoryOne = 0;

    const [layer1, setLayer1] = useState(null);
    const [layer2, setLayer2] = useState(null);
    const [layer3, setLayer3] = useState(null);
    const [layer4, setLayer4] = useState(null);
    const [layer5, setLayer5] = useState(null);
    const [layer6, setLayer6] = useState(null);
    const [layer7, setLayer7] = useState(null);
    const [layer8, setLayer8] = useState(null);
    const [layer9, setLayer9] = useState(null);
    const [layer10, setLayer10] = useState(null);
    const [layer11, setLayer11] = useState(null);
    const [layer12, setLayer12] = useState(null);

    const [demoCharImg, setDemoCharImg] = useState(null);


    useEffect(() => {
        if (accessoryOne != 0) {
            setPriceAccOne(0.005)
        } else {
            setPriceAccOne(0)
        }
    }, [accessoryOne]);

    useEffect(() => {
        if (accessoryTwo != 0) {
            setPriceAccTwo(0.005)
        } else {
            setPriceAccTwo(0)
        }
    }, [accessoryTwo]);

    useEffect(() => {
        if (accessoryThree != 0) {
            setPriceAccThree(0.005)
        } else {
            setPriceAccThree(0)
        }
    }, [accessoryThree]);


    useEffect(() => {
        if (disguise != 0) {
            setPriceDisguise(0.005)
        } else {
            setPriceDisguise(0)
        }
    }, [disguise]);


    useEffect(() => {

        setPriceAccTotal(priceAccOne + priceAccTwo + priceAccThree + priceDisguise)

    }, [priceAccOne, priceAccTwo, priceAccThree, priceDisguise]);





    //random bgColor
    //random char
    //random eyes
    //random blush
    //random shirt

    useEffect(() => {

        if (menuIsOpen) {
            setSide(1)
            setAnimationNb(3)
            setAnimation(animationData.animations[3])
        } else {
            setSide(0)
            setAnimationNb(0)
            setAnimation(animationData.animations[0])
        }
    }, [menuIsOpen]);

    //RANDOM ALLSHIRTS
    useEffect(() => {
        const randomIndexShirt = Math.floor(Math.random() * ((characterProperties.shirts.length * characterProperties.clothesColor.length) - 1)) + 1;

        //combinate shirt and color for each
        const shirtsStylesWithColors = characterProperties.shirts.map(shirt => {
            return {
                ...shirt,
                colors: characterProperties.clothesColor.map(color => color.color)
            }
        });
        setAllShirts(shirtsStylesWithColors);
        //DRESS
        if (randomIndexShirt >= 100 && randomIndexShirt <= 110) {
            setShirt(1);
            //NONE
        } else if (randomIndexShirt >= 111 && randomIndexShirt <= 120) {
            setShirt(111);

        }
        else {
            setShirt(randomIndexShirt);
        }
    }, []);

    //random shirt 
    useEffect(() => {
        //search in all shirt and associate a name into character object
        for (const obj of allShirts) {
            if (shirt >= obj.range[0] && shirt <= obj.range[1]) {
                const colorIndex = (shirt - obj.range[0]) % obj.colors.length;
                setNameShirt(obj.name + ' ' + obj.colors[colorIndex])
                break // exit the loop once a match is found
            }
        }

    }, [allShirts]);

    //random ALLPANTS
    useEffect(() => {
        const randomIndexPant = Math.floor(Math.random() * ((characterProperties.pants.length * characterProperties.clothesColor.length) - 1)) + 1;

        //combinate shirt and color for each
        const pantsStylesWithColors = characterProperties.pants.map(pant => {
            return {
                ...pant,
                colors: characterProperties.clothesColor.map(color => color.color)
            }
        });
        setAllPants(pantsStylesWithColors);
        if (randomIndexPant >= 21 && randomIndexPant <= 30) {
            setPant(1);
            setOldPant(1)

            //NONE
        } else {
            setPant(randomIndexPant);
            setOldPant(randomIndexPant)

        }
    }, []);

    //random pant 
    useEffect(() => {

        for (const obj of allPants) {
            if (pant >= obj.range[0] && pant <= obj.range[1]) {
                const colorIndex = (pant - obj.range[0]) % obj.colors.length;
                setNamePant(obj.name + ' ' + obj.colors[colorIndex])
                break // exit the loop once a match is found
            }
        }

    }, [allPants]);

    //random Hair
    useEffect(() => {
        const randomIndexHair = Math.floor(Math.random() * ((characterProperties.hairs.length * characterProperties.hairsColor.length) - 1)) + 1;

        //combinate shirt and color for each
        const hairsStylesWithColors = characterProperties.hairs.map(hair => {
            return {
                ...hair,
                colors: characterProperties.hairsColor.map(color => color.color)
            }
        });
        //forbiden to get skirt hair on the launch because but if not skirt in pant
        if (randomIndexHair >= 57 && randomIndexHair <= 70) {
            setHair(1);
            //NONE
        } else if (randomIndexHair >= 127 && randomIndexHair <= 140) {
            setHair(1);
        } else {
            setHair(randomIndexHair);
        }
        setAllHairs(hairsStylesWithColors);
    }, []);

    //random hair 
    useEffect(() => {

        for (const obj of allHairs) {
            if (hair >= obj.range[0] && hair <= obj.range[1]) {
                const colorIndex = (hair - obj.range[0]) % obj.colors.length;
                setNameHair(obj.name + ' ' + obj.colors[colorIndex])
                break // exit the loop once a match is found
            }
        }

    }, [allHairs]);

    //change bg when i update select, the same for follow functions
    // function handleChangeBg(event) {

    //     // setCharacter({
    //     //     ...character,
    //     //     background: event.target.options[event.target.selectedIndex].getAttribute("name")
    //     // });
    //     // setSelectedBg(event.target.value);
    //     // setBgColor(event.target.value)
    // }

    function handleChangeBg(color) {
        setCharacter({
            ...character,
            background: color.name
        });
        setSelectedBg(color.color);
        setBgColor(color.color);
    }




    function handleChangeSkin(event) {
        const selectedSkin = event
        setCharacter({
            ...character,
            skin: selectedSkin
        });
        const charIndex = characterProperties.char.findIndex(skin => skin.name === selectedSkin)
        setChar(charIndex);

    }

    function handleChangeEyes(event) {
        const selectedEyes = event
        if (selectedEyes != "None") {

            setCharacter({
                ...character,
                eyes: selectedEyes
            });
            const eyesIndex = characterProperties.eyes.findIndex(eye => eye.color === selectedEyes)
            setEyes(eyesIndex);
        }
    }

    function handleChangeBlush(event) {
        const selectedBlush = event

        setCharacter({
            ...character,
            blush: selectedBlush
        });
        const blushIndex = characterProperties.blush.findIndex(blush => blush.color === selectedBlush)
        setBlush(blushIndex);
    }

    function handleChangeLipstick(event) {
        const selectedLipstick = event
        setCharacter({
            ...character,
            lipstick: selectedLipstick
        });
        const lipstickIndex = characterProperties.lipstick.findIndex(lipstick => lipstick.color === selectedLipstick)
        setLipstick(lipstickIndex);
    }

    // function handleChangeShirt(event) {

    //     console.log(event)
    //     const selectedName = event.target.options[event.target.selectedIndex].getAttribute("name")
    //     const selectedColor = event.target.options[event.target.selectedIndex].getAttribute("colorname")
    //     const selectedValue = event.target.value
    //     if (pant != 0) {
    //         setOldPant(pant)
    //     }

    //     //if dress 
    //     if (selectedName === 'DRESS') {
    //         setPant(0)
    //     } else {
    //         setPant(oldPant)
    //     }

    //     setCharacter({
    //         ...character,
    //         shirt: selectedValue
    //     });
    //     const shirtIndex = characterProperties.shirts.find((s) => s.name === selectedName);
    //     const colorIndex = characterProperties.clothesColor.findIndex(
    //         (c) => c.color === selectedColor
    //     );
    //     const range = shirtIndex.range;
    //     const res = range[0] + colorIndex;
    //     if (selectedValue === 'NONE') {
    //         setShirt(111)
    //     } else {
    //         setShirt(res)
    //     }

    //     setShirt(res)
    // }

    function handleChangeShirt(color, name) {

        const selectedName = name
        const selectedColor = color
        const selectedValue = name + ' ' + color


        if (pant != 0) {
            setOldPant(pant)
        }

        //if dress 
        if (selectedName === 'DRESS') {
            setPant(0)
        }
        else {
            setPant(oldPant)
        }

        setCharacter({
            ...character,
            shirt: selectedValue
        });
        const shirtIndex = characterProperties.shirts.find((s) => s.name === selectedName);
        const colorIndex = characterProperties.clothesColor.findIndex(
            (c) => c.color === selectedColor
        );
        const range = shirtIndex.range;
        const res = range[0] + colorIndex;
        if (selectedValue === 'NONE') {
            setShirt(111)
        } else {
            setShirt(res)
        }

        setShirt(res)
    }

    function handleChangePant(color, name) {
        const selectedName = name
        const selectedColor = color
        const selectedValue = name + ' ' + color


        // const selectedName = event.target.options[event.target.selectedIndex].getAttribute("name")
        // const selectedColor = event.target.options[event.target.selectedIndex].getAttribute("colorname")
        // const selectedValue = event.target.value
        setCharacter({
            ...character,
            pant: selectedValue
        });
        const pantIndex = characterProperties.pants.find((s) => s.name === selectedName);
        const colorIndex = characterProperties.clothesColor.findIndex(
            (c) => c.color === selectedColor
        );
        const range = pantIndex.range;


        const res = range[0] + colorIndex;

        if (res != 0) {
            setOldPant(res)
        }
        setPant(res)
        //when i switch pant or skirt, move hair select and choice for correspondant pant and hair 
        if (selectedName != 'SKIRT') {
            //Long strait  skirt    
            if (hair >= 57 && hair <= 70) {
                let otherHair = hair + 14;
                setHair(otherHair)
                for (const obj of allHairs) {
                    if (otherHair >= obj.range[0] && otherHair <= obj.range[1]) {
                        const colorIndex = (otherHair - obj.range[0]) % obj.colors.length;
                        setCharacter({
                            ...character,
                            hair: obj.name + ' ' + obj.colors[colorIndex],
                            pant: selectedValue
                        });
                    }
                }
            }
            if (hair >= 127 && hair <= 140) {
                let otherHair = hair - 14;
                setHair(otherHair)
                for (const obj of allHairs) {
                    if (otherHair >= obj.range[0] && otherHair <= obj.range[1]) {
                        const colorIndex = (otherHair - obj.range[0]) % obj.colors.length;
                        // console.log(obj.name + ' ' + obj.colors[colorIndex])
                        setCharacter({
                            ...character,
                            hair: obj.name + ' ' + obj.colors[colorIndex],
                            pant: selectedValue
                        });
                    }
                }
            }
        } else {
            if (hair >= 71 && hair <= 94) {
                let otherHair = hair - 14;
                setHair(otherHair)
                for (const obj of allHairs) {
                    if (otherHair >= obj.range[0] && otherHair <= obj.range[1]) {
                        const colorIndex = (otherHair - obj.range[0]) % obj.colors.length;
                        // console.log(obj.name + ' ' + obj.colors[colorIndex])
                        setCharacter({
                            ...character,
                            hair: obj.name + ' ' + obj.colors[colorIndex],
                            pant: selectedValue
                        });
                    }
                }
            }
            if (hair >= 113 && hair <= 126) {
                let otherHair = hair + 14;
                setHair(otherHair)
                for (const obj of allHairs) {
                    if (otherHair >= obj.range[0] && otherHair <= obj.range[1]) {
                        const colorIndex = (otherHair - obj.range[0]) % obj.colors.length;
                        setCharacter({
                            ...character,
                            hair: obj.name + ' ' + obj.colors[colorIndex],
                            pant: selectedValue
                        });
                    }
                }
            }
        }
    }

    function handleChangeShoes(event) {
        const selectedShoes = event
        if (selectedShoes != "None") {

            setCharacter({
                ...character,
                shoes: selectedShoes
            });
            const shoesIndex = characterProperties.clothesColor.findIndex(shoe => shoe.color === selectedShoes)
            setShoes(shoesIndex + 1);
        }
    }

    function handleChangeHair(color, name) {
        // const selectedName = event.target.options[event.target.selectedIndex].getAttribute("name")
        // const selectedColor = event.target.options[event.target.selectedIndex].getAttribute("colorname")
        // const selectedValue = event.target.value
        const selectedName = name
        const selectedColor = color
        const selectedValue = name + ' ' + color

        setCharacter({
            ...character,
            hair: selectedValue
        });
        const hairIndex = characterProperties.hairs.find((s) => s.name === selectedName);
        const colorIndex = characterProperties.hairsColor.findIndex(
            (c) => c.color === selectedColor
        );
        const range = hairIndex.range;
        const res = range[0] + colorIndex;
        if (selectedValue === 'SHORT') {
            setHair(211)
        } else {
            setHair(res)
        }
    }

    function handleChangeAccessoryOne(event) {
        // const selectedAccessoryOne = event.target.options[event.target.selectedIndex].getAttribute("name")
        const selectedAccessoryOne = event

        setCharacter({
            ...character,
            accessory_one: selectedAccessoryOne
        });
        const accessoryOneIndex = characterProperties.accessory_one.findIndex(accessoryOne => accessoryOne.name === selectedAccessoryOne)

        setAccessoryOne(accessoryOneIndex);
    }

    function handleChangeAccessoryTwo(event) {
        // const selectedAccessoryTwo = event.target.options[event.target.selectedIndex].getAttribute("name")
        const selectedAccessoryTwo = event
        setCharacter({
            ...character,
            accessory_two: selectedAccessoryTwo
        });
        const accessoryTwoIndex = characterProperties.accessory_two.findIndex(accessoryTwo => accessoryTwo.name === selectedAccessoryTwo)

        setAccessoryTwo(accessoryTwoIndex);
    }

    function handleChangeAccessoryThree(event) {
        // const selectedAccessoryThree = event.target.options[event.target.selectedIndex].getAttribute("name")
        const selectedAccessoryThree = event
        setCharacter({
            ...character,
            accessory_three: selectedAccessoryThree
        });
        const accessoryThreeIndex = characterProperties.accessory_three.findIndex(accessoryThree => accessoryThree.name === selectedAccessoryThree)

        setAccessoryThree(accessoryThreeIndex);
    }

    function handleChangeDisguise(event) {
        // const selectedDisguise = event.target.options[event.target.selectedIndex].getAttribute("name")
        const selectedDisguise = event

        setCharacter({
            ...character,
            disguise: selectedDisguise
        });
        const disguiseIndex = characterProperties.disguise.findIndex(disguise => disguise.name === selectedDisguise)
        setDisguise(disguiseIndex);
    }

    useEffect(() => {
        //get random number for elements , max is max array length
        const randomIndexBg = Math.floor(Math.random() * backgroundColor.bg.length);
        //whitout 0 number because char0.png, eyes0.png ... not exist (-1 + 1)
        const randomIndexCharacterProperties = Math.floor(Math.random() * (characterProperties.char.length - 1)) + 1;
        const randomIndexEyes = Math.floor(Math.random() * (characterProperties.eyes.length - 1)) + 1;
        const randomIndexBlush = Math.floor(Math.random() * characterProperties.blush.length);
        const randomIndexLipstick = Math.floor(Math.random() * (characterProperties.lipstick.length));
        const randomIndexShoes = Math.floor(Math.random() * (characterProperties.clothesColor.length)) + 1;
        const randomIndexAccessoryOne = 0;
        const randomIndexAccessoryTwo = 0;
        const randomIndexAccessoryThree = 0;
        const randomIndexDisguise = 0;

        setBgColor(backgroundColor.bg[randomIndexBg].color);
        setChar(randomIndexCharacterProperties);
        setEyes(randomIndexEyes);
        setBlush(randomIndexBlush);
        setLipstick(randomIndexLipstick);
        setShoes(randomIndexShoes);

        setAccessoryOne(randomIndexAccessoryOne)
        setAccessoryTwo(randomIndexAccessoryTwo)
        setAccessoryThree(randomIndexAccessoryThree)
        setDisguise(randomIndexDisguise);

        setSelectedBg(backgroundColor.bg[randomIndexBg].color)
        //put information into character object
        setCharacter({
            ...character,
            background: backgroundColor.bg[randomIndexBg].name,
            skin: characterProperties.char[randomIndexCharacterProperties].name,
            eyes: characterProperties.eyes[randomIndexEyes].color,
            blush: characterProperties.blush[randomIndexBlush].color,
            lipstick: characterProperties.lipstick[randomIndexLipstick].color,
            shirt: nameShirt,
            pant: namePant,
            shoes: characterProperties.clothesColor[randomIndexShoes - 1].color,
            hair: nameHair,
            accessory_one: characterProperties.accessory_one[randomIndexAccessoryOne].name,
            accessory_two: characterProperties.accessory_two[randomIndexAccessoryTwo].name,
            accessory_three: characterProperties.accessory_three[randomIndexAccessoryThree].name,
            disguise: characterProperties.disguise[randomIndexDisguise].name,

        });

    }, [nameShirt, namePant, nameHair]);

    useEffect(() => {
        //when push different keys, do action
        // window.addEventListener('keydown', handleKeyPress);
        // function handleKeyPress(event) {
        //     if (event.code === 'ArrowRight') {
        //         setSide(2);
        //     }
        //     if (event.code === 'ArrowLeft') {
        //         setSide(3);
        //     }
        //     if (event.code === 'ArrowUp') {
        //         setSide(1);
        //     }
        //     if (event.code === 'ArrowDown') {
        //         setSide(0);
        //     }
        // }
        //if one frame (die patern have only one frame in height)
        if (animationData.animations[animationNb].heightCell === 1) {
            setSide(0);
        }
        const canvasSprite = canvasRefGenerateSprite.current;
        canvasSprite.height = "1568";
        canvasSprite.width = "256";
        const ctxSprite = canvasSprite.getContext('2d');

        const canvasSpriteToMint = canvasRefGenerateSpriteToMint.current;
        canvasSpriteToMint.height = "1568";
        canvasSpriteToMint.width = "256";
        const ctxSpriteToMint = canvasSpriteToMint.getContext('2d');

        const canvasSpriteToDemo = canvasRefGenerateSpriteToDemo.current;
        canvasSpriteToDemo.height = "1568";
        canvasSpriteToDemo.width = "256";
        const ctxSpriteToDemo = canvasSpriteToDemo.getContext('2d');


        if (layer1 && layer2 && layer3 && layer4 && layer5 && layer6 && layer7 && layer8 && layer9 && layer10 && layer11 && layer12) {
            ctxSprite.clearRect(0, 0, canvasSprite.width, canvasSprite.height);
            ctxSprite.fillStyle = bgColor; // Set the color to purple
            ctxSprite.fillRect(0, 0, canvasSprite.width, canvasSprite.height); // Apply the filter
            ctxSprite.drawImage(layer1, 0, - side * sizeFrame);
            ctxSprite.drawImage(layer2, 0, - side * sizeFrame);
            ctxSprite.drawImage(layer3, 0, - side * sizeFrame);
            ctxSprite.drawImage(layer4, 0, - side * sizeFrame);

            //if disguise , hide shirt and pant
            if (disguise === 0) {
                ctxSprite.drawImage(layer5, 0, - side * sizeFrame);
                ctxSprite.drawImage(layer6, 0, - side * sizeFrame);
            }
            ctxSprite.drawImage(layer12, 0, - side * sizeFrame);
            ctxSprite.drawImage(layer7, 0, - side * sizeFrame);
            ctxSprite.drawImage(layer8, 0, - side * sizeFrame);
            ctxSprite.drawImage(layer9, 0, - side * sizeFrame);
            ctxSprite.drawImage(layer10, 0, - side * sizeFrame);
            ctxSprite.drawImage(layer11, 0, - side * sizeFrame);

            // FOR MINT
            ctxSpriteToMint.clearRect(0, 0, canvasSprite.width, canvasSprite.height);
            ctxSpriteToMint.fillStyle = bgColor; // Set the color to purple
            ctxSpriteToMint.fillRect(0, 0, canvasSprite.width, canvasSprite.height); // Apply the filter
            ctxSpriteToMint.drawImage(layer1, 0, - 0 * sizeFrame);
            ctxSpriteToMint.drawImage(layer2, 0, - 0 * sizeFrame);
            ctxSpriteToMint.drawImage(layer3, 0, - 0 * sizeFrame);
            ctxSpriteToMint.drawImage(layer4, 0, - 0 * sizeFrame);

            //if disguise , hide shirt and pant
            if (disguise === 0) {
                ctxSpriteToMint.drawImage(layer5, 0, - 0 * sizeFrame);
                ctxSpriteToMint.drawImage(layer6, 0, - 0 * sizeFrame);
            }
            ctxSpriteToMint.drawImage(layer12, 0, - 0 * sizeFrame);
            ctxSpriteToMint.drawImage(layer7, 0, - 0 * sizeFrame);
            ctxSpriteToMint.drawImage(layer8, 0, - 0 * sizeFrame);
            ctxSpriteToMint.drawImage(layer9, 0, - 0 * sizeFrame);
            ctxSpriteToMint.drawImage(layer10, 0, - 0 * sizeFrame);
            ctxSpriteToMint.drawImage(layer11, 0, - 0 * sizeFrame);

            // FOR demo
            ctxSpriteToDemo.clearRect(0, 0, canvasSprite.width, canvasSprite.height);
            ctxSpriteToDemo.globalCompositeOperation = 'destination-out';
            ctxSpriteToDemo.fillStyle = bgColor;

            //  ctxSpriteToMint.fillStyle = bgColor; // Set the color to purple
            ctxSpriteToDemo.fillRect(0, 0, canvasSprite.width, canvasSprite.height); // Apply the filter
            ctxSpriteToDemo.globalCompositeOperation = 'source-over';

            ctxSpriteToDemo.drawImage(layer1, 0, - 0 * sizeFrame);
            ctxSpriteToDemo.drawImage(layer2, 0, - 0 * sizeFrame);
            ctxSpriteToDemo.drawImage(layer3, 0, - 0 * sizeFrame);
            ctxSpriteToDemo.drawImage(layer4, 0, - 0 * sizeFrame);

            //if disguise , hide shirt and pant
            if (disguise === 0) {
                ctxSpriteToDemo.drawImage(layer5, 0, - 0 * sizeFrame);
                ctxSpriteToDemo.drawImage(layer6, 0, - 0 * sizeFrame);
            }
            ctxSpriteToDemo.drawImage(layer12, 0, - 0 * sizeFrame);
            ctxSpriteToDemo.drawImage(layer7, 0, - 0 * sizeFrame);
            ctxSpriteToDemo.drawImage(layer8, 0, - 0 * sizeFrame);
            ctxSpriteToDemo.drawImage(layer9, 0, - 0 * sizeFrame);
            ctxSpriteToDemo.drawImage(layer10, 0, - 0 * sizeFrame);
            ctxSpriteToDemo.drawImage(layer11, 0, - 0 * sizeFrame);

        }
        const base64 = canvasSpriteToMint.toDataURL();
        const base64Demo = canvasSpriteToDemo.toDataURL();

        if (base64Demo) {
            // document.cookie = `charImg='${base64Demo}'; expires=Thu, 01 Jan 2970 00:00:00 GMT`;
            sessionStorage.clear();
            sessionStorage.setItem('charImg', base64Demo);

            //utils
            // utils.testUtil(base64Demo)
            setDemoCharImg(base64Demo)
        }
        setCroppedImage(base64);

        // return () => window.removeEventListener('keydown', handleKeyPress);
    }, [handleChangeSkin, handleChangeEyes, handleChangeBlush, handleChangeLipstick, handleChangeShirt, handleChangePant, handleChangeShoes, handleChangeHair, handleChangeAccessoryOne, handleChangeAccessoryTwo, handleChangeAccessoryThree, side, handlechangePosition, handleChangeDisguise, demoChar]);

    const [results, setResults] = useState([]);
    const [data, setData] = useState(null);
    useEffect(() => {
        if (demoCharImg)
            setResults(prevResults => [...prevResults, demoCharImg]);
    }, [demoCharImg]);


    useEffect(() => {
        if (results[3]) {
            if (data === null) {
                setData(results[3])
            }
        } else if (results[2]) {
            if (data === null) {
                setData(results[2])
            }
        }
        // storing input name
        // localStorage.removeItem("demoCharImg")
        // localStorage.setItem("demoCharImg", demoCharImg);
        // console.log(results.pop());

    }, [results]);


    function handleLayer1Loaded(e) {
        setLayer1(e.target);
    }
    function handleLayer2Loaded(e) {
        setLayer2(e.target);
    }
    function handleLayer3Loaded(e) {
        setLayer3(e.target);
    }
    function handleLayer4Loaded(e) {
        setLayer4(e.target);
    }
    function handleLayer5Loaded(e) {
        setLayer5(e.target);
    }
    function handleLayer6Loaded(e) {
        setLayer6(e.target);
    }
    function handleLayer7Loaded(e) {
        setLayer7(e.target);
    }
    function handleLayer8Loaded(e) {
        setLayer8(e.target);
    }
    function handleLayer9Loaded(e) {
        setLayer9(e.target);
    }
    function handleLayer10Loaded(e) {
        setLayer10(e.target);
    }
    function handleLayer11Loaded(e) {
        setLayer11(e.target);
    }
    function handleLayer12Loaded(e) {
        setLayer12(e.target);
    }

    function handlechangePosition(params, e) {
        setAnimationNb(params)
        setAnimation(animationData.animations[params])
    }

    const handleStateChangeFooter = (states) => {
        setFooterStates(states)
        setAnimationNb(states)
        setAnimation(animationData.animations[states])

    };

    const handleStateChangeBtn = (states) => {
        // setFooterStates(states)
        // setAnimationNb(states)
        // setAnimation(animationData.animations[states])
        setSide(states)
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFrame((frame + 1) % frameCount);
            setFrameVertical(animationData.animations[animationNb].vertical[frame]);
        }, frameRate); // Set the frame change interval
        return () => clearInterval(intervalId);
    }, [frame]);

    useEffect(() => {
        let animationTurn1 = 0;
        let animationTurn2 = 0;
        if (animationData.animations[animationNb].vertical) {
            animationTurn1 = animationType;
            animationTurn2 = frameVertical * sizeFrame;

        } else {
            animationTurn1 = frame * sizeFrame;
            animationTurn2 = animationType;
        }
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.src = canvasRefGenerateSprite.current.toDataURL();

        img.onload = () => {
            // ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.imageSmoothingEnabled = false; // Enable image smoothing
            ctx.drawImage(
                img,
                animationTurn1, // Source x
                animationTurn2, // Source y
                sizeFrame, // Source width
                sizeFrame, // Source height
                0, // Destination x
                0, // Destination y
                sizeFrame * zoom, // Destination width
                sizeFrame * zoom // Destination height
            );
        };
    }, [frame]);





    let animatedChar = <>
        <img src={charPath} onLoad={handleLayer1Loaded} hidden />
        <img src={eyesPath} onLoad={handleLayer2Loaded} hidden />
        <img src={lipStickPath} onLoad={handleLayer3Loaded} hidden />
        <img src={blushPath} onLoad={handleLayer4Loaded} hidden />
        <img src={shirtPath} onLoad={handleLayer5Loaded} hidden />
        <img src={pantPath} onLoad={handleLayer6Loaded} hidden />
        <img src={disguisePath} onLoad={handleLayer12Loaded} hidden />
        <img src={shoesPath} onLoad={handleLayer7Loaded} hidden />
        <img src={hairPath} onLoad={handleLayer8Loaded} hidden />
        <img src={AccessoryOnePath} onLoad={handleLayer9Loaded} hidden />
        <img src={AccessoryTwoPath} onLoad={handleLayer10Loaded} hidden />
        <img src={AccessoryThreePath} onLoad={handleLayer11Loaded} hidden />
        <canvas ref={canvasRefGenerateSprite} hidden />
        <canvas ref={canvasRefGenerateSpriteToMint} hidden />
        <canvas ref={canvasRefGenerateSpriteToDemo} hidden id="demo-char" />

        <canvas id="character" ref={canvasRef} width={sizeFrame * zoom} height={sizeFrame * zoom} />
    </>

    let charWhenMint = null;
    let charWhenMain = animatedChar;
    if (mintIsOpen) {
        charWhenMint = animatedChar
        charWhenMain = null
    } else {
        charWhenMint = null;
        charWhenMain = animatedChar;
    }

    const [buttonStates, setButtonStates] = useState({
        showColorsBg: false,
        showColorsSkin: false,
        showColorsEyes: false,
        showColorsBlush: false,
        showColorsLips: false,
        showColorsShirt: false,
        showColorsPant: false,
        showColorsShoes: false,
        showColorsHair: false,
        showColorsAccessoryOne: false,
        showColorsAccessoryTwo: false,
        showColorsAccessoryThree: false,
        showColorsDisguise: false,
    });
    const [closePanel, setClosePanel] = useState(false);

    const handleShowButton = (buttonName, closeAll = false) => {
        const updatedStates = {};
        for (const button in buttonStates) {
            if (closeAll) {
                updatedStates[button] = false;
                setClosePanel(false)

            } else {
                updatedStates[button] = button === buttonName;
                setClosePanel(true)

            }
        }
        setButtonStates(updatedStates);
    };

    const [showShirtColors, setShowShirtColors] = useState(false);
    const [selectedShirt, setSelectedShirt] = useState(null);

    const [showPantColors, setShowPantColors] = useState(false);
    const [selectedPant, setSelectedPant] = useState(null);

    const [showHairColors, setShowHairColors] = useState(false);
    const [selectedHair, setSelectedHair] = useState(null);

    const [showAccessoryOneColors, setShowAccessoryOneColors] = useState(false);
    const [selectedAccessoryOne, setSelectedAccessoryOne] = useState(null);

    const [showAccessoryTwoColors, setShowAccessoryTwoColors] = useState(false);
    const [selectedAccessoryTwo, setSelectedAccessoryTwo] = useState(null);

    const [showAccessoryThreeColors, setShowAccessoryThreeColors] = useState(false);
    const [selectedAccessoryThree, setSelectedAccessoryThree] = useState(null);

    const [showAccessoryDisguiseColors, setShowAccessoryDisguiseColors] = useState(false);
    const [selectedAccessoryDisguise, setSelectedAccessoryDisguise] = useState(null);


    const handleShowButtonShirts = (event) => {
        setShowShirtColors(event);
        setSelectedShirt(null);
    };
    const handleShowButtonPants = (event) => {
        setShowPantColors(event);
        setSelectedPant(null);
    };
    const handleShowButtonHairs = (event) => {
        setShowHairColors(event);
        setSelectedHair(null);
    };
    const handleShowButtonAccessoryOne = (event) => {
        setShowAccessoryOneColors(event);
        setSelectedAccessoryOne(null);
    };
    const handleShowButtonAccessoryTwo = (event) => {
        setShowAccessoryTwoColors(event);
        setSelectedAccessoryTwo(null);
    };
    const handleShowButtonAccessoryThree = (event) => {
        setShowAccessoryThreeColors(event);
        setSelectedAccessoryThree(null);
    };

    const handleShowButtonAccessoryDisguise = (event) => {
        setShowAccessoryDisguiseColors(event);
        setSelectedAccessoryDisguise(null);
    };
    const handleButtonClickShirt = (name) => {
        setShowShirtColors(true);
        setSelectedShirt(name);
    };
    const handleButtonClickPant = (name) => {
        setShowPantColors(true);
        setSelectedPant(name);
    };
    const handleButtonClickHair = (name) => {
        setShowHairColors(true);
        setSelectedHair(name);
    };
    const handleButtonClickAccessoryOne = (name) => {
        setShowAccessoryOneColors(true);
        setSelectedAccessoryOne(name);
    };
    const handleButtonClickAccessoryTwo = (name) => {
        setShowAccessoryTwoColors(true);
        setSelectedAccessoryTwo(name);
    };
    const handleButtonClickAccessoryThree = (name) => {
        setShowAccessoryThreeColors(true);
        setSelectedAccessoryThree(name);
    };
    const handleButtonClickAccessoryDisguise = (name) => {
        setShowAccessoryDisguiseColors(true);
        setSelectedAccessoryDisguise(name);
    };
    const displayedWords = new Set(); // Initialize a Set to keep track of the displayed words


    let charContent =
        <>
            {buttonStates.showColorsBg ? (
                <Col sm={12} className='position-relative h-100'>
                    <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                    <div className="overlay-mint-options">
                        <div className="imagemaker_colorpalette" >
                            <div className='row  d-flex justify-content-center align-items-center' >
                                <div className='col-12'>Background <p style={{ color: backgroundColor.bg.find(color => color.name == character.background)?.color, }}>{character.background}</p> </div>
                                {backgroundColor.bg.map((color, index) => (
                                    <button
                                        key={index}
                                        className={selectedBg === color.color ? "col-1 m-1 selected text-center" : "col-1 m-1 text-center"}
                                        style={{
                                            textAlign: "center",
                                            backgroundColor: color.color,
                                            width: "40px",
                                            height: "40px",
                                            margin: " auto",
                                            border: "5px dashed #212121"
                                        }}
                                        onClick={() => handleChangeBg(color)}
                                    ></button>
                                ))}
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1">
                        <button style={{ backgroundColor: backgroundColor.bg.find(color => color.name == character.background)?.color, }} onClick={() => handleShowButton('showColorsBg')} data-name={character.background}>Background</button>
                    </Col>
                ) : null
            )
            }

            {buttonStates.showColorsSkin ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12'>
                            <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>

                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' >
                                        <div className='col-12'>Skin <p style={{ color: backgroundColor.bg_mint.find(color => color.name == character.skin.toLowerCase())?.color, }}>{character.skin}</p></div>
                                        {characterProperties.char.map((n, key) => (
                                            <button
                                                className="col-1 m-1 text-center"

                                                key={key}
                                                name={n.name} value={n.name}
                                                style={{
                                                    backgroundColor: backgroundColor.bg_mint.find(color => color.name == n.name.toLowerCase())?.color,

                                                    width: "40px",
                                                    height: "40px",
                                                    margin: "10px",
                                                    border: "5px dashed #212121"
                                                }}

                                                onClick={() => handleChangeSkin(n.name)}
                                            ></button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1">
                        <button style={{ backgroundColor: backgroundColor.bg_mint.find(color => color.name == character.skin.toLowerCase())?.color, }} onClick={() => handleShowButton('showColorsSkin')} data-name={character.skin}>Skin</button>
                    </Col>
                ) : null
            )
            }


            {buttonStates.showColorsEyes ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12'>
                            <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>

                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' >
                                        <div className='col-12'>Eyes <p style={{ color: backgroundColor.bg_mint.find(color => color.name == character.eyes.toLowerCase())?.color, }}>{character.eyes}</p></div>
                                        {characterProperties.eyes
                                            .filter(entry => entry.color !== "None")
                                            .map((n, key) => (
                                                <button
                                                    key={key}
                                                    name={n.color} value={n.color}
                                                    className="col-1 m-1 text-center"

                                                    style={{
                                                        backgroundColor: backgroundColor.bg_mint.find(color => color.name == n.color.toLowerCase())?.color,
                                                        width: "40px",
                                                        height: "40px",
                                                        margin: "10px",
                                                        border: "5px dashed #212121"
                                                    }}

                                                    onClick={() => handleChangeEyes(n.color)}
                                                ></button>
                                            ))}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="input-group">
                                <label className="input-group-text" htmlFor="inputEyes">Eyes</label>
                                <select className="form-select" id="inputEyes" value={character.eyes} onChange={handleChangeEyes}>
                                    {characterProperties.eyes
                                        .filter(entry => entry.color !== "None")
                                        .map((n, key) => (
                                            <option key={key} name={n.color} value={n.color}>
                                                {n.color}
                                            </option>
                                        ))}
                                </select>
                            </div> */}
                        </div>
                    </div>

                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1">
                        <button style={{ backgroundColor: backgroundColor.bg_mint.find(color => color.name == character.eyes.toLowerCase())?.color, }} onClick={() => handleShowButton('showColorsEyes')} data-name={character.eyes}>Eyes</button>
                    </Col>
                ) : null
            )
            }

            {buttonStates.showColorsBlush ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12'>
                            <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>

                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' >
                                        <div className='col-12'>Blush <p style={{ color: backgroundColor.bg_mint.find(color => color.name == character.blush.toLowerCase())?.color, }}>{character.blush}</p></div>
                                        {characterProperties.blush.map((n, key) => (
                                            <button
                                                key={key}
                                                className="col-1 m-1 text-center"

                                                name={n.color} value={n.color}
                                                style={{
                                                    backgroundColor: n.color === "None" ? "white" : backgroundColor.bg_mint.find(color => color.name == n.color.toLowerCase())?.color,
                                                    width: "40px",
                                                    height: "40px",
                                                    margin: "10px",
                                                    border: "5px dashed #212121"
                                                }}

                                                onClick={() => handleChangeBlush(n.color)}
                                            >{n.color === "None" ? "X" : ""}</button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1">
                        <button style={{ backgroundColor: backgroundColor.bg_mint.find(color => color.name == character.blush.toLowerCase())?.color, }} onClick={() => handleShowButton('showColorsBlush')} data-name={character.blush}>Blush</button>
                    </Col>
                ) : null
            )
            }

            {buttonStates.showColorsLips ? (

                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12'>
                            <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' >
                                        <div className='col-12'>Lips <p style={{ color: backgroundColor.bg_mint.find(color => color.name == character.lipstick.toLowerCase())?.color, }}>{character.lipstick}</p></div>
                                        {characterProperties.lipstick.map((n, key) => (
                                            <button
                                                key={key}
                                                className="col-1 m-1 text-center"

                                                name={n.color} value={n.color}
                                                style={{
                                                    backgroundColor: backgroundColor.bg_mint.find(color => color.name == n.color.toLowerCase())?.color,

                                                    width: "40px",
                                                    height: "40px",
                                                    margin: "10px",
                                                    border: "5px dashed #212121"
                                                }}

                                                onClick={() => handleChangeLipstick(n.color)}
                                            ></button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1">
                        <button style={{ backgroundColor: backgroundColor.bg_mint.find(color => color.name == character.lipstick.toLowerCase())?.color, }} onClick={() => handleShowButton('showColorsLips')} data-name={character.lipstick}>Lips</button>
                    </Col>
                ) : null
            )
            }

            {buttonStates.showColorsShirt ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row '>
                        <div className='col-12'>
                            {!showShirtColors ? (
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                            ) :
                                <button className='close-btn' onClick={() => handleShowButtonShirts(false)}>X</button>

                            }
                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' style={{ height: '30vh', overflowY: 'auto' }}  >
                                        <div className='col-12'>Shirt <p style={{ color: backgroundColor.bg_mint.find(color => color.name == character.shirt.split(' ')[1].toLowerCase())?.color, }}>{character.shirt} </p>
                                            {/* <small><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></small> */}

                                        </div>
                                        <div className='row d-flex justify-content-center align-items-center'>
                                            <div className='col-12 mb-3 d-lg-none'><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></div>

                                            {allShirts.map((n, key) => (
                                                <div className={showShirtColors ? 'col-12' : 'col-12  col-lg-3'} key={key}>
                                                    {/* {showShirtColors ? ( */}
                                                    {selectedShirt === n.name ? (


                                                        <div className='row d-flex justify-content-center align-items-center'>
                                                            {n.colors.map((color) => (
                                                                <button
                                                                    key={color}
                                                                    className="col-1 m-1 text-center"
                                                                    name={n.name}
                                                                    colorname={color}
                                                                    value={n.name + ' ' + color}
                                                                    style={{
                                                                        backgroundColor: backgroundColor.bg_mint.find(colorF => colorF.name == color.toLowerCase())?.color,
                                                                        width: "40px",
                                                                        height: "40px",
                                                                        margin: "10px",
                                                                        border: "5px dashed #212121",
                                                                        display: showShirtColors ? 'flex' : 'none',
                                                                    }}
                                                                    onClick={() => handleChangeShirt(color, n.name)}
                                                                ></button>

                                                            ))}
                                                        </div>
                                                    ) : (
                                                        !showShirtColors ? (
                                                            incrementPant++,
                                                            <button key={incrementPant} onClick={() => handleButtonClickShirt(n.name)}>{n.name}</button>

                                                        ) : null
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

                // <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                //     <div className='row'>
                //         <div className='col-12'>
                //             <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>

                //             <div className="input-group">
                //                 <label className="input-group-text" htmlFor="inputShirt">Shirt</label>
                //                 <select className="form-select" id="inputShirt" value={character.shirt} onChange={handleChangeShirt}>
                //                     {allShirts.map((n) => (
                //                         n.colors.map((color) => (
                //                             incrementShirt++,
                //                             <option key={incrementShirt} idclothe={incrementShirt} name={n.name} colorname={color} value={n.name + ' ' + color}>
                //                                 {n.name}  {color}
                //                             </option>
                //                         ))
                //                     ))}
                //                 </select>
                //             </div>
                //         </div>
                //     </div>

                // </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1">
                        <button
                            style={{
                                backgroundColor: backgroundColor.bg_mint.find(
                                    color =>
                                        color.name ===
                                        character.shirt?.split(' ')[1]?.toLowerCase() // Add null check here
                                )?.color,
                            }}
                            onClick={() => handleShowButton('showColorsShirt')}
                            data-name={character.shirt}
                        >
                            Shirt
                        </button>
                    </Col>
                ) : null
            )
            }

            {
                buttonStates.showColorsPant ? (

                    <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                        <div className='row w-100'>
                            <div className='col-12'>
                                {!showPantColors ? (
                                    <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                                ) :
                                    <button className='close-btn' onClick={() => handleShowButtonPants(false)}>X</button>

                                }
                                <div className="overlay-mint-options w-100">
                                    <div className="imagemaker_colorpalette w-100" >
                                        <div className='row  d-flex justify-content-center align-items-center' style={{ height: '30vh', overflowY: 'auto' }}  >
                                            <div className='col-12'>Pant <p style={{ color: backgroundColor.bg_mint.find(color => color.name == character.pant.split(' ')[1].toLowerCase())?.color, }}>{pant > 0 ? character.pant : 'NONE'} </p>
                                                {/* <small><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></small> */}

                                            </div>
                                            <div className='row d-flex justify-content-center align-items-center'>
                                                <div className='col-12 mb-3 d-lg-none'><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></div>

                                                {
                                                    (character.shirt.split(" ")[0] === 'DRESS') ?
                                                        (
                                                            // <option key={111} idclothe={111} name={"NONE"} colorname={"NONE"}>NONE</option>
                                                            <div className='col-12'>
                                                                <div className='row d-flex justify-content-center align-items-center'>
                                                                    <button
                                                                        key={111}
                                                                        className="col-1 m-1 text-center"
                                                                        name={"NONE"}
                                                                        colorname={"NONE"}
                                                                        value={"NONE NONE"}
                                                                        style={{
                                                                            backgroundColor: "white",
                                                                            width: "40px",
                                                                            height: "40px",
                                                                            margin: "10px",
                                                                            border: "5px dashed #212121",
                                                                            // display: showPantColors ? 'flex' : 'none',
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            </div>
                                                        ) :

                                                        allPants.map((n, key) => (
                                                            <div className={showPantColors ? 'col-12' : 'col-12  col-lg-4'} key={key}>
                                                                {selectedPant === n.name ? (

                                                                    <div className='col-12'>
                                                                        <div className='row d-flex justify-content-center align-items-center'>

                                                                            {n.colors.map((color) => (

                                                                                <button
                                                                                    key={color}
                                                                                    className="col-1 m-1 text-center"
                                                                                    name={n.name}
                                                                                    colorname={color}
                                                                                    value={n.name + ' ' + color}
                                                                                    style={{
                                                                                        backgroundColor: backgroundColor.bg_mint.find(colorF => colorF.name == color.toLowerCase())?.color,
                                                                                        width: "40px",
                                                                                        height: "40px",
                                                                                        margin: "10px",
                                                                                        border: "5px dashed #212121",
                                                                                        display: showPantColors ? 'flex' : 'none',
                                                                                    }}
                                                                                    onClick={() => handleChangePant(color, n.name)}
                                                                                ></button>

                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    !showPantColors ? (
                                                                        <button onClick={() => handleButtonClickPant(n.name)}>{n.name}</button>

                                                                    ) : null
                                                                )}
                                                            </div>
                                                        ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    // <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    //     <div className='row'>
                    //         <div className='col-12'>
                    //             <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>

                    //             <div className="input-group">
                    //                 <label className="input-group-text" htmlFor="inputPant">Pant</label>
                    //                 <select className="form-select" id="inputPant" value={character.pant} onChange={handleChangePant}>
                    //                     {
                    //                         (character.shirt.split(" ")[0] === 'DRESS') ?
                    //                             (
                    //                                 <option key={111} idclothe={111} name={"NONE"} colorname={"NONE"}>NONE</option>
                    //                             ) :
                    //                             (allPants.map((n) => (
                    //                                 n.colors.map((color) => (


                    //                                     incrementPant++,
                    //                                     <option key={incrementPant} idclothe={incrementPant} name={n.name} colorname={color} value={n.name + ' ' + color}>
                    //                                         {n.name}  {color}
                    //                                     </option>
                    //                                 ))
                    //                             ))
                    //                             )
                    //                     }
                    //                 </select>
                    //             </div>
                    //         </div>
                    //     </div>

                    // </Col>
                ) : (
                    !closePanel ? (
                        <Col lg={2} sm={12} className="p-1">
                            <button
                                style={{
                                    backgroundColor: pant > 0 ? backgroundColor.bg_mint.find(
                                        color =>
                                            color.name ===
                                            character.pant?.split(' ')[1]?.toLowerCase() // Add null check here
                                    )?.color : 'grey',

                                }}
                                onClick={() => handleShowButton('showColorsPant')}
                                data-name={pant > 0 ? character.pant : 'NONE'}
                            >
                                Pant
                            </button>
                        </Col>
                    ) : null
                )
            }

            {buttonStates.showColorsShoes ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12'>
                            <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>

                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' >
                                        <div className='col-12'>Shoes <p style={{ color: backgroundColor.bg_mint.find(color => color.name == character.shoes.toLowerCase())?.color, }}>{character.shoes}</p></div>
                                        {characterProperties.clothesColor
                                            .filter(entry => entry.color !== "None")
                                            .map((n, key) => (
                                                <button
                                                    key={key}
                                                    name={n.color} value={n.color}
                                                    className="col-1 m-1 text-center"

                                                    style={{
                                                        backgroundColor: backgroundColor.bg_mint.find(color => color.name == n.color.toLowerCase())?.color,
                                                        width: "40px",
                                                        height: "40px",
                                                        margin: "10px",
                                                        border: "5px dashed #212121"
                                                    }}

                                                    onClick={() => handleChangeShoes(n.color)}
                                                ></button>
                                            ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1">
                        <button style={{ backgroundColor: backgroundColor.bg_mint.find(color => color.name == character.shoes.toLowerCase())?.color, }} onClick={() => handleShowButton('showColorsShoes')} data-name={character.shoes}>Shoes</button>
                    </Col>
                ) : null
            )
            }


            {buttonStates.showColorsHair ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row '>
                        <div className='col-12'>
                            {!showHairColors ? (
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                            ) :
                                <button className='close-btn' onClick={() => handleShowButtonHairs(false)}>X</button>

                            }
                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' style={{ height: '30vh', overflowY: 'auto' }}  >
                                        <div className='col-12'>Hair <p style={{ color: backgroundColor.bg_mint.find(color => color.name == character.hair.split(' ')[1].toLowerCase())?.color, }}>{character.hair} </p>
                                            {/* <small><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></small> */}

                                        </div>
                                        <div className='row d-flex justify-content-center align-items-center'>
                                            {!showHairColors ? (

                                                <div className='col-12 mb-3'><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></div>
                                            ) :
                                                null
                                            }
                                            {allHairs.map((n, key) => (
                                                <div
                                                    hidden={character.pant.includes("SKIRT") && (n.name === 'LONG_STRAIGHT' || n.name === 'EXTRA_LONG') || (!character.pant.includes("SKIRT") && (n.name === 'LONG_STRAIGHT_SKIRT' || n.name === 'EXTRA_LONG_SKIRT'))}
                                                    className={showHairColors ? 'col-12' : 'col-12  col-lg-3'} key={key}>
                                                    {/* {showShirtColors ? ( */}
                                                    {selectedHair === n.name ? (


                                                        <div className='row d-flex justify-content-center align-items-center'>
                                                            {n.colors.map((color) => (
                                                                <button
                                                                    key={color}
                                                                    className="col-1 m-1 text-center"
                                                                    name={n.name}
                                                                    colorname={color}
                                                                    value={n.name + ' ' + color}

                                                                    style={{
                                                                        backgroundColor: backgroundColor.bg_mint.find(colorF => colorF.name == color.toLowerCase())?.color,
                                                                        width: "40px",
                                                                        height: "40px",
                                                                        margin: "10px",
                                                                        border: "5px dashed #212121",
                                                                        display: showHairColors ? 'flex' : 'none',

                                                                    }}
                                                                    onClick={() => handleChangeHair(color, n.name)}
                                                                ></button>

                                                            ))}
                                                        </div>
                                                    ) : (
                                                        !showHairColors ? (
                                                            incrementHair++,
                                                            <button
                                                                key={incrementHair} onClick={() => handleButtonClickHair(n.name)}>{n.name}</button>

                                                        ) : null
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1">
                        <button
                            style={{
                                backgroundColor: backgroundColor.bg_mint.find(
                                    color =>
                                        color.name ===
                                        character.hair?.split(' ')[1]?.toLowerCase() // Add null check here
                                )?.color,
                            }}
                            onClick={() => handleShowButton('showColorsHair')}
                            data-name={character.hair}
                        >
                            Hair
                        </button>
                    </Col>
                ) : null
            )
            }


            {buttonStates.showColorsAccessoryOne ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12'>
                            {!showAccessoryOneColors ? (
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                            ) :
                                <button className='close-btn' onClick={() => handleShowButtonAccessoryOne(false)}>X</button>

                            }
                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' >
                                        <div className='col-12'>Acc 1
                                            {/* character.accessory_one.split(' ')[1] ? character.accessory_one.split(' ')[1].toLowerCase() : 'no' */}
                                            <p style={{ color: character.accessory_one.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == character.accessory_one.split(' ')[1].toLowerCase())?.color : 'white' }}>Selected: {character.accessory_one} </p>
                                            {/* <small><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></small> */}
                                            <hr />
                                        </div>

                                        {characterProperties.accessory_one.map((n, key) => {

                                            const firstWord = n.name.split(' ')[0];
                                            if (firstWord && !displayedWords.has(firstWord)) {
                                                displayedWords.add(firstWord); // Add the word to the Set of displayed words
                                                return (
                                                    <React.Fragment key={`div-${key}`}>
                                                        <div className='col-12'
                                                            style={{
                                                                // backgroundColor: 'white',
                                                                // height: "40px"
                                                            }}
                                                        >{firstWord}</div>
                                                        <button
                                                            key={`button-${key}`}
                                                            className="col-1 m-1 text-center"
                                                            name={n.name}
                                                            value={n.name}
                                                            style={{
                                                                backgroundColor: n.name.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == n.name.split(' ')[1].toLowerCase())?.color : 'white',
                                                                width: "40px",
                                                                height: "40px",
                                                                margin: "10px",
                                                                border: "5px dashed #212121"
                                                            }}
                                                            onClick={() => handleChangeAccessoryOne(n.name)}
                                                        >
                                                            {n.name === "NONE" ? "X" : ""}
                                                        </button>
                                                    </React.Fragment>
                                                );
                                            } else {
                                                return (
                                                    <button
                                                        key={`button-${key}`}
                                                        className="col-1 m-1 text-center"
                                                        name={n.name}
                                                        value={n.name}
                                                        style={{
                                                            backgroundColor: n.name.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == n.name.split(' ')[1].toLowerCase())?.color : 'white',
                                                            width: "40px",
                                                            height: "40px",
                                                            margin: "10px",
                                                            border: "5px dashed #212121"
                                                        }}
                                                        onClick={() => handleChangeAccessoryOne(n.name)}
                                                    >
                                                        {n.name === "NONE" ? "X" : ""}
                                                    </button>

                                                );
                                            }
                                        }

                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1 acc_prime" >
                        <button style={{
                            backgroundColor: character.accessory_one.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == character.accessory_one.split(' ')[1].toLowerCase())?.color : 'grey'
                        }} onClick={() => handleShowButton('showColorsAccessoryOne')} data-name={character.accessory_one} data-price='+0.005' >Acc 1</button>
                    </Col>
                ) : null
            )
            }


            {/* {
                buttonStates.showColorsAccessoryOne ? (

                    <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>

                                <div className="input-group">
                                    <label className="input-group-text" htmlFor="inputAccessoryOne">Acc 1</label>
                                    <select className="form-select" id="inputAccessoryOne" value={character.accessory_one} onChange={handleChangeAccessoryOne}>
                                        {characterProperties.accessory_one
                                            .map((n, key) => (
                                                <option key={key} name={n.name} value={n.name}>
                                                    {n.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Col>
                ) : (
                    !closePanel ? (
                        <Col lg={2} sm={12} className="p-1">         
                                       <button onClick={() => handleShowButton('showColorsAccessoryOne')} data-name={character.accessory_one}>Acc 1</button>
                        </Col>
                    ) : (
                        <></>
                    )
                )
            } */}
            {buttonStates.showColorsAccessoryTwo ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12'>
                            {!showAccessoryTwoColors ? (
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                            ) :
                                <button className='close-btn' onClick={() => handleShowButtonAccessoryTwo(false)}>X</button>

                            }
                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' >
                                        <div className='col-12'>Acc 2
                                            {/* character.accessory_one.split(' ')[1] ? character.accessory_one.split(' ')[1].toLowerCase() : 'no' */}
                                            <p style={{ color: character.accessory_two.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == character.accessory_two.split(' ')[1].toLowerCase())?.color : 'white' }}>Selected: {character.accessory_two} </p>
                                            {/* <small><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></small> */}
                                            <hr />
                                        </div>

                                        {characterProperties.accessory_two.map((n, key) => {

                                            const firstWord = n.name.split(' ')[0];
                                            if (firstWord && !displayedWords.has(firstWord)) {
                                                displayedWords.add(firstWord); // Add the word to the Set of displayed words
                                                return (
                                                    <React.Fragment key={`div-${key}`}>
                                                        <div className='col-12'
                                                            style={{
                                                                // backgroundColor: 'white',
                                                                // height: "40px"
                                                            }}
                                                        >{firstWord}</div>
                                                        <button
                                                            key={`button-${key}`}
                                                            className="col-1 m-1 text-center"
                                                            name={n.name}
                                                            value={n.name}
                                                            style={{
                                                                backgroundColor: n.name.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == n.name.split(' ')[1].toLowerCase())?.color : 'white',
                                                                width: "40px",
                                                                height: "40px",
                                                                margin: "10px",
                                                                border: "5px dashed #212121"
                                                            }}
                                                            onClick={() => handleChangeAccessoryTwo(n.name)}
                                                        >
                                                            {n.name === "NONE" ? "X" : ""}
                                                        </button>
                                                    </React.Fragment>
                                                );
                                            } else {
                                                return (
                                                    <button
                                                        key={`button-${key}`}
                                                        className="col-1 m-1 text-center"
                                                        name={n.name}
                                                        value={n.name}
                                                        style={{
                                                            backgroundColor: n.name.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == n.name.split(' ')[1].toLowerCase())?.color : 'white',
                                                            width: "40px",
                                                            height: "40px",
                                                            margin: "10px",
                                                            border: "5px dashed #212121"
                                                        }}
                                                        onClick={() => handleChangeAccessoryTwo(n.name)}
                                                    >
                                                        {n.name === "NONE" ? "X" : ""}
                                                    </button>

                                                );
                                            }
                                        }

                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1 acc_prime" >
                        <button style={{
                            backgroundColor: character.accessory_two.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == character.accessory_two.split(' ')[1].toLowerCase())?.color : 'grey'
                        }} onClick={() => handleShowButton('showColorsAccessoryTwo')} data-name={character.accessory_two} data-price='+0.005' >Acc 2</button>
                    </Col>
                ) : null
            )
            }
            {/* {
                buttonStates.showColorsAccessoryTwo ? (

                    <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>

                                <div className="input-group">
                                    <label className="input-group-text" htmlFor="inputAccessoryTwo">Acc 2</label>
                                    <select className="form-select" id="inputAccessoryTwo" value={character.accessory_two} onChange={handleChangeAccessoryTwo}>
                                        {characterProperties.accessory_two
                                            .map((n, key) => (
                                                <option key={key} name={n.name} value={n.name}>
                                                    {n.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Col>
                ) : (
                    !closePanel ? (
                        <Col lg={2} sm={12} className="p-1">
                            <button onClick={() => handleShowButton('showColorsAccessoryTwo')} data-name={character.accessory_two}>Acc 2</button>
                        </Col>
                    ) : (
                        <></>
                    )
                )
            } */}

            {buttonStates.showColorsAccessoryThree ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12'>
                            {!showAccessoryThreeColors ? (
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                            ) :
                                <button className='close-btn' onClick={() => handleShowButtonAccessoryThree(false)}>X</button>

                            }
                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' >
                                        <div className='col-12'>Acc 3
                                            {/* character.accessory_one.split(' ')[1] ? character.accessory_one.split(' ')[1].toLowerCase() : 'no' */}
                                            <p style={{ color: character.accessory_three.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == character.accessory_three.split(' ')[1].toLowerCase())?.color : 'white' }}>Selected: {character.accessory_three} </p>
                                            {/* <small><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></small> */}
                                            <hr />
                                        </div>

                                        {characterProperties.accessory_three.map((n, key) => {

                                            const firstWord = n.name.split(' ')[0];
                                            if (firstWord && !displayedWords.has(firstWord)) {
                                                displayedWords.add(firstWord); // Add the word to the Set of displayed words
                                                return (
                                                    <React.Fragment key={`div-${key}`}>
                                                        <div className='col-12'
                                                            style={{
                                                                // backgroundColor: 'white',
                                                                // height: "40px"
                                                            }}
                                                        >{firstWord}</div>
                                                        <button
                                                            key={`button-${key}`}
                                                            className="col-1 m-1 text-center"
                                                            name={n.name}
                                                            value={n.name}
                                                            style={{
                                                                backgroundColor: n.name.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == n.name.split(' ')[1].toLowerCase())?.color : 'white',
                                                                width: "40px",
                                                                height: "40px",
                                                                margin: "10px",
                                                                border: "5px dashed #212121"
                                                            }}
                                                            onClick={() => handleChangeAccessoryThree(n.name)}
                                                        >
                                                            {n.name === "NONE" ? "X" : ""}
                                                        </button>
                                                    </React.Fragment>
                                                );
                                            } else {
                                                return (
                                                    <button
                                                        key={`button-${key}`}
                                                        className="col-1 m-1 text-center"
                                                        name={n.name}
                                                        value={n.name}
                                                        style={{
                                                            backgroundColor: n.name.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == n.name.split(' ')[1].toLowerCase())?.color : 'white',
                                                            width: "40px",
                                                            height: "40px",
                                                            margin: "10px",
                                                            border: "5px dashed #212121"
                                                        }}
                                                        onClick={() => handleChangeAccessoryThree(n.name)}
                                                    >
                                                        {n.name === "NONE" ? "X" : ""}
                                                    </button>

                                                );
                                            }
                                        }

                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1 acc_prime" >
                        <button style={{
                            backgroundColor: character.accessory_three.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == character.accessory_three.split(' ')[1].toLowerCase())?.color : 'grey'
                        }} onClick={() => handleShowButton('showColorsAccessoryThree')} data-name={character.accessory_three} data-price='+0.005' >Acc 3</button>
                    </Col>
                ) : null
            )
            }

            {/* {
                buttonStates.showColorsAccessoryThree ? (

                    <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>

                                <div className="input-group">
                                    <label className="input-group-text" htmlFor="inputAccessoryThree">Acc 3</label>
                                    <select className="form-select" id="inputAccessoryThree" value={character.accessory_three} onChange={handleChangeAccessoryThree}>
                                        {characterProperties.accessory_three
                                            .map((n, key) => (
                                                <option key={key} name={n.name} value={n.name}>
                                                    {n.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Col>
                ) : (
                    !closePanel ? (
                        <Col lg={2} sm={12} className="p-1">
                            <button onClick={() => handleShowButton('showColorsAccessoryThree')} data-name={character.accessory_three}>Acc 3</button>
                        </Col>
                    ) : (
                        <></>
                    )
                )
            } */}


            {buttonStates.showColorsDisguise ? (
                <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-12'>
                            {!showAccessoryDisguiseColors ? (
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                            ) :
                                <button className='close-btn' onClick={() => handleShowButtonAccessoryDisguise(false)}>X</button>

                            }
                            <div className="overlay-mint-options">
                                <div className="imagemaker_colorpalette" >
                                    <div className='row  d-flex justify-content-center align-items-center' >
                                        <div className='col-12'>Disguise
                                            {/* character.accessory_one.split(' ')[1] ? character.accessory_one.split(' ')[1].toLowerCase() : 'no' */}
                                            <p style={{ color: character.disguise.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == character.disguise.split(' ')[1].toLowerCase())?.color : 'white' }}>Selected: {character.disguise} </p>
                                            {/* <small><i className="fa-solid fa-arrow-down"></i> Scroll to bottom <i className="fa-solid fa-arrow-down"></i></small> */}
                                            <hr />
                                        </div>

                                        {characterProperties.disguise.map((n, key) => {

                                            const firstWord = n.name.split(' ')[0];
                                            if (firstWord && !displayedWords.has(firstWord)) {
                                                displayedWords.add(firstWord); // Add the word to the Set of displayed words
                                                return (
                                                    <React.Fragment key={`div-${key}`}>
                                                        <div className='col-12'
                                                            style={{
                                                                // backgroundColor: 'white',
                                                                // height: "40px"
                                                            }}
                                                        >{firstWord}</div>
                                                        <button
                                                            key={`button-${key}`}
                                                            className="col-1 m-1 text-center"
                                                            name={n.name}
                                                            value={n.name}
                                                            style={{
                                                                backgroundColor: n.name.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == n.name.split(' ')[1].toLowerCase())?.color : 'white',
                                                                width: "40px",
                                                                height: "40px",
                                                                margin: "10px",
                                                                border: "5px dashed #212121"
                                                            }}
                                                            onClick={() => handleChangeDisguise(n.name)}
                                                        >
                                                            {n.name === "NONE" ? "X" : ""}
                                                        </button>
                                                    </React.Fragment>
                                                );
                                            } else {
                                                return (
                                                    <button
                                                        key={`button-${key}`}
                                                        className="col-1 m-1 text-center"
                                                        name={n.name}
                                                        value={n.name}
                                                        style={{
                                                            backgroundColor: n.name.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == n.name.split(' ')[1].toLowerCase())?.color : 'white',
                                                            width: "40px",
                                                            height: "40px",
                                                            margin: "10px",
                                                            border: "5px dashed #212121"
                                                        }}
                                                        onClick={() => handleChangeDisguise(n.name)}
                                                    >
                                                        {n.name === "NONE" ? "X" : ""}
                                                    </button>

                                                );
                                            }
                                        }

                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
            ) : (
                !closePanel ? (
                    <Col lg={2} sm={12} className="p-1 acc_prime" >
                        <button style={{
                            backgroundColor: character.disguise.split(' ')[1] ? backgroundColor.bg_mint.find(color => color.name == character.disguise.split(' ')[1].toLowerCase())?.color : 'grey'
                        }} onClick={() => handleShowButton('showColorsDisguise')} data-name={character.disguise} data-price='+0.005' >Disguise</button>
                    </Col>
                ) : null
            )
            }


            {/* {
                buttonStates.showColorsDisguise ? (

                    <Col sm={12} className='position-relative h-100   d-flex justify-content-center align-items-center'>
                        <div className='row'>
                            <div className='col-12'>
                                <button className='close-btn' onClick={() => handleShowButton(null, true)}>X</button>
                                <div className="input-group">
                                    <label className="input-group-text" htmlFor="inputDisguise">Disguise</label>
                                    <select className="form-select" id="inputDisguise" value={character.disguise} onChange={handleChangeDisguise}>
                                        {characterProperties.disguise
                                            .map((n, key) => (
                                                <option key={key} name={n.name} value={n.name}>
                                                    {n.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Col>
                ) : (
                    !closePanel ? (
                        <Col lg={2} sm={12} className="p-1">
                            <button onClick={() => handleShowButton('showColorsDisguise')} data-name={character.disguise}>Disguise</button>
                        </Col>
                    ) : (
                        <></>
                    )
                )
            } */}

        </>


    // const { animatedChar, charContent, bgColor, animationData } = Character();

    const invertColor = (hex) => {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16).padStart(2, '0');
        let g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16).padStart(2, '0');
        let b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16).padStart(2, '0');
        return '#' + r + g + b;
    }
    return (
        <>

            <div id='content' className='content' style={{ backgroundColor: bgColor }}>
                {/* <Header animatedChar={animatedChar} onStateChange={handleStateChange} animationData={animationData} onStateChangeFooter={handleStateChangeFooter} onStateChangeBtnPress={handleStateChangeBtn} char={character} base64Img={croppedImage} url={url} urlExternal={urlExternal} characterContent={charContent} />
            <Footer /> */}
                <Header />
                <div className='container'>
                    <Main animatedChar={charWhenMain} onStateChange={handleStateChange} animationData={animationData} onStateChangeFooter={handleStateChangeFooter} onStateChangeBtnPress={handleStateChangeBtn} char={character} base64Img={croppedImage} bgColor={invertColor(bgColor)} />
                </div>
                <Header />

                {/* <Footer /> */}
            </div>

            <Menu isOpen={menuIsOpen} bgColor={invertColor(bgColor)} textColor={bgColor} />
            <Roadmap isOpen={roadmapIsOpen} bgColor={invertColor(bgColor)} textColor={bgColor} demoCharImg={results} />
            <Mint closePanel={closePanel} totalPriceElements={priceAccTotal} isOpen={mintIsOpen} animatedChar={charWhenMint} char={character} animationData={animationData} onStateChangeFooter={handleStateChangeFooter} onStateChangeBtnPress={handleStateChangeBtn} characterContent={charContent} url={url} urlExternal={urlExternal} base64Img={croppedImage} />

        </>
    );
};

export default Home;