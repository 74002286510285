import React, { Component } from "react";
import Sprite from "./Sprite";
import OverworldEvent from "../Overworld/OverworldEvent";



class GameObject extends Component {
    constructor(config) {

        super(config);
        this.id = null
        // Retrieving the base64 encoded string from session storage
        this.isMountedC = false;
        this.x = config.x || 0;
        this.y = config.y || 0;
        this.direction = config.direction || "down";
        this.sprite = new Sprite({
            gameObject: this,
            //hero
            src: config.src || "./assets/images/characters/people/hero.png",
        });
        this.behaviorLoop = config.behaviorLoop || [];
        this.behaviorLoopIndex = 0;
        this.talking = config.talking || [];
        this.retryTime = null;
    }

    mount(map) {
        this.isMountedC = true;

        //if e have behavior, kick off after a short delay

        setTimeout(() => {
            this.doBehaviorEvent(map)
        }, 10)
    }

    update() {
    }

    async doBehaviorEvent(map) {

        //Don't do anything if I don't have config to do anything
        if (this.behaviorLoop.length === 0) {
            return;
        }

        // //do anything if there is a more important cutscene or i not have config to do anything anyway
        // if (map.isCutscenePlaying || this.behaviorLoop.length === 0 || this.isStanding) {
        //     return;
        // }

        if (map.isCutscenePlaying) {

            // console.log("will retry", this.id)
            if (this.retryTimeout) {
                clearTimeout(this.retryTimeout);
            }
            this.retryTimeout = setTimeout(() => {
                this.doBehaviorEvent(map);
            }, 1000)
            return;
        }

        //setting up our event with relevant info
        let eventConfig = this.behaviorLoop[this.behaviorLoopIndex]
        eventConfig.who = this.id;

        //create an event instance out of our next event config
        const eventHandler = new OverworldEvent({ map, event: eventConfig })
        await eventHandler.init()
        //setting the next event to fire
        this.behaviorLoopIndex += 1;
        if (this.behaviorLoopIndex === this.behaviorLoop.length) {
            this.behaviorLoopIndex = 0
        }

        //do it again
        this.doBehaviorEvent(map)
    }


    render() {
        return (
            <div>
                <Sprite
                    config={this.sprite.config}
                    ctx={this.config.ctx}
                />
            </div>
        );
    }


}



export default GameObject;
