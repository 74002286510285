import React, { Component } from "react";

class DirectionInput extends Component {
  constructor(props) {
    super(props);
    this.heldDirections = [];

    this.map = {
      "ArrowUp": "up",
      "KeyW": "up",
      "ArrowDown": "down",
      "KeyS": "down",
      "ArrowLeft": "left",
      "KeyA": "left",
      "ArrowRight": "right",
      "KeyD": "right",
    }

  }


  get direction() {
    return this.heldDirections[0];
  }

  handleMouseClick = (x, y, cameraPerson) => {
    console.log(cameraPerson);
    // const centerX = (1056 / 2);
    // const centerY = (594 / 2);
    // const dx = x - centerX;
    // const dy = y - centerY;
    // let direction = "";
    // if (dx > 0 && Math.abs(dx) > Math.abs(dy)) {
    //   direction = "right";
    // } else if (dx < 0 && Math.abs(dx) > Math.abs(dy)) {
    //   direction = "left";
    // } else if (dy > 0 && Math.abs(dy) > Math.abs(dx)) {
    //   direction = "down";
    // } else if (dy < 0 && Math.abs(dy) > Math.abs(dx)) {
    //   direction = "up";
    // }
    // if (direction !== "") this.heldDirections.unshift(direction);
  }


  init() {
    // const grid = document.getElementById("game-canvas");
    // grid.addEventListener("click", (event) => {
    //   const cameraPerson = this.map.gameObjects.hero;

    //   const x = event.clientX
    //   const y = event.clientY
    //   this.handleMouseClick(x, y, cameraPerson);
    // });

    document.addEventListener("keydown", e => {

      const dir = this.map[e.code];

      if (dir && this.heldDirections.indexOf(dir) === -1) {

        this.heldDirections.unshift(dir);
      }
    });
    document.addEventListener("keyup", e => {
      const dir = this.map[e.code];
      const index = this.heldDirections.indexOf(dir);
      if (index > -1) {
        this.heldDirections.splice(index, 1);
      }
    })

  }


  render() {
    return (
      <></>
    );
  }
}

export default DirectionInput;
