
const utils = {
    withGrid(n) {
        return n * 16
    },
    asGridCoord(x, y) {
        return `${x * 16},${y * 16}`
    },
    nextPosition(initialX, initialY, direction) {
        let x = initialX;
        let y = initialY;
        const size = 16;
        if (direction === "left") {
            x -= size;
        } else if (direction === "right") {
            x += size;
        } else if (direction === "up") {
            y -= size;
        } else if (direction === "down") {
            y += size;
        }
        return { x, y };
    },
    oppositeDirection(direction) {
        if (direction === "left") { return "right" }
        if (direction === "right") { return "left" }
        if (direction === "up") { return "down" }
        return "up"
    },
    wait(ms) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve()
            }, ms)
        })
    },
    randomFromArray(array) {
        return array[Math.floor(Math.random() * array.length)]
    },

    SceneLoopJson(data) {
        return data;
    },
    emitEvent(name, detail) {
        const event = new CustomEvent(name, {
            detail
        });
        document.dispatchEvent(event);
    },

    cropAvatar(avatar) {
        const canvas = document.createElement('canvas');
        canvas.width = 32;
        canvas.height = 32;
        const ctx = canvas.getContext('2d');
        const img = document.createElement('img');
        img.src = avatar;
        img.onload = () => {
            ctx.drawImage(img, 0, 0, 32, 32, 0, 0, 32, 32);
            const base64 = canvas.toDataURL();
            return base64;
        };
    }
}
export default utils;


