import React, { useState, useRef, useEffect, useContext } from 'react';
import Navbar, { resIsOpen } from '../Navbar/Navbar';
import backgroundColor from '../Character/backgroundColor.json';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { gsap } from 'gsap';

import characterProperties from '../Character/properties.json';
import characterFirstname from '../GenerateName/firstname.json';
import characterLastname from '../GenerateName/lastname.json';

import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";


import './style.scss';

const Main = ({ animatedChar, onStateChange, animationData, onStateChangeFooter, onStateChangeBtnPress, char, base64Img, url, urlExternal, characterContent, headerLink, bgColor }) => {


    // const handlechangePosition = (key, e) => {
    //     setStatesFooter(key);
    // };

    // const [isOpen, setIsOpen] = useState(false);
    // const [isRoadmapOpen, setIsRoadmapOpen] = useState(false);
    // const [mintIsOpen, setMintIsOpen] = useState(false);
    // const menuRef = useRef(null);

    // const [states, setStates] = useState(0);
    // const [statesFooter, setStatesFooter] = useState(0);
    // const [statesBtnArrows, setStatesBtnArrows] = useState(0);

    // const [animating, setAnimating] = useState(false);
    // const [mintIsOpenTriggerDiv, setMintIsOpenTriggerDiv] = useState(false);
    // const wordRef = useRef(null);
    // const wordRefH2 = useRef(null);


    let [finalChar, setFinalChar] = useState({})
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(``);
    const [mintAmount, setMintAmount] = useState(1);
    const [totalMint, setTotalMint] = useState(1);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });

    const [charFirstName, setCharFirstName] = useState(null);
    const [charLastName, setCharLastName] = useState(null);


    const claimNFTs = () => {
        let cost = CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);

        if (finalChar) {

            blockchain.smartContract.methods
                .mint(mintAmount)
                .send({
                    gasLimit: String(totalGasLimit),
                    to: CONFIG.CONTRACT_ADDRESS,
                    from: blockchain.account,
                    value: totalCostWei,
                })
                .once("error", (err) => {
                    console.log(err);
                    setFeedback("Sorry, something went wrong please try again later.");
                    setClaimingNft(false);
                })
                .then((receipt) => {
                    async function getTotalMinted() {

                        // Call the total minted function
                        const totalMinted = await blockchain.smartContract.methods.totalSupply().call();
                        // Update the state
                        const newFinalChar = {
                            ...finalChar,
                            "animation_url": urlExternal + "/peopixs/" + totalMinted,
                            "external_url": urlExternal + "/peopixs/" + totalMinted,
                        };

                        const data = {
                            finalChar: newFinalChar,
                            base64Img: { "image": base64Img },
                            totalMints: totalMinted
                        };

                        axios.post(urlJson + '/save-json', data)
                            .then(response => {
                                console.log('WONDERFULL !!! your NFT has been well minted ');

                            })
                            .catch(error => {
                                console.log('Error saving NFT', error);
                            });
                    }
                    getTotalMinted();

                    setFeedback(
                        `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
                    );
                    setClaimingNft(false);
                    dispatch(fetchData(blockchain.account));
                });
        } else {
            console.log('Error saving NFT');
            setFeedback(
                `OUPS ! an error appeared, please refresh the page and mint again`
            );
        }
    };

    // const decrementMintAmount = () => {
    //     let newMintAmount = mintAmount - 1;
    //     if (newMintAmount < 1) {
    //         newMintAmount = 1;
    //     }
    //     setMintAmount(newMintAmount);
    // };

    // const incrementMintAmount = () => {
    //     let newMintAmount = mintAmount + 1;
    //     if (newMintAmount > 1) {
    //         newMintAmount = 1;
    //     }
    //     setMintAmount(newMintAmount);
    // };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);



    const canvasRef = useRef(null);
    const [croppedBlob, setCroppedBlob] = useState(null);
    const [name, setName] = useState('');

    let urlJson = url;

    const {
        accessory_one,
        accessory_two,
        accessory_three,
        background,
        blush,
        disguise,
        eyes,
        hair,
        lipstick,
        pant,
        shirt,
        shoes,
        skin,
    } = char;

    const search = (name) => {
        // name.hair = 'WAV Black';
        const indexAccessoryOne = characterProperties.accessory_one.findIndex(item => item.name === name.accessory_one);
        const indexAccessoryTwo = characterProperties.accessory_two.findIndex(item => item.name === name.accessory_two);
        const indexAccessoryThree = characterProperties.accessory_three.findIndex(item => item.name === name.accessory_three);
        const indexBackground = backgroundColor.bg.findIndex(item => item.name === name.background);
        const indexBlush = characterProperties.blush.findIndex(item => item.color === name.blush);
        const indexDisguise = characterProperties.disguise.findIndex(item => item.name === name.disguise);
        const indexEyes = characterProperties.eyes.findIndex(item => item.color === name.eyes);

        const hairSplit = name.hair;
        const nameHair = hairSplit.substring(0, hairSplit.lastIndexOf(" "));
        const colorHair = hairSplit.split(" ").pop();

        const indexNameHair = characterProperties.hairs.findIndex(item => item.name === nameHair);
        const indexColorHair = characterProperties.hairsColor.findIndex(item => item.color === colorHair);
        const indexLipstick = characterProperties.lipstick.findIndex(item => item.color === name.lipstick);

        const shirtSplit = name.shirt;
        const nameShirt = shirtSplit.substring(0, shirtSplit.lastIndexOf(" "));
        const colorShirt = shirtSplit.split(" ").pop();

        const indexNameShirt = characterProperties.shirts.findIndex(item => item.name === nameShirt);
        const indexColorShirt = characterProperties.clothesColor.findIndex(item => item.color === colorShirt);

        const pantSplit = name.pant;
        const namePant = pantSplit.substring(0, pantSplit.lastIndexOf(" "));
        const colorPant = pantSplit.split(" ").pop();

        const indexNamePant = characterProperties.pants.findIndex(item => item.name === namePant);
        const indexColorPant = characterProperties.clothesColor.findIndex(item => item.color === colorPant);

        const indexShoes = characterProperties.clothesColor.findIndex(item => item.color === name.shoes);
        const indexSkin = characterProperties.char.findIndex(item => item.name === name.skin);

        return {
            indexAccessoryOne,
            indexAccessoryTwo,
            indexAccessoryThree,
            indexBackground,
            indexBlush,
            indexDisguise,
            indexEyes,
            indexNameHair,
            indexColorHair,
            indexLipstick,
            indexNameShirt,
            indexColorShirt,
            indexNamePant,
            indexColorPant,
            indexShoes,
            indexSkin
        };
    }

    const IsAllTrue = search(char);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const img = new Image();
        ctx.imageSmoothingEnabled = false; // Enable image smoothing
        if (base64Img) {
            img.src = base64Img;

        }
        img.onload = () => {
            ctx.drawImage(img, 0, 0, 32, 32, 0, 0, 640, 640);
            const base64 = canvas.toDataURL();
            setCroppedBlob(base64);
        }
        //if all char object params are in the json file
    }, [base64Img]);

    // useEffect(() => {
    //     axios.get('https://randomuser.me/api?nat=us,fr,es&inc=name').then(response => {
    //         const names = response.data.results.map(person => person.name);
    //         const randomIndex = Math.floor(Math.random() * names.length);
    //         if (!name) {
    //             setName(names[randomIndex]);
    //         }
    //     });
    // }, [name]);

    useEffect(() => {
        if (
            IsAllTrue.indexAccessoryOne > -1 &&
            IsAllTrue.indexAccessoryTwo > -1 &&
            IsAllTrue.indexAccessoryThree > -1 &&
            IsAllTrue.indexBackground > -1 &&
            IsAllTrue.indexBlush > -1 &&
            IsAllTrue.indexDisguise > -1 &&
            IsAllTrue.indexEyes > -1 &&
            IsAllTrue.indexNameHair > -1 &&
            IsAllTrue.indexColorHair > -1 &&
            IsAllTrue.indexLipstick > -1 &&
            IsAllTrue.indexNameShirt > -1 &&
            IsAllTrue.indexColorShirt > -1 &&
            IsAllTrue.indexColorPant > -1 &&
            IsAllTrue.indexColorPant > -1 &&
            IsAllTrue.indexShoes > -1 &&
            IsAllTrue.indexSkin > -1

        ) {
            //if shirt is a dress need to remove pant
            if (IsAllTrue.indexNameShirt === 10) {
                //char.pant = "NONE"
            }
            const indexBackgroundColor = backgroundColor.bg.findIndex(item => item.name === char.background);
            document.body.style.backgroundColor = backgroundColor.bg[indexBackgroundColor].color;

            const hexToRgba = (hex, alpha = 1) => {
                const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
                return `rgba(${r}, ${g}, ${b}, ${alpha})`;
            }

            const invertColor = (hex) => {
                if (hex.indexOf('#') === 0) {
                    hex = hex.slice(1);
                }
                // convert 3-digit hex to 6-digits.
                if (hex.length === 3) {
                    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                }
                if (hex.length !== 6) {
                    throw new Error('Invalid HEX color.');
                }
                let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16).padStart(2, '0');
                let g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16).padStart(2, '0');
                let b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16).padStart(2, '0');
                return '#' + r + g + b;
            }

            let styleElem = document.head.appendChild(document.createElement("style"));
            styleElem.innerHTML = ".overlay:before {--gradient-color:" + hexToRgba(backgroundColor.bg[indexBackgroundColor].color, 1) + ", " + hexToRgba(backgroundColor.bg[indexBackgroundColor].color, 0) + "; --gradient-width:200px;}";
            styleElem.innerHTML += ".overlay:after {--gradient-color:" + hexToRgba(backgroundColor.bg[indexBackgroundColor].color, 1) + ", " + hexToRgba(backgroundColor.bg[indexBackgroundColor].color, 0) + "; --gradient-width:200px;}";
            // document.getElementById("menu-btn").style.backgroundColor = invertColor(backgroundColor.bg[indexBackgroundColor].color, 0);
            // document.getElementById("menu-text").style.color = backgroundColor.bg[indexBackgroundColor].color;

            // document.getElementById("roadmap-btn").style.backgroundColor = invertColor(backgroundColor.bg[indexBackgroundColor].color, 0);
            // document.getElementById("roadmap-text").style.color = backgroundColor.bg[indexBackgroundColor].color;


            // document.querySelectorAll("#menu-links li a, footer button").forEach(function (element) {
            //     element.style.color = backgroundColor.bg[indexBackgroundColor].color;
            // });
            // document.querySelectorAll("footer button").forEach(function (element) {
            //     element.style.color = backgroundColor.bg[indexBackgroundColor].color;
            // });
            // document.querySelectorAll("footer button").forEach(function (element) {
            //     element.style.backgroundColor = invertColor(backgroundColor.bg[indexBackgroundColor].color);
            // });
            // document.querySelectorAll("button:hover").backgroundColor = backgroundColor.bg[indexBackgroundColor].color;
            const randomIndex = Math.floor(Math.random() * characterFirstname.length);
            const randomName = characterFirstname[randomIndex];
            setCharFirstName(randomName)

            const randomIndexLastName = Math.floor(Math.random() * characterLastname.length);
            const randomLastName = characterLastname[randomIndexLastName];
            setCharLastName(randomLastName)


            setFinalChar(
                {
                    "name": charFirstName + ' ' + randomLastName,
                    "description": "a definir",
                    "image": croppedBlob,
                    "timestamp": Date.now(),
                    "background": backgroundColor.bg[indexBackgroundColor].color.slice(1),
                    "attributes": [
                        // accessory_one,
                        // accessory_two,
                        // accessory_three,
                        // disguise,


                        {
                            "trait_type": "background",
                            "value": char.background,
                        },
                        {
                            "trait_type": "skin",
                            "value": char.skin,
                        },
                        {
                            "trait_type": "shirt",
                            "value": char.shirt,
                        }
                        ,
                        {
                            "trait_type": "pant",
                            "value": char.pant,
                        },
                        {
                            "trait_type": "hair",
                            "value": char.hair,
                        }
                        ,
                        {
                            "trait_type": "eyes",
                            "value": char.eyes,
                        }
                        ,
                        {
                            "trait_type": "blush",
                            "value": char.blush,
                        }
                        ,
                        {
                            "trait_type": "lipstick",
                            "value": char.lipstick,
                        }
                        ,
                        {
                            "trait_type": "shoes",
                            "value": char.shoes,
                        }
                        ,
                        {
                            "trait_type": "accessory one",
                            "value": char.accessory_one,
                        }
                        ,
                        {
                            "trait_type": "accessory two",
                            "value": char.accessory_two,
                        }
                        ,
                        {
                            "trait_type": "accessory three",
                            "value": char.accessory_three,
                        }
                        ,
                        {
                            "trait_type": "disguise",
                            "value": char.disguise,
                        }

                    ],
                    "compiler": "Peopixs"


                })


        }

    }, [char, croppedBlob]);

    const handleSave = () => {


        const data = {
            finalChar: finalChar,
            base64Img: { "image": base64Img }
        };
        axios.post(urlJson + '/save-json', data)
            .then(response => {
                console.log('JSON file saved successfully');

            })
            .catch(error => {
                console.log('Error saving JSON file:', error);
            });


    }

    const [isOpen, setIsOpen] = useState(false);
    const [isRoadmapOpen, setIsRoadmapOpen] = useState(false);
    const [mintIsOpen, setMintIsOpen] = useState(false);
    const menuRef = useRef(null);

    const [states, setStates] = useState(0);
    const [statesFooter, setStatesFooter] = useState(0);
    const [statesBtnArrows, setStatesBtnArrows] = useState(0);

    const [animating, setAnimating] = useState(false);
    const [mintIsOpenTriggerDiv, setMintIsOpenTriggerDiv] = useState(false);
    const wordRef = useRef(null);
    const wordRefH2 = useRef(null);

    useEffect(() => {
        onStateChange(states);
    }, [states]);




    //MINT BTN
    // useEffect(() => {
    //     gsap.to('.div8', {
    //         duration: 0,
    //         ease: "power3.out",
    //         opacity: 0,
    //     });
    // }, []);

    const handleRoadmapClick = () => {
        setIsRoadmapOpen(!isRoadmapOpen);
    };



    const handleMint = () => {
        // setDiv7Content(prevState => prevState + document.getElementById("div7").innerHTML);
        //document.getElementById("mint-panel").innerHTML = div7Content + document.getElementById("mint-panel").innerHTML;

        if (mintIsOpen) {

            gsap.to('#mint-panel', {
                duration: 1,
                y: 0,
                scale: 0,
            });
            // gsap.to('.div5', {
            //     duration: 1,
            //     x: 0,
            // });

            gsap.to('.div1, .div3', {
                duration: 1,
                x: 0,
            });
            gsap.to('.div2', {
                duration: 1,
                y: 0,
            });

            gsap.to(' .div7', {
                duration: 1,
                opacity: 1,
                y: "0vh",
                onComplete: () => setMintIsOpen(false),
            });
            setMintIsOpenTriggerDiv(false)

        } else {
            gsap.to("header", {
                duration: 1,
                css: {
                    "grid-template-columns": "2fr 1fr",
                    "grid-template-rows": "2fr 1fr",
                    "grid-column-gap": "0px",
                    "grid-row-gap": "0px",
                    "display": "grid"
                }
            });

            // gsap.to(".div4", {
            //     duration: 1,
            //     css: {
            //         "grid-row-start": 3,
            //         "grid-row-end": 1,
            //         "grid-column-start": 3,
            //         "grid-column-end": 5,
            //         "z-index": 100
            //     }
            // })
            // gsap.to(".div6", {
            //     duration: 1,
            //     css: {
            //         "grid-row-start": 3,
            //         "grid-row-end": 3,
            //         "grid-column-start": 3,
            //         "grid-column-end": 5,
            //         "z-index": 100
            //     }
            // })
            gsap.to(".div5", {
                duration: 1,
                css: {
                    "grid-row-start": 1,
                    "grid-row-end": 1,
                    "grid-column-start": 2,
                    "grid-column-end": 3,
                    "z-index": 100
                }
            })
            gsap.to(".div8", {
                duration: 1,
                css: {
                    "grid-row-start": 1,
                    "grid-row-end": 2,
                    "grid-column-start": 2,
                    "grid-column-end": 3,
                    "z-index": 50
                }
            })
            gsap.fromTo('#mint-panel', {
                scale: 0,
            }, {
                duration: 0.5,
                scale: 1,
            });
            // gsap.fromTo('.div5', {
            //     zIndex: 0,
            //     x: 0,
            // }, {
            //     duration: 0.5,
            //     zIndex: 100,
            //     x: "-=100%",
            // });
            // gsap.fromTo('.div1, .div3', {
            //     x: 0,
            // }, {
            //     duration: 0.5,
            //     x: "-=100vw",
            // });
            // gsap.fromTo('.div2', {
            //     y: 0,
            // }, {
            //     duration: 0.5,
            //     y: "-=100vh",
            // });
            // gsap.fromTo('.div3', {
            //     x: 0,
            // }, {
            //     duration: 0.5,
            //     x: "+=100vw",
            // });
            gsap.fromTo(' .div7', {
                duration: 0.5,
                opacity: 1,
                y: "0",
            }, {
                opacity: 0,
                y: "+=100vh",
                onComplete: () => setMintIsOpen(true),
            });
            setMintIsOpenTriggerDiv(true)

        }
    };
    const letters = "PEOPIXS".split("");
    const sloganLetters = "PEOPIXS-ARE-8,888-UNIQUE-COLLECTIBLE-FULLY-CREATED-BY-YOU-AND-ANIMATED-FOR-FUTUR-2D-GAME.".split("");


    useEffect(() => {
        onStateChangeFooter(statesFooter);
    }, [statesFooter]);


    const handlechangePosition = (key, e) => {
        setStatesFooter(key)
    };

    useEffect(() => {
        onStateChangeBtnPress(statesBtnArrows);
    }, [statesBtnArrows]);

    const handleBtnPress = (key, e) => {
        setStatesBtnArrows(key)
    };



    return (

        <div className='row g-0'>

            <section className='col-12'>

                <Navbar bgColor={bgColor} />
            </section>

            <main className='col-12 position-relative' id="content-home">
                <section className='rs d-flex align-items-center '>
                    <ul>
                        <li>
                            <a href="./" >
                                <img src="/assets/img/refresh-black.png" alt="refresh" width={64} />
                            </a>
                        </li>
                        <li className='pt-1'>
                            <a href="https://twitter.com/peopixs" target='_blank' >
                                <img src="/assets/img/twitter-black.png" alt="twitter" width={64} />
                            </a>
                        </li>
                        {/* <li className='pt-1'>
                            <a href="#" >
                                <img src="/assets/img/opensea-black.png" alt="opensea" width={64} />
                            </a>
                        </li> */}
                    </ul>
                </section>
                <canvas ref={canvasRef} width={640} height={640} hidden />
                <section className=' row g-0   text-center  d-flex align-items-end'>

                    <div className='col-12   text-center'>
                        <p className='pt-2 m-0 nb-mint'>{data.totalSupply != 0 ? data.totalSupply : '???'} / {CONFIG.MAX_SUPPLY}</p>
                        {animatedChar}
                    </div>

                    <div className='col-12 text-center title'>
                        <h1 >
                            {letters.map((letter, index) => (
                                <span key={index} style={{ display: "inline-block" }}>
                                    {letter}
                                </span>
                            ))}
                        </h1>
                        <h2>
                            {sloganLetters.map((letter, index) => (
                                <span key={index} style={{ display: "inline-block" }}>
                                    {letter}
                                </span>
                            ))}
                        </h2>
                    </div>
                </section>
                {/* <header className='text-center container-fluid container-lg'> */}
                {/* <div className='div1'>
                        <Button className='menu' id="menu-btn" onClick={handleClick}>
                            {isOpen ? 'CLOSE' : 'MENU'}
                        </Button>
                    </div>
                    <div className='div2'>
                        <Button id="roadmap-btn" className=' roadmap' onClick={handleRoadmapClick}>
                            {isRoadmapOpen ? 'CLOSE' : 'ROADMAP'}
                        </Button>
                    </div>
                    <div className='div3' onClick={handleMint}>
                        <Button id="mint-btn" className=' mint '>
                            {mintIsOpenTriggerDiv ? 'X' : 'MINT'}
                        </Button>
                    </div> */}
                {/* <div className='row div4'>
                        {animationData.animations
                            .filter((n, key) => key < 7)
                            .map((n, key) => (
    
                                <div className='col-4' key={key}>
                                    <button
                                        className=" btn-animation mb-3 "
                                        style={{ backgroundColor: backgroundColor.bg[key].color }}
                                        onClick={(e) => handlechangePosition(key, e)}
                                    >
                                        {n.name}
                                    </button>
                                </div>
                            ))}
                        <div className='col-4'>
                            <button
                                onClick={(e) => handleBtnPress(1, e)}
    
                                className=" btn-animation arrows mb-3 ">
                                <i className="fa-solid fa-caret-up"></i>
                            </button>
                        </div>
    
                        <div className='col-4'>
                            <button
                                onClick={(e) => handleBtnPress(0, e)}
    
                                className=" btn-animation arrows mb-3 ">
                                <i className="fa-solid fa-caret-down"></i>
    
                            </button>
                        </div>
    
                    </div> */}
                {/* <div className='div5' id='div5'>
                        <p>{data.totalSupply != 0 ? data.totalSupply : '???'} / {CONFIG.MAX_SUPPLY}</p>
                        {animatedChar}
    
                    </div> */}
                {/* <div className='row div6 '>
    
                        {animationData.animations
                            .filter((n, key) => key > 6)
                            .map((n, key) => (
    
                                <div className='col-4' key={key}>
                                    <button
                                        className=" btn-animation mb-3 "
                                        style={{ backgroundColor: backgroundColor.bg[key + 7].color }}
                                        onClick={(e) => handlechangePosition(key + 7, e)}
                                    >
                                        {n.name}
                                    </button>
                                </div>
                            ))}
                        <div className='col-4' >
    
                            <button
                                onClick={(e) => handleBtnPress(3, e)}
    
                                className=" btn-animation arrows mb-3 ">
                                <i className="fa-solid fa-caret-left"></i>
                            </button>
                        </div>
                        <div className='col-4' >
    
                            <button
                                onClick={(e) => handleBtnPress(2, e)}
    
                                className=" btn-animation arrows mb-3 ">
                                <i className="fa-solid fa-caret-right"></i>
    
                            </button>
                        </div>
                    </div> */}
                {/* <div className='div7'>
                        <h1 ref={wordRef}>
                            {letters.map((letter, index) => (
                                <span key={index} style={{ display: "inline-block" }}>
                                    {letter}
                                </span>
                            ))}
                        </h1>
                        <h2 ref={wordRefH2}>
                            {sloganLetters.map((letter, index) => (
                                <span key={index} style={{ display: "inline-block" }}>
                                    {letter}
                                </span>
                            ))}
                        </h2>
                    </div> */}

                {/* <div className='div8'> */}
                {/* {characterContent} */}

                {/* <Container fluid>
                            <Row>
                                {characterContent}
                                <Col lg={4} sm={12} className="text-center">
                                    {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                                        <>
                                            <div
                                                style={{ textAlign: "center", color: "var(--accent-text)" }}
                                            >
                                                The sale has ended.
                                            </div>
                                            <p
                                                style={{ textAlign: "center", color: "var(--accent-text)" }}
                                            >
                                                You can still find {CONFIG.NFT_NAME} on
                                            </p>
                                            <hr />
                                            <a target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                                                {CONFIG.MARKETPLACE}
                                            </a>
                                        </>
                                    ) : (
                                        <div className='pt-4'>
                                            <p className='text-center'>{CONFIG.NETWORK.NAME} Network</p>
    
                                        </div>
                                    )}
                                </Col>
                                <Col lg={4} sm={12} className="text-center pt-3">
                                    {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
    
                                            {blockchain.account === "" ||
                                                blockchain.smartContract === null ? (
                                                <div>
    
                                                    <button className=' btn btn-success'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            dispatch(connect());
                                                            getData();
                                                        }}
                                                    >
                                                        CONNECT
                                                    </button>
                                                    {blockchain.errorMsg !== "" ? (
                                                        <>
                                                            <p
                                                                style={{
                                                                    textAlign: "center",
                                                                    lineHeight: 1,
                                                                    marginTop: '1rem',
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                <small>{blockchain.errorMsg}</small>
                                                            </p>
                                                        </>
                                                    ) : null}
                                                </div>
                                            ) : (
                                                <>
                                                    <p className='text-center'>
                                                        <small> {feedback}</small>
                                                    </p>
    
                                                    <div ai={"center"} jc={"center"} fd={"row"}>
                                                        <button className=' btn-mint w-100 btn btn-success'
                                                            disabled={claimingNft ? 1 : 0}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                claimNFTs();
                                                                getData();
                                                            }}
                                                        >
                                                            {claimingNft ? "BUSY" : "MINT " + CONFIG.SYMBOL + " for " + CONFIG.DISPLAY_COST + " " + CONFIG.NETWORK.SYMBOL}
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
    
                                </Col>
                            </Row>
                        </Container> */}
                {/* </div> */}
                {/* </header> */}
            </main>
        </div>


    );
};

export default Main;