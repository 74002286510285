import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import { Provider } from "react-redux";
import DataContextProvider from "./contexts/DataContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <DataContextProvider>
        <App />
      </DataContextProvider>

      <audio id="mySoundHover" hidden>
        <source src="/assets/audio/audio_hover_links.mp3" type="audio/mpeg" />
      </audio>
      <audio id="mySoundClickOn" hidden>
        <source src="/assets/audio/audio_click_links.mp3" type="audio/mpeg" />
      </audio>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
