import KeyPressListener from '../Keyboard/KeyPressListener'
import RevealingText from './RevealingText'
class TextMessage {
  constructor({ text, actor, avatar, onComplete }) {
    this.text = text;
    this.actor = actor || 'Informations';
    this.avatar = avatar
    this.onComplete = onComplete;
    this.element = null;
  }

  createElement() {
    //Create the element
    this.element = document.createElement("div");
    this.element.classList.add("TextMessage");
    // Check if the text contains an HTML link tag
    if (this.text.includes("<a href")) {
      this.element.innerHTML = (`
        <p class="TextMessage_actor">${this.actor}</p>
        ${this.avatar ? `
        <div class="row">
        <div class="col-1">
        <div class="TextMessage_avatar" style="background-image: url('${this.avatar}');"></div>
        </div>
        <div class="col-11"> <p class="TextMessage_p">${this.text}</p></div>
        </div>
        ` : `<p class="TextMessage_p">${this.text}</p> `
        }
       
        <button class="TextMessage_button">[PRESS ENTER]</button>
      `);
    } else {
      this.element.innerHTML = (`
      ${this.actor == 'Informations' ? `
      <div class="TextMessage_actor"><div class="TextMessage_actor_false">${this.actor}</div></div>
      ` : `
      <div class="TextMessage_actor"><div class="TextMessage_actor_true">${this.actor}</div></div>
      `}
      ${this.avatar ? `
      <div class="row g-0 w-100">
      <div class="col-2">
      <div class="TextMessage_avatar">
          <div class="TextMessage_avatar_img" style="background-image: url('${this.avatar}');">
          </div>
        </div>
      </div>
        <div class="col-10 d-flex align-items-center justify-content-start text-start"> <p class="TextMessage_p"></p>
        </div>
      </div>

      ` : `<p class="TextMessage_p"></p> `
        }
              <button class="TextMessage_button">[PRESS ENTER]</button>
      `);
    }
    //Init the typewriter effect
    this.revealingText = new RevealingText({
      element: this.element.querySelector(".TextMessage_p"),
      text: this.text
    });

    this.revealingText.init();


    this.element.querySelector("button").addEventListener("click", () => {
      //Close the text message
      this.done();
    });

    this.actionListener = new KeyPressListener("Enter", () => {
      this.done();
    });

  }


  done() {
    if (this.revealingText && !this.revealingText.isDone) {
      this.revealingText.warpToDone();
      return;
    }

    this.element.remove();
    this.actionListener.unbind();
    this.onComplete();
  }

  init(container) {
    this.createElement();
    container.appendChild(this.element);
  }
}

export default TextMessage;
